import { Component, OnInit, Input, } from '@angular/core';
import { InvoiceItemModel } from 'app/models/invoice-item.model';
import { EventService } from '../../core/services/event.service';
import { ProviderBillService } from '../../services/provider-bill.service';
import { saveAs } from 'file-saver';
import { RegionType } from 'app/enums/region-type.enum';

@Component({
  selector: 'app-invoice-items-accordion',
  templateUrl: './invoice-items-accordion.component.html',
  styleUrls: ['./invoice-items-accordion.component.css']
})
export class InvoiceItemsAccordionComponent implements OnInit {

  public items: Array<InvoiceItemModel>;
  public RegionType = RegionType;
  @Input('items') public set update_items(data: Array<InvoiceItemModel>)
  {
    this.items = data;
    for(let item of this.items)
    {
      item.position = item.provider_category != null ? item.provider_category.position : 9999;
    }
  }

  
  constructor(private event_service: EventService, private provider_bill_service: ProviderBillService) {

  }

  ngOnInit() {
  }

  PdfProviderBillExport(provider_bill_id: string) {
    this.event_service.loadingStart();
    this.provider_bill_service.GetProviderBillPDF(provider_bill_id).subscribe(
      file_model => {
        saveAs(file_model.url, file_model.file_name);
        this.event_service.loadingFinish();
      },
      error => {
        this.event_service.loadingFinish();
      });
  }

}
