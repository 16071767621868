import { throwError, Observable, from } from 'rxjs';

import { map, catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpSentEvent,
    HttpHeaderResponse,
    HttpProgressEvent,
    HttpResponse,
    HttpUserEvent
} from '@angular/common/http';
import { MembershipService } from '../core/services/membership.service';

import { TokenModel } from '../core/models/token.model';
import * as moment from 'moment/moment';
import { ConfigService } from 'app/services/config.service';
import { Router } from '@angular/router';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

    constructor(private router: Router, public service: MembershipService, private config: ConfigService) {}

    private json_date_time = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/;
    private json_date = /^\d{4}-\d\d-\d\dT00:00:00$/;

    private convert_dates_from_api(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convert_dates_from_api(item);
            }
        }

        for (const key of Object.keys(object)) {
            const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convert_dates_from_api(item);
                }
            }

            if (value instanceof Object) {
                this.convert_dates_from_api(value);
            }
            
            if(this.json_date.test(value))
            {
                object[key] = moment(value).toDate();
            }
            else if(this.json_date_time.test(value))
            {
                object[key] = moment.utc(value).toDate();
            }
        }
    }
    
    private convert_dates_to_api(object: Object) {
        if (!object || !(object instanceof Object)) {
            return;
        }

        if (object instanceof Array) {
            for (const item of object) {
                this.convert_dates_from_api(item);
            }
        }

        for (const key of Object.keys(object)) {
            const value = object[key];

            if (value instanceof Array) {
                for (const item of value) {
                    this.convert_dates_from_api(item);
                }
            }

            if (value instanceof Object) {
                this.convert_dates_from_api(value);
            }
            
            if(value instanceof Date)
            {
                if(moment(value).format('HH:mm:ss') == '00:00:00')
                {
                    object[key] = moment(value).format('YYYY-MM-DD');
                }
                else
                {
                    object[key] = moment(value).toISOString();
                }
            }
        }
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        if (!this.config.isApiRoute(request.url))
        {
            return next.handle(request);
        }
        return from(this.service.GetToken()).pipe(
            switchMap((token: TokenModel) => {
                if (token && token.token) {
                    request = request.clone({
                        setHeaders: {
                            Authorization: `Bearer ${token.token}`
                        }
                    });
                }
                
                this.convert_dates_to_api(request.body);
                
                return next.handle(request).pipe(map((event: HttpResponse<any>) => {
                    if (event instanceof HttpResponse) {
                        this.convert_dates_from_api(event.body);
                    }
                    return event;
                }), catchError((err: HttpErrorResponse) => {
                    if (err.status == 401) {
                        this.service.ClearToken();
                        this.router.navigateByUrl('/login', {replaceUrl: true});                        
                    }

                    return throwError(err);                    
                }));
            })
        );

    }
}