import {JwtHelperService} from '@auth0/angular-jwt';
import { TokenModel } from './token.model';
const jwtHelper = new JwtHelperService();

export class SessionModel
{
    public user_id: string;
    public first_name: string;
    public email: string;
    public roles: Array<string>;
    public utilities_linked: boolean;

    public constructor(token: TokenModel)
    {
        let jwt: any = jwtHelper.decodeToken(token.token);
        this.user_id = jwt.nameid;
        this.first_name = jwt.given_name;
        this.email = jwt.email;
        this.roles = jwt.role;
        this.utilities_linked = token.utilities_linked;
    }
}