<div class="row">
  <div class="input-cell col-3">
    <mat-form-field>
      <input matInput class="date-month" id="date-month-text-box" type="text" placeholder="Month"
      [(ngModel)]="month" (blur)="handleChange()" [disabled]="is_disabled" mask="09" />
    </mat-form-field>
  </div>
  <div class="input-cell col-3">
    <mat-form-field>
      <input matInput class="date-day" id="date-day-text-box" type="text" placeholder="Day"
      [(ngModel)]="day" (blur)="handleChange()" [disabled]="is_disabled" mask="09" />
    </mat-form-field>
  </div>
  <div class="input-cell col-6">
    <mat-form-field>
      <input matInput class="date-year" id="date-year-text-box" type="text" placeholder="Year"
      [(ngModel)]="year" (blur)="handleChange()" [disabled]="is_disabled" mask="0000" />
    </mat-form-field>
  </div>
</div>