import { Component, OnInit, Input  } from '@angular/core';

@Component({
    selector: 'app-styled-currency',
    templateUrl: './styled-currency.component.html',
    styleUrls: ['./styled-currency.component.css']
})
export class StyledCurrencyComponent implements OnInit {
    @Input() amount: string;
    public dollars: string;
    public cents: string;
    public breakdownToggle: boolean;
    public negative: boolean;

    constructor() {
        
    }

    ngOnInit() {
        this.negative = this.amount.charAt(0) === '-';
        if (this.negative) {
            this.dollars = this.amount.substr(this.amount.indexOf('$') + 1, this.amount.indexOf('.') - 2);
        }
        else {
            this.dollars = this.amount.substr(this.amount.indexOf('$') + 1, this.amount.indexOf('.') - 1);
        }    
        this.cents = this.amount.charAt(this.amount.indexOf('.') + 1) + this.amount.charAt(this.amount.indexOf('.') + 2);
    }

}
