import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { InvoiceModel } from 'app/models/invoice.model';
import { InvoiceService } from 'app/services/invoice.service';

@Injectable()
export class CurrentInvoiceResolver implements Resolve<InvoiceModel> {

    constructor(private invoice_service: InvoiceService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<InvoiceModel> {
    return this.invoice_service.GetCurrentInvoice();
  }
}
