import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-private-browsing-notice',
  templateUrl: './private-browsing-notice.component.html',
  styleUrls: ['./private-browsing-notice.component.css']
})
export class PrivateBrowsingNoticeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
