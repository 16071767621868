import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { EstimatedReconciliationModel } from 'app/models/estimated-reconciliation.model';
import { ConfigService } from './config.service';
import { TrueUpFeatureModel } from 'app/models/true-up.model';

@Injectable({
  providedIn: 'root'
})
export class EstimatedReconciliationService {

  constructor(private http: HttpClient, private config: ConfigService) { }


  public GetTrueUpConfig(): Observable<TrueUpFeatureModel> {
    return this.http.get<TrueUpFeatureModel>(this.config.getApiRoute('api/v3/administration/features/true-up')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetReconcilations(): Observable<EstimatedReconciliationModel> {
    return this.http.get<EstimatedReconciliationModel>(this.config.getApiRoute('api/v3/customer-portal/estimated-reconciliation')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public SaveReconciliation(model: EstimatedReconciliationModel): Observable<EstimatedReconciliationModel> {
      return this.http.put<EstimatedReconciliationModel>(this.config.getApiRoute('api/v3/customer-portal/estimated-reconciliation'), model).pipe(
        map(data => {
          return data;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
        }));
  }
}
