<div class="bg-light">
    <div class="container pt-3 pb-3 pt-md-5 pb-md-5">
        <div class="card">
            <div class="card-body p-4 text-center">
                <div class="card-inner">
                    <h1 class="mb-4 mb-md-5">Utilities <strong>Made Simple</strong></h1>

                    <p class="mb-4 mb-md-5"><strong>Good news {{ opt_in_model.customer.first_name }}!</strong> We are
                        excited to share that
                        {{ opt_in_model.group.property?.name }} has teamed up with SimpleBills on all leases going
                        forward in order to make paying utility bills easier than ever!</p>

                    <h4 class="mb-4">SimpleBills is giving you the option to opt in now, mid-lease, so that you can
                        start
                        experiencing simplified utilities immediately!</h4>

                    <h3 class="mb-4">Not to mention if you start now,</h3>

                    <div class="waive-row mb-4">
                        <span class="waive-left">
                            we will<br />waive the
                        </span>
                        <span class="waive-middle">{{ opt_in_model.new_account_fee | currency }}</span>
                        <span class="waive-right">sign-up fee</span>
                    </div>

                    <p class="mb-4">After signing up, you should receive any deposit back that was made to your utility
                        provider since the utilities will now be placed in your property's name. </p>

                    <div class="button-box mt-4 mb-4 mt-md-5 mb-md-5">
                        <ng-container *ngIf="!opt_in_model.accept">
                            <p>
                                <button mat-raised-button type="button" color="primary" (click)="Accept()">Yes, I want
                                    to
                                    simplify my
                                    utilities</button>
                            </p>
                            <p>
                                <button mat-raised-button type="button" color="primary" (click)="Reject()">No thanks,
                                    I'll
                                    sign up when I renew
                                    my
                                    lease</button>
                            </p>
                        </ng-container>
                        <ng-container *ngIf="opt_in_model.accept">
                            <h4 class="mb-3">Your account e-mail is already set to {{ opt_in_model.customer.email }}, now we need to set your password.</h4>
                            <form [formGroup]="form" method="post"  (ngSubmit)="SubmitAccept()">
                                <div>
                                    <mat-form-field>
                                        <input matInput type="password" placeholder="Password"
                                            formControlName="password" autocomplete="off" required />
                                    </mat-form-field>
                                </div>
                                <div>
                                    <mat-form-field>
                                        <input matInput type="password" placeholder="Confirm Password"
                                            formControlName="confirm_password" autocomplete="off" required />
                                    </mat-form-field>
                                </div>

                                <p><button mat-raised-button type="submit" color="primary" [disabled]="!form.valid">Set
                                        my
                                        Password</button></p>

                            </form>
                        </ng-container>
                        <nexus-form-result [model]="result_model"></nexus-form-result>
                    </div>
                    <hr />

                    <div class="row">
                        <div class="col-md-6">
                            <div class="detail-bullet">
                                <strong>Retrieve</strong> - our service retrieves utility bills for your residence so
                                you don't have to worry about missing any bills.
                            </div>
                            <div class="detail-bullet">
                                <strong>Pay</strong> - we receive payment from you by your preferred method of payment
                                and ensure the utility providers are paid on time.
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="detail-bullet">
                                <strong>Invoice</strong> - we invoice you once a month for ALL utilities in one
                                convenient location.
                            </div>
                            <div class="detail-bullet">
                                <strong>Transparency</strong> - our system allows you to access all utilities in one
                                place with actual copies of the utility provider invoices.
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>