import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { InvoiceModel } from 'app/models/invoice.model';
import { ConfigService } from 'app/services/config.service';
import { RegionType } from 'app/enums/region-type.enum';


@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.css']
})
export class InvoiceComponent implements OnInit {
  public invoice_model: InvoiceModel;
  public is_late: boolean;
  public days_until_bill_due: number;
  public RegionType = RegionType;


  constructor(private route: ActivatedRoute, private router: Router, private config: ConfigService) { }

  ngOnInit() {
    this.route.data.subscribe(({ invoice_model }) => {
      this.invoice_model = invoice_model;
      let days = this.config.getCurrentMoment().startOf('day').diff(moment(this.invoice_model.date_due).startOf('day'), 'days', false);
      this.is_late = days > 0;
      this.days_until_bill_due = Math.ceil(Math.abs(days));
    });

  }

}
