import { Injectable } from '@angular/core';  
import { Subject, Observable } from 'rxjs';
import { SessionModel } from '../models/session.model';

@Injectable()
export class EventService {
    private listeners = {};
    private eventsSubject: Subject<Event>;
    private events: Observable<Event>;

    constructor() {
        this.eventsSubject = new Subject();
        this.events = this.eventsSubject.asObservable()
        this.events.subscribe(
            ({ name, args }) => {
                if (this.listeners[name]) {
                    for (let listener of this.listeners[name]) {
                        listener(...args);
                    }
                }
            });
    }

    on(name, listener) {
        if (!this.listeners[name]) {
            this.listeners[name] = [];
        }

        this.listeners[name].push(listener);
    }

    

    broadcast(name, ...args) {
        this.eventsSubject.next({
            name,
            args
        });
    }

    loadingStart() {
        this.broadcast('loading-start');
    }

    loadingFinish() {
        this.broadcast('loading-finish');
    }

    sessionStart(model: SessionModel)
    {
        this.broadcast('session-start', model);
    }
    
    sessionEnd()
    {
        this.broadcast('session-end');
    }
}

export class Event
{
    public name: string;
    public args: string[];
}