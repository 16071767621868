<div id="utilities-container" class="container">
    <h2>Link Your Utilities</h2>
    <p *ngIf="!current_group" class="no-group-message">We have received your application. You will be notified when
        linking your utilities on this page is available once your application is fully processed.</p>
    <div class="row" *ngIf="current_group">
        <div class="col-md-4">
            <mat-card>
                <mat-card-title *ngIf="current_group.active">Current Address</mat-card-title>
                <mat-card-title *ngIf="!current_group.active">Future Address</mat-card-title>
                <mat-card-subtitle>{{ current_group.group.address1}}<br
                        *ngIf="current_group.group.address2" />{{ current_group.group.address2}}<br />{{ current_group.group.city }},
                    {{ current_group.group.region?.code }} {{ current_group.group.zip5 }}</mat-card-subtitle>
                <mat-card-content *ngIf="utilities_model.groups.length > 1">
                    <p>
                        <mat-form-field>
                            <mat-select placeholder="Choose Address" (selectionChange)="setup_group()"
                                [(ngModel)]="current_group">
                                <mat-option *ngFor="let group of utilities_model.groups | orderBy:['-end_date']"
                                    [value]="group">{{ group.group.address1 }} {{ group.group.address2 }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </p>
                    <p *ngIf="current_group.all_utilities_linked">You’re Linked! Nothing else to do here!</p>
                    <p *ngIf="!current_group.all_utilities_linked">Almost Done! Link your utilities so we can start
                        sending you bills.</p>
                </mat-card-content>

            </mat-card>
        </div>
        <div id="current-group-utilities" class="col-md-8">
            <div
                *ngIf="(current_group.implementation_mandatory && current_group.utilities.length == 0); then show_image else show_accordion">
            </div>
            <ng-template #show_accordion>
                <div class="utility-titles-container hidden-xs">
                    <div class="utility-column">Utility</div>
                    <div>Link&nbsp;Status</div>
                    <div>Details</div>
                </div>

                <mat-accordion [multi]="true" [displayMode]="'flat'">
                    <mat-expansion-panel
                        *ngFor="let utility of current_group.utilities | orderBy:['provider.provider_category.position']">
                        <mat-expansion-panel-header collapsedHeight="56px" expandedHeight="56px">
                            <mat-panel-title>
                                <span>{{ utility.provider.provider_category.name}}</span>
                            </mat-panel-title>
                            <mat-panel-description>
                                <span class="utilities-linked" *ngIf="utility.completed_date"><a
                                        class="linked-icon"></a>Linked</span>
                                <span class="utilities-unlinked" *ngIf="!utility.completed_date"><a
                                        class="linked-icon"></a>Not
                                    Linked</span>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div class="utility-content">
                            <div class="alert alert-warning" *ngIf="utility.provider_task_rejection">
                                <p><em>This completion was rejected for the following reason:</em></p>
                                <div [innerHtml]="utility.provider_task_rejection.description"></div>
                                <p class="mb-0" *ngIf="utility.rejection_other">{{utility.rejection_other}}</p>
                            </div>
                            <div class="alert alert-success" *ngIf="utility.completed_date && !utility.confirmed_date">
                                <p>This task was completed on {{utility.completed_date | amDateFormat:'MMM D, YYYY' }}
                                    <span *ngIf="utility.completed_by">by {{ utility.completed_by.first_name }}
                                        {{ get_initial(utility.completed_by.last_name) }}.</span></p>
                                <p class="mb-0">We're reviewing this information and we will send you an update email
                                    when
                                    we are done! If you need to change any of this information, please contact us!</p>
                            </div>
                            <div class="alert alert-success" *ngIf="utility.confirmed_date">
                                This account information has been accepted and we are able to access your account!
                            </div>
                            <div class="alert alert-success"
                                *ngIf="!utility.completed_date && !utility.confirmed_date && utility.completed_date">
                                You are not required to complete any steps for utility service.
                            </div>
                            <div class="row">
                                <div class="col-md-6" *ngIf="utility.steps.length > 0">
                                    <mat-card class="utility-instructions-card">
                                        <mat-card-title>{{ utility.provider.name}}</mat-card-title>
                                        <ng-container *ngFor="let step of utility.steps | orderBy:['position']">
                                            <mat-card-subtitle>{{ step.name}}</mat-card-subtitle>
                                            <mat-card-content>
                                                <div [innerHtml]="step.description"></div>
                                            </mat-card-content>
                                        </ng-container>
                                    </mat-card>
                                </div>
                                <div class="col-md-6" *ngIf="utility.fields.length > 0">
                                    <mat-card class="utility-setup-card">
                                        <form [formGroup]="link_forms[utility.id]" method="post" (ngSubmit)="linkUtility(utility)">
                                            <mat-card-title>Link Utility</mat-card-title>
                                            <mat-card-content>
                                                <div class="input-cell"
                                                    *ngFor="let field of utility.fields | orderBy:['position']">
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.Text">
                                                        <input matInput type="text" [placeholder]="field.name"
                                                            [formControlName]="field.id" />
                                                    </mat-form-field>
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.Numeric">
                                                        <input matInput type="number" [placeholder]="field.name"
                                                            [formControlName]="field.id" />
                                                    </mat-form-field>
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.EMail">
                                                        <input matInput type="email" [placeholder]="field.name"
                                                            [formControlName]="field.id" />
                                                    </mat-form-field>
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.Password">
                                                        <input matInput type="password" [placeholder]="field.name"
                                                            [formControlName]="field.id" autocomplete="off"/>
                                                    </mat-form-field>
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.Password">
                                                        <input matInput type="password"
                                                            placeholder="Confirm {{ field.name}}"
                                                            formControlName="{{ field.id}}_confirm" autocomplete="off"/>
                                                    </mat-form-field>
                                                    <mat-form-field *ngIf="field.field_type == ProviderTaskFieldType.TextArea">
                                                        <textarea matInput type="text" [placeholder]="field.name"
                                                            [formControlName]="field.id"></textarea>
                                                    </mat-form-field>
                                                </div>
                                            </mat-card-content>
                                            <mat-card-actions *ngIf="!utility.completed_date">
                                                <p><button mat-raised-button type="submit" color="primary"
                                                        [disabled]="!link_forms[utility.id].valid">Save</button></p>
                                                <div class="alert alert-danger" role="alert"
                                                    *ngIf="link_error[utility.id]">
                                                    {{ link_error[utility.id]}}</div>
                                                <div class="alert alert-success" role="alert"
                                                    *ngIf="link_success[utility.id]">
                                                    {{ link_success[utility.id]}}</div>
                                            </mat-card-actions>
                                        </form>
                                    </mat-card>
                                </div>
                            </div>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>
            </ng-template>
            <ng-template #show_image>
                <img src="/assets/standard_group_message.png" class="img-fluid">
            </ng-template>
        </div>
    </div>
</div>