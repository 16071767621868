import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

import { faComments } from '@fortawesome/free-solid-svg-icons';
import { MembershipService } from 'app/core/services/membership.service';

const jwtHelper = new JwtHelperService();

declare var CXBus: any;

@Component({
  selector: 'app-chat-genesys',
  templateUrl: './chat-genesys.component.html',
  styleUrls: ['./chat-genesys.component.scss']
})
export class ChatGenesysComponent implements OnInit {

  private loadAPI: Promise<any>;
  public customPlugin : any;

  public faComments = faComments;

  timeout:any;
  
  constructor(public membership: MembershipService,private router:Router) { 
    this.loadAPI = new Promise((resolve) => {
      this.loadScript();
      resolve(true);
    });

    this.router.events.subscribe(e=>{
      if (e instanceof NavigationStart) {
        clearInterval(this.timeout);
      }
      if (e instanceof NavigationEnd) {
        this.initiateGenesysChatPrompt();
      }
    });
  }

  ngOnInit(): void {
  }

  public loadScript() {   
    var library = "https://apps.mypurecloud.com/widgets/9.0/cxbus.min.js";
    
    var isFound = false;
    var scripts = document.getElementsByTagName("script")
    for (var i = 0; i < scripts.length; ++i) {
      if (scripts[i].getAttribute('src') != null && scripts[i].getAttribute('src') === library) {
        isFound = true;
      }
    }

    if (!isFound) {
      var F = new Function(`
        var load = function () {      
          window._genesys = {
            "widgets": {
              "webchat": {
                "transport": {
                  "type": "purecloud-v2-sockets",
                  "dataURL": "https://api.mypurecloud.com",
                  "deploymentKey": "86059801-a433-46fb-ac1d-27a29d3281cf",
                  "orgGuid": "11862a65-a67e-49b9-ac75-2c92e6830885",
                  "interactionData": {
                    "routing": {
                      "targetType": "QUEUE",
                      "targetAddress": "PS_SimpleBills_Chat",
                      "priority": 2
                    }
                  }
                }
              },
              "main":{
                      "theme":"light"
                    }  
            }
          }; 

          var script = "${library}";
          var x = document.createElement('script');
          x.src = script;
          x.setAttribute("onload", "javascript:CXBus.configure({debug:false,pluginsPath:'https://apps.mypurecloud.com/widgets/9.0/plugins/'}); CXBus.loadPlugin('widgets-core');");
          var sx = document.getElementsByTagName('script')[0];
          sx.parentNode.insertBefore(x, sx);
        };
        if (document.readyState === "complete")
          load();
        else if (window.addEventListener)
          window.addEventListener('load', load, false);
        else if (window.attachEvent) {
          window.attachEvent("onload", load);
        }`);
      F();
    }
  }

  public startChat()
  {
    if (!this.customPlugin) {      
      this.customPlugin = CXBus.registerPlugin('Custom');
    }

    this.membership.GetToken().subscribe((token) => {
      if (!token) {
          this.customPlugin.command('WebChat.open', this.getChatConfig({}));
          return;
      }
      let jwt = jwtHelper.decodeToken(token.token);
      if (!jwt) {
          this.customPlugin.command('WebChat.open', this.getChatConfig({}));
          return;
      }

      this.customPlugin.command('WebChat.open', this.getChatConfig(jwt));
  });

  sessionStorage.setItem("GENESYS_PROMPTED", "true");
  clearInterval(this.timeout);
  
}

  private getChatConfig(jwt: any): any {
    const names = jwt["given_name"] ? (jwt["given_name"] as string).trim().split(" ", 2) : [];
    const validate_input = function(event, form, input, label, $, CXBus, Common){
      if (input && input.val())
      {
      return true;
      } else {
      return false;              
      }
    };
    const config = {
      "form": {
        "autoSubmit": false,
        "firstname": names.length > 0 ? names[0] : "",
        "lastname": names.length > 1 ? names[1] : "",
        "email": jwt["email"],
        "subject": ""
      },
      "formJSON": {
        "wrapper": "<table></table>",
        "inputs": [
          {
            "id": "cx_webchat_form_firstname",
            "name": "firstname",
            "maxlength": "100",
            "placeholder": "Required",
            "label": "First Name",
            "validate": validate_input
          },
          {
            "id": "cx_webchat_form_lastname",
            "name": "lastname",
            "maxlength": "100",
            "placeholder": "Required",
            "label": "Last Name",
            "validate": validate_input
          },
          {
            "id": "cx_webchat_form_address",
            "name": "address",
            "maxlength": "100",
            "placeholder": "Address you are contacting us about",
            "label": "Address",
            "validate": validate_input
          },
          {
            "id": "cx_webchat_form_email",
            "name": "email",
            "maxlength": "100",
            "placeholder": "Optional",
            "label": "Email"
          },
          {
            "id": "cx_webchat_form_subject",
            "name": "subject",
            "maxlength": "100",
            "placeholder": "Optional",
            "label": "Subject"
          }
        ]
      }
    };
    return config;
  }

  private initiateGenesysChatPrompt(){
    if (sessionStorage.getItem("GENESYS_PROMPTED") != "true") {
      this.timeout = window.setTimeout(() => {
          this.startChat();
          sessionStorage.setItem("GENESYS_PROMPTED", "true");
          clearInterval(this.timeout);
      }, 30000);
  }
  }
}
