<div id="settings-container" class="container">
  <h2>Manage My Account</h2>
  <div class="row">
    <div class="col-md-4">
      <mat-card>
        <form [formGroup]="profile_form" (ngSubmit)="saveProfile($event)">
          <mat-card-title>Profile</mat-card-title>
          <mat-card-content>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="E-Mail" formControlName="email" />
              </mat-form-field>
            </div>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Phone" formControlName="phone"
                       mask="(000) 000-0000" />
              </mat-form-field>
            </div>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Secondary Phone" formControlName="secondary_phone"
                       mask="(000) 000-0000" />
              </mat-form-field>
            </div>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Secondary E-Mail" formControlName="secondary_email" />
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <p>
              <button mat-raised-button type="submit" color="primary" [disabled]="!profile_form.valid">Save</button>
            </p>
            <nexus-form-result [model]="profile_result_model"></nexus-form-result>
          </mat-card-actions>
        </form>
      </mat-card>
      <mat-card *ngIf="this.settings_model.profile?.resident_portal_tutorial_url != null && this.settings_model.profile?.resident_portal_tutorial_url != ''">
          <mat-card-title>Tutorial Video</mat-card-title>
          <mat-card-actions>
            <p>
              <button mat-raised-button type="submit" color="primary" *ngIf="!opened" (click)="openDialog()">Watch Tutorial</button>
            </p>
          </mat-card-actions>
      </mat-card>
    </div>
    <div class="col-md-4">
      <mat-card>
        <form [formGroup]="password_form" method="post"  (ngSubmit)="changePassword($event)">
          <mat-card-title>Change Password</mat-card-title>
          <mat-card-content>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="password" placeholder="Current Password" formControlName="current_password" autocomplete="off"/>
              </mat-form-field>
            </div>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="password" placeholder="New Password" formControlName="new_password" required
                  validateEqual="confirm_password" autocomplete="off"/>
              </mat-form-field>
            </div>
            <small>Password should be at least 8 characters in length and must contain at least one digit!</small>
            <div class="input-cell">
              <mat-form-field>
                <input matInput type="password" placeholder="Confirm Password" formControlName="confirm_password"
                  required validateEqual="new_password" reverse="true" autocomplete="off"/>
              </mat-form-field>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <p>
              <button mat-raised-button color="primary" type="submit"
                [disabled]="!password_form.valid">Save</button>
            </p>
            <nexus-form-result [model]="password_result_model"></nexus-form-result>
          </mat-card-actions>
        </form>
      </mat-card>
    </div>
    <div class="col-md-4">
        <mat-card *ngIf="settings_model.credit_reporting.credit_reporting_enabled_scheme_wise">
        <mat-card-title>Credit Reporting</mat-card-title><br/>
        <div *ngIf="!previous_credit_reporting">
          <mat-card-subtitle><strong>Would you like to turn on Credit Reporting?</strong></mat-card-subtitle>
        <mat-card-subtitle>SimpleBills can help you build credit by reporting your payment history to Equifax for
          an additional charge of $2.99 a month.</mat-card-subtitle>
        </div> 
        <div class="alert alert-info" role="alert" *ngIf="previous_credit_reporting">
          <p>Credit Reporting is currently enabled.</p> <br/>
          <p>Toggle it on or off below to tailor your experience.</p>
        </div>      
        <mat-card-content>
          <form [formGroup]="credit_reporting_form" (ngSubmit)="saveCreditReporting()">
            <div class="input-cell">
              <mat-slide-toggle color="primary"
                                (change)="creditReportingToggled()" [checked]="settings_model.credit_reporting.credit_reporting_enabled">
              </mat-slide-toggle>
            </div>
          </form>
          <form *ngIf="settings_model.credit_reporting.credit_reporting_enabled" [formGroup]="credit_reporting_enabled_form">           
            <div class="row">
              <div class="birthdate-label col-12 col-sm-3">
                Birthdate:
              </div>
              <div class="col-12 col-sm-9">
                <nexus-form-date-input formControlName="birth_date"></nexus-form-date-input>
              </div>
            </div>
            <mat-error *ngIf="birthdate_valid && !credit_reporting_enabled_form.valid">
              Please enter your birth date
            </mat-error>
            <mat-error *ngIf="!birthdate_valid">
              The birth date you entered makes you ineligible for this service...
            </mat-error>
          </form>
        </mat-card-content>
        <mat-card-actions>
          <button mat-raised-button (click)="saveCreditReporting()" color="primary" [disabled]="!credit_reporting_enabled_form.valid">Save</button>

          <nexus-form-result [model]="credit_reporting_result_model"></nexus-form-result>
        </mat-card-actions>
      </mat-card>

      <mat-card>
        <mat-card-title>Contract Dates</mat-card-title>

        <div *ngFor="let contract_group of settings_model.contract_groups">
          <div class="contract-group-header">{{contract_group.address1 }} {{contract_group.address2 }}</div>
          <table class="table">
            <tr>
              <th>you + roomates</th>
              <th>start date</th>
              <th>end date</th>
            </tr>
            <tr *ngFor="let contract_date of contract_group.contract_dates">
              <td>{{contract_date.first_name}} {{contract_date.last_name}}</td>
              <td>{{contract_date.start_date | amDateFormat:'M/D/YYYY' }}</td>
              <td>{{contract_date.end_date | amDateFormat:'M/D/YYYY' }}</td>
            </tr>
          </table>
          <br />
          <br />
        </div>
        <ul>
          <!--<li>
              <div>
                  <a href="#">Invite roommates to sign up</a>
                  <p>All roommates who will be sharing the cost of utilities must be signed up with SimpleBills</p>
              </div>
          </li>-->
          <li>
            <div>
              <a href="https://simplebills.zendesk.com/hc/en-us/articles/115010459208-How-do-I-change-my-contract-dates-"
                target="_blank">Request a change to your contract dates</a>
              <p>Plans change and sometimes you need to stay longer or move out sooner. Let us know.</p>
            </div>
          </li>
          <li>
            <div> <a href="https://simplebills.zendesk.com/hc/en-us/articles/217389867-How-do-I-close-my-account-"
                target="_blank">Close my account</a></div>
          </li>
        </ul>
      </mat-card>
    </div>
  </div>

</div>
