var Months = [
    {name: 'January', abbreviation: 'Jan', numeric : 1 },
    {name: 'February', abbreviation: 'Feb', numeric : 2 },
    {name: 'March', abbreviation: 'March', numeric : 3 },
    {name: 'April', abbreviation: 'Apr', numeric : 4 },
    {name: 'May', abbreviation: 'May', numeric : 5 },
    {name: 'June', abbreviation: 'Jun', numeric : 6 },
    {name: 'July', abbreviation: 'Jul', numeric : 7 },
    {name: 'August', abbreviation: 'Aug', numeric : 8 },
    {name: 'September', abbreviation: 'Sep', numeric : 9 },
    {name: 'October', abbreviation: 'Oct', numeric : 10 },
    {name: 'November', abbreviation: 'Nov', numeric : 11 },
    {name: 'December', abbreviation: 'Dec', numeric : 12 }
    ];   
export default Months;