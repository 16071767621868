import { Component, Inject } from '@angular/core';
import { Router, NavigationEnd, ActivatedRouteSnapshot } from '@angular/router';
import { faFacebookF, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faUserCircle, faHome, faExclamationCircle, faUser, faWrench, faSignOutAlt, faComments } from '@fortawesome/free-solid-svg-icons';
import { MembershipService } from './core/services/membership.service';
import { SessionModel } from './core/models/session.model';
import { EventService } from './core/services/event.service';
import { DOCUMENT } from '@angular/common';
import { ConfigService } from './services/config.service';
import { EnvironmentType } from './enums/environment-type.enum';
import { SettingsService } from './services/settings.service';

declare var ga: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  public no_local_storage=false;
  public session: SessionModel = null;
  public is_nav_collapsed: boolean = true;
  public routeData: any;
  public hide_nav_bar: boolean;
  public faFacebookF = faFacebookF;
  public faInstagram = faInstagram;
  public faTwitter = faTwitter;
  public faUserCircle = faUserCircle;
  public faHome = faHome;
  public faExclamationCircle = faExclamationCircle;
  public faUser = faUser;
  public faWrench = faWrench;
  public faSignOutAlt = faSignOutAlt;
  public faComments = faComments;
  public currentYear: number = new Date().getFullYear();
  public co_brand_img_url = null;
  constructor(public membership: MembershipService, public router: Router, private event_service: EventService, @Inject(DOCUMENT) private document, private config: ConfigService,private setting_service: SettingsService,) {
    this.event_service.on('session-start', (model) => {
      this.session = model;
      if(this.session)
      {
        this.load_co_brand();
      }
    });
    this.event_service.on('session-end', () => {
      this.session = null;
    });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (evt instanceof NavigationEnd) {
        window.scrollTo(0, 0);
        ga('send', 'pageview', evt.url);

        let snapshot = this.lastChild(this.router.routerState.snapshot.root);
        this.hide_nav_bar = snapshot && snapshot.data && snapshot.data.hide_nav_bar;
      }

    });
    if(this.membership.no_local_storage){
      this.session = null;
      this.no_local_storage = true;
    }

    this.membership.GetSession().subscribe(
      data => {
        this.session = data;
        if(this.session)
        {
          this.load_co_brand();
        }
      },
      error => {
        this.session = null;
      });

    var url: string;
    switch (this.config.getEnvironmentType()) {
      case EnvironmentType.Staging:
        url = "./assets/dev_favicon.png"
        break;
      case EnvironmentType.Demo:
        url = "./assets/demo_favicon.png"
        break;
      default:
        url = "./assets/prod_favicon.png"
        break;
    }

    const link = <HTMLLinkElement>(this.document.head.querySelector("link[rel='icon']")) || this.document.head.appendChild(this.document.createElement('link'));
    link.rel = 'icon';
    link.type = 'image/x-icon';
    link.href = url;

  }

  private load_co_brand(){
    this.setting_service.GetCoBrand().subscribe(
      data => {
        this.co_brand_img_url = data?.url?.length>0 ? data.url : null;
      },
      error => {
        this.co_brand_img_url =null;
      });
  }
  private lastChild(route: ActivatedRouteSnapshot): ActivatedRouteSnapshot {
    if (route.firstChild) {
      return this.lastChild(route.firstChild);
    } else {
      return route;
    }
  }

  sign_out() {
    this.membership.ClearToken();
    this.router.navigate(['/login']);
  }

  toggle_nav_collapse() {
    this.is_nav_collapsed = !this.is_nav_collapsed;
  }

  isAuthenticated() {
    return this.session && this.session.user_id;
  }


}
