
    <mat-card>
        <mat-card-title>Please Verify the Following Information</mat-card-title>
        <mat-card-content>
            <dl class="dl-horizontal">
                <dt>Invoice Number</dt>
                <dd>{{ invoice_model.invoice_number }}</dd>
                <dt>Date Issued</dt>
                <dd>{{ invoice_model.date_sent | amDateFormat:'MMM D, YYYY' }}</dd>
                <dt>Bill Amount</dt>
                <dd>{{ invoice_model.item_amount | currency }}</dd>
                <dt>Payments To Date</dt>
                <dd>{{ invoice_model.transaction_amount | currency }}</dd>
                <dt>Current Balance</dt>
                <dd>{{ invoice_model.balance | currency }}</dd>
                <dt>Previous Balance</dt>
                <dd>{{ invoice_model.previous_balance | currency }}</dd>
                <dt>Total Balance Due</dt>
                <dd>{{ invoice_model.total_balance | currency }}</dd>
                <dt>Date Due</dt>
                <dd>{{ invoice_model.date_due | amDateFormat:'MMM D, YYYY' }}</dd>
            </dl>
        </mat-card-content>
    </mat-card>
