import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatOptionModule, MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatBadgeModule } from '@angular/material/badge';

import { MomentModule } from 'ngx-moment';
import { NgPipesModule } from 'ngx-pipes';

import { InvoiceService } from './services/invoice.service';
import { AutoPayService } from './services/auto-pay.service';
import { SettingsService } from './services/settings.service';
import { DashboardService } from './services/dashboard.service';
import { UtilitiesService } from './services/utilities.service';

import { EnrollmentCompleteGuard } from './guards/enrollment-complete.guard';
import { GoodStandingGuard } from './guards/good-standing.guard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AppComponent } from './app.component';
import { ComponentLoadingIndicatorComponent } from './components/component-loading-indicator/component-loading-indicator.component';
import { MyBillComponent } from './components/my-bill/my-bill.component';
import { UtilitiesComponent } from './components/utilities/utilities.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaymentsMenuComponent } from './components/payments-menu/payments-menu.component';
import { PaymentMethodCreditComponent } from './components/payment-method-credit/payment-method-credit.component';
import { PaymentMethodBankComponent } from './components/payment-method-bank/payment-method-bank.component';
import { AutoPayComponent } from './components/auto-pay/auto-pay.component';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { SettingsComponent } from './components/settings/settings.component';
import { InvoicePayComponent } from './components/invoice-pay/invoice-pay.component';
import { InvoicePaymentComponent } from './components/invoice-payment/invoice-payment.component';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { PaymentMethodRequiredComponent } from './components/payment-method-required/payment-method-required.component';
import { StyledCurrencyComponent } from './components/styled-currency/styled-currency.component';
import { ErrorComponent } from './components/error/error.component';
import { PrivateBrowsingNoticeComponent } from './components/private-browsing-notice/private-browsing-notice.component';

import { CurrentInvoiceResolver } from './resolvers/current-invoice.resolver';
import { AutoPayResolver } from './resolvers/auto-pay.resolver';
import { SettingsResolver } from './resolvers/settings.resolver';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { UtilitiesResolver } from './resolvers/utilities.resolver';
import { InvoicesResolver } from './resolvers/invoices.resolver';
import { InvoiceResolver } from './resolvers/invoice.resolver';
import { SupportComponent } from './components/support/support.component';
import { InvoiceItemsAccordionComponent } from './components/invoice-items-accordion/invoice-items-accordion.component';
import { CoreModule } from './core/core.module';
import { SignupHostComponent } from './components/signup-host/signup-host.component';
import { PaymentMethodService } from './services/payment-method.service';
import { PaymentMethodsResolver } from './resolvers/payment-methods.resolver';
import { PaymentMethodResolver } from './resolvers/payment-method.resolver';
import { InvoicePaymentResolver } from './resolvers/invoice-payment.resolver';
import { RegulatoryService } from './services/regulatory.service';
import { RegulatoryResolver } from './resolvers/regulatory.resolver';
import { ApiInterceptor } from './interceptors/api.interceptor';
import { EstimatedReconciliationComponent } from './components/estimated-reconciliation/estimated-reconciliation.component';
import { EstimatedReconciliationResolver } from './resolvers/estimated-reconciliation.resolver';
import { EstimatedReconciliationService } from './services/estimated-reconciliation.service';
import { InvoiceDetailComponent } from './components/invoice-detail/invoice-detail.component';
import { ConfigService } from './services/config.service';
import { SentryErrorHandler } from './handlers/sentry.error-handler';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { AppRoutingModule } from './app-routing.module';
import { OptInService } from './services/opt-in.service';
import { OptInResolver } from './resolvers/opt-in.resolver';
import { ChatGenesysComponent } from './components/chat/chat-genesys/chat-genesys.component';
import { ProviderBillService } from './services/provider-bill.service';
import { InvoiceOneTimePaymentMethodComponent } from './components/invoice-one-time-payment-method/invoice-one-time-payment-method.component';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogModule, MatDialogTitle } from '@angular/material/dialog';
import { SystemNotificationsService } from './services/system-notifications.service';
import { SystemNotificationsComponent } from './components/system-notifications/system-notifications.component';
import { SystemNotificationsDialogComponent } from './components/system-notifications/system-notifications-dialog/system-notifications-dialog.component';
import { PaymentMethodInstructionsComponent } from './components/payment-method-instructions/payment-method-instructions.component';
import { PaymentMethodNotificationsResolver } from './resolvers/payment-method-notifications.resolver';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { TrueUpConfigResolver } from './resolvers/true-up-config.resolver';
import { CardDisclosuresDialogComponent } from './components/card-disclosures-dialog/card-disclosures-dialog.component';

@NgModule({
    declarations: [
        AppComponent,
        MyBillComponent,
        UtilitiesComponent,
        DashboardComponent,
        PaymentMethodsComponent,
        LoginComponent,
        SettingsComponent,
        AutoPayComponent,
        PaymentMethodCreditComponent,
        PaymentMethodBankComponent,
        PaymentsMenuComponent,
        ComponentLoadingIndicatorComponent,
        InvoicePayComponent,
        InvoicePaymentComponent,
        InvoicesComponent,
        InvoiceComponent,
        PaymentMethodRequiredComponent,
        StyledCurrencyComponent,
        ForgotPasswordComponent,
        ResetPasswordComponent,
        ErrorComponent,
        PrivateBrowsingNoticeComponent,
        SupportComponent,
        InvoiceItemsAccordionComponent,
        SignupHostComponent,
        EstimatedReconciliationComponent,
        InvoiceDetailComponent,
        OptInComponent,
        ChatGenesysComponent,
        InvoiceOneTimePaymentMethodComponent,
        SystemNotificationsComponent,
        SystemNotificationsDialogComponent,
        PaymentMethodInstructionsComponent,
        SafeHtmlPipe,
        CardDisclosuresDialogComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        NgPipesModule,
        MatProgressSpinnerModule,
        MatOptionModule,
        MatSlideToggleModule,
        MatRadioModule,
        MatCheckboxModule,
        MatCardModule,
        MatInputModule,
        MatFormFieldModule,
        MatButtonModule,
        MatSelectModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatListModule,
        MatGridListModule,
        MatTabsModule,
        MatExpansionModule,
        MatAutocompleteModule,
        MomentModule,
        FontAwesomeModule,
        CoreModule.forRoot(),
        AppRoutingModule,
        MatDialogModule,
        MatBadgeModule
    ],
    exports: [
        MatBadgeModule,
        MatDialogTitle,
        MatDialogContent,
        MatDialogActions,
        MatDialogClose
    ],
    providers: [
        EnrollmentCompleteGuard,
        GoodStandingGuard,
        InvoiceService,
        PaymentMethodService,
        AutoPayService,
        RegulatoryService,
        SettingsService,
        DashboardService,
        UtilitiesService,
        EstimatedReconciliationService,
        OptInService,
        ProviderBillService,
        CurrentInvoiceResolver,
        PaymentMethodsResolver,
        PaymentMethodResolver,
        PaymentMethodNotificationsResolver,
        AutoPayResolver,
        RegulatoryResolver,
        SettingsResolver,
        DashboardResolver,
        UtilitiesResolver,
        InvoicePaymentResolver,
        InvoicesResolver,
        InvoiceResolver,
        EstimatedReconciliationResolver,
        TrueUpConfigResolver,
        OptInResolver,
        SystemNotificationsService,
        {
            provide: ErrorHandler,
            useClass: SentryErrorHandler
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApiInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "en-US"
        },
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFactory,
            deps: [ConfigService],
            multi: true
        },
        {
            provide: MAT_DIALOG_DEFAULT_OPTIONS,
            useValue: {panelClass: 'mat-dialog-override'}
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}

export function appInitializerFactory(configService: ConfigService) {
    return () => configService.loadConfig();
}
