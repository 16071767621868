import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { faBell, faExclamationCircle, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { SystemNotificationModel } from 'app/models/system-notification.model';
import { SystemNotificationsService } from 'app/services/system-notifications.service';
import { Subscription, interval } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import { SystemNotificationsDialogComponent } from './system-notifications-dialog/system-notifications-dialog.component';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-system-notifications',
  templateUrl: './system-notifications.component.html',
  styleUrls: ['./system-notifications.component.scss']
})
export class SystemNotificationsComponent implements OnInit, AfterViewInit, OnDestroy {
  time_interval: Subscription;
  navigation_watch: Subscription;

  notifications: SystemNotificationModel[] = [];

  private _badge_text:string = '';
  private _badge_color:any = 'primary';
  private _icon: any = faBell;

  public get icon() {
    return this._icon;
  }

  public get badge_color() {
    return this._badge_color;
  }

  public get badge_text() {
    return this._badge_text;
  }

  constructor(private notification_service: SystemNotificationsService, private dialog: MatDialog, private router: Router) { }

  ngOnInit(): void {
 
  }

  ngAfterViewInit(): void {
    this.navigation_watch = 
      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          this.setInterval();
        }
      });
  }

  ngOnDestroy(): void {
    if (this.time_interval) {
      this.time_interval.unsubscribe();
    }
    if (this.navigation_watch) {
      this.navigation_watch.unsubscribe();
    }
  }

  setInterval(): void {
    if (this.time_interval) {
      this.time_interval.unsubscribe();
    }

    this.time_interval =
      interval(60000)
        .pipe(
          startWith(0),
          switchMap(() => this.notification_service.GetNotifications())
        )
        .subscribe(data => this.handleNotificationData(data)); 
  }

  handleNotificationData(data: any) {
    if ((data?.length ?? 0) <= 0) {
      this.notifications = [];
    }
    else {
      this.notifications = data;
    }

    if (this.notifications.length <= 0) {
      this._badge_text = "";
      this._badge_color = "primary";
      this._icon = faBell;
    }
    else {
      this._badge_text = this.notifications.length.toString();
      this._badge_color = (this.notifications.filter(n=>n.priority == "Critical").length > 0) ? "warn" : "primary";
      this._icon = (this.notifications.filter(n=>n.priority == "Critical").length > 0) ? faExclamationTriangle : faBell;
    }
  }
  
  openNotifications(): void {
    const dialogRef = this.dialog.open(SystemNotificationsDialogComponent, {
      data: this.notifications,
      minHeight:'60%',
      maxHeight:'96%',
      minWidth:'35%',
      maxWidth:'75%'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
