import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentMethodModel } from '../models/payment-method.model';
import { PaymentMethodService } from '../services/payment-method.service';

@Injectable()
export class PaymentMethodResolver implements Resolve<PaymentMethodModel> {

    constructor(private service: PaymentMethodService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<PaymentMethodModel> {
        return this.service.GetPaymentMethod(route.params.payment_method_id);                
    }
}
