<div id="dashboard-container" class="container">
  <div class="alert alert-danger mt-2" role="alert" *ngIf="!session?.utilities_linked">
    You have incomplete utility setup tasks. <a [routerLink]="['/utilities']">Click here!</a>
  </div>
  <h2>{{ dashboard_model.customer.first_name }}'s Dashboard</h2>

  <div class="no-bill-message" *ngIf="!dashboard_model.items">
    <img class="no-bill-message-image" src="/assets/no-bill.png" >
    <hr />
    <p>Prepare for your first bill now! <a
        href="https://simplebills.zendesk.com/hc/en-us/articles/218114667-10-Ways-To-Reduce-Your-Electricity-Usage"
        target="_blank">Tips on how to lower usage.</a></p>
    <mat-card id="credit-reporting" *ngIf="!dashboard_model.customer.credit_reporting_enabled">
      <mat-card-content>
        <mat-card-subtitle>Let your payments work for you.</mat-card-subtitle>
        <mat-card-title>BUILD CREDIT NOW</mat-card-title>
        <mat-card-content>
          <p>Signup for SimpleBills Credit Reporting to have your payment status sent to Equifax each month and start building credit.</p>
          <p><button mat-raised-button color="primary" [routerLink]="['/settings']">Sign Up</button></p>
          <p><a href="https://simplebills.zendesk.com/hc/en-us/sections/115002342308-Credit-Reporting"
              target="_blank">Read FAQs</a></p>
        </mat-card-content>
      </mat-card-content>
    </mat-card>
  </div>

  <div class="dashboard-cells" *ngIf="dashboard_model.items">
    <mat-card class="dashboard-cell current-invoice-summary">
      <mat-card-content>
        <mat-list>
          <mat-list-item>
            <span mat-line class="your-balance">Your Balance</span>
          </mat-list-item>
          <mat-list-item>
            <span mat-line class="invoice-balance">
              <app-styled-currency amount="{{ dashboard_model.total_balance | currency }}"></app-styled-currency>
            </span>
          </mat-list-item>
          <mat-list-item>
            <span mat-line class="due-on">{{ dashboard_model.group?.region?.code === RegionType.NC ? 'Past Due After' : 'Due' }}
              {{ dashboard_model.date_due | amDateFormat:'MMM D, YYYY' }}</span>
          </mat-list-item>
          <mat-list-item *ngIf="dashboard_model.date_defaulted">
            <span mat-line class="defaulted">Forwarded to Property on
                {{ dashboard_model.date_defaulted | amDateFormat:'MMM D, YYYY' }}</span>
        </mat-list-item>
          <mat-list-item *ngIf="dashboard_model.settled && dashboard_model.date_settled">
            <span mat-line>Settled on {{ dashboard_model.date_settled | amDateFormat:'MMM D, YYYY' }}</span>
          </mat-list-item>
          <mat-list-item *ngIf="!dashboard_model.settled && !is_late">
            <span mat-line class="due-days" *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }} days left</span>
            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due Tomorrow!</span>
            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 0">Due Today!</span>
          </mat-list-item>
          <mat-list-item *ngIf="!dashboard_model.settled && is_late">
            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }} days
              late</span>
            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due Yesterday!</span>
          </mat-list-item>
        </mat-list>
      </mat-card-content>
      <mat-card-actions>
        <button mat-raised-button [routerLink]="['/my-bill']" color="primary">My Bill</button>
      </mat-card-actions>
    </mat-card>

    <mat-card class="dashboard-cell" *ngIf="dashboard_model.items.length > 0">
      <mat-card-subtitle>This is how your bill breaks down this month.</mat-card-subtitle>
      <mat-card-content>
        <div id="service-category-summary-chart" class="dashboard-chart"></div>
        <div id="service-category-legend">
          <div class="row">
            <div class="col-6 legend-item" *ngFor="let category of service_category_summary_data">
              <div class="legend-marker" [style.background]="category.color"></div>
              <span class="legend-title">{{category.service_category_name}}</span>
            </div>
          </div>
        </div>
      </mat-card-content>
    </mat-card>

    <ng-container *ngFor="let item of dashboard_model.items | orderBy:['provider_category.position']">

      <mat-card class="dashboard-cell">
        <mat-card-content class="service-category-title">
          <div attr.id="{{item.provider_category.id}}-historical-amount-chart" class="dashboard-chart"></div>
        </mat-card-content>
        <mat-card-title>{{ item.provider_category.name }} Cost <span>month
            to month</span></mat-card-title>
      </mat-card>

      <mat-card class="dashboard-cell" *ngIf="item.average_amount">
        <mat-card-content>
          <div attr.id="{{item.provider_category.id}}-property-average-chart" class="dashboard-chart"></div>
        </mat-card-content>
        <mat-card-title>Average Cost of {{ item.provider_category.name }}
          is
          {{ item.average_amount | currency }}<br /><span>Based on residents in
            similar units at
            {{ dashboard_model.group?.property?.name }}</span><br /></mat-card-title>
        <mat-card-subtitle
          *ngIf="item.amount > item.average_amount">
          You spent more than average
          compared to other residents in similar units. <a
            href="https://simplebills.zendesk.com/hc/en-us/articles/115010296327" target="_blank">Find out why.</a>
        </mat-card-subtitle>
      </mat-card>

    </ng-container>

  </div>

</div>
