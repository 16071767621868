
import {throwError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ConfigService } from './config.service';
import { DashboardModel } from 'app/models/dashboard.model';

@Injectable()
export class DashboardService {

    constructor(private http: HttpClient, private config: ConfigService) {

    }

    public GetDashboard(): Observable<DashboardModel> {
        return this.http.get<DashboardModel>(this.config.getApiRoute('api/v3/customer-portal/dashboard')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);    
            }),);

    }

}
