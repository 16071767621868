
import { environment } from '../../../environments/environment';

export class LoginModel
{
    public email: string;
    public password: string;
    public client_id: string = environment.client_id;
    public impersonation_id: string;
    public external_token: string;
}