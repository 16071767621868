import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ConfigService } from './config.service';
import { OptInModel } from 'app/models/opt-in.model';
import { TokenModel } from 'app/core/models/token.model';

@Injectable({
  providedIn: 'root'
})
export class OptInService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetOptIn(user_in_group_id: string, opt_in_token: string): Observable<OptInModel> {
    return this.http.get<OptInModel>(this.config.getApiRoute('api/v3/customer-portal/opt-in/'+user_in_group_id+'/'+opt_in_token)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public SaveOptIn(user_in_group_id: string, opt_in_token: string, model: OptInModel): Observable<TokenModel> {
    return this.http.put<TokenModel>(this.config.getApiRoute('api/v3/customer-portal/opt-in/'+user_in_group_id+'/'+opt_in_token), model).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
}
