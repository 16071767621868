import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable, of, from } from 'rxjs';
import { catchError, map, share, switchMap } from 'rxjs/operators';
import { LoginModel } from '../models/login.model';
import { TokenModel } from '../models/token.model';
import { SessionModel } from '../models/session.model';
import { ForgotPasswordModel } from '../models/forgot-password.model';
import { ResetPasswordModel } from '../models/reset-password.model';
import { EventService } from './event.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';
import { ConfigService } from 'app/services/config.service';
import * as Sentry from "@sentry/browser";

const jwtHelper = new JwtHelperService();

@Injectable()
export class MembershipService {
    public redirectUrl: string = null;
    public no_local_storage: boolean = false;
    constructor(private http: HttpClient, private router: Router, private event_service: EventService, private config: ConfigService) {
        if (this.testLocalStorage()) {
            this.load_user();
        } else {
            this.no_local_storage = true;
        }
    }

    private load_user() {
        this.GetToken().subscribe((token) => {
            if (!token) {
                return;
            }
            let jwt = jwtHelper.decodeToken(token.token);
            if (!jwt) {
                return;
            }
            Sentry.configureScope(function (scope) {
                scope.setUser({ "email": jwt["email"] });
            });

        });
    }

    public Login(argument: LoginModel): Observable<TokenModel> {
        let url = 'api/v3/session/login';
        if (argument.external_token) {
            url = 'api/v3/session/external-login';
        }
        return this.http.post<TokenModel>(this.config.getApiRoute(url), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public ForgotPassword(argument: ForgotPasswordModel): Observable<{}> {
        return this.http.post(this.config.getApiRoute('api/v3/session/forgot-password'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public ResetPassword(argument: ResetPasswordModel): Observable<{}> {
        return this.http.post(this.config.getApiRoute('api/v3/session/reset-password'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError(err.error.message);
            }),
            share());

    }

    public SetToken(token: TokenModel) {
        if (this.no_local_storage) { return; };
        localStorage.setItem('token', JSON.stringify(token));
        this.load_user();
        this.event_service.sessionStart(new SessionModel(token));
    }

    public ClearToken() {
        if (this.no_local_storage) { return; };
        localStorage.removeItem('token');
        this.load_user();
        this.event_service.sessionEnd();
    }

    private testLocalStorage(): boolean {
        var test = 'test';
        try {
            localStorage.setItem(test, test);
            localStorage.removeItem(test);
            return true;
        } catch (e) {
            return false;
        }
    }

    public GetToken(): Observable<TokenModel> {
        if (this.no_local_storage) { return of(null); };
        if (localStorage.getItem('token') && localStorage.getItem('token').startsWith('{')) {
            let model: TokenModel = JSON.parse(localStorage.getItem('token'));

            if (jwtHelper.isTokenExpired(model.token)) {
                this.ClearToken();
                this.redirectUrl = this.router.url;
                this.router.navigate(['/login']);
            }
            return of(model);
        }
        else {
            return of(null);
        }
    }

    public OverrideUtilitiesLinked() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.utilities_linked = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public OverrideGoodStanding() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.good_standing = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public OverrideEnrollmentComplete() {
        if (localStorage.getItem('token')) {
            let token = JSON.parse(localStorage.getItem('token'));
            token.enrollment_complete = true;
            localStorage.setItem('token', JSON.stringify(token));
        }
    }

    public GetSession(): Observable<SessionModel> {
        return from(this.GetToken()).pipe(
            switchMap((token: TokenModel) => {
                let session: SessionModel = null;
                if (token && token.token) {
                    session = new SessionModel(token);
                }
                return of(session);
            }),
            catchError(error => {
                return of(null);
            }),
            share()
        );
    }
}
