import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { EventService } from 'app/core/services/event.service';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
import { ResetPasswordModel } from 'app/core/models/reset-password.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';

@Component({
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent extends NexusComponent implements OnInit {
    public show_success: boolean = false;

    public form = new UntypedFormGroup({
        password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)]),
        confirm_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)])
    });

    public model: ResetPasswordModel = new ResetPasswordModel();
    public result_model: NexusFormResultModel = new NexusFormResultModel();

    constructor(public membership: MembershipService, private router: Router, private route: ActivatedRoute) {
        super();
    }

    submit() {
        if (this.form.invalid) {
            this.result_model.Incomplete();
            return;
        }

        if (!this.validatePasswordConfirmation()) {
            this.result_model.Error('Passwords Must Match');
            return;
        }

        this.result_model.Reset();
        this.StartSpinner();

        this.membership.ResetPassword(this.model).subscribe(
            data => {
                this.StopSpinner();
                this.show_success = true;
                setTimeout(() => {
                    this.router.navigate(['/login']);
                }, 2000);

            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });

    }

    ngOnInit() {
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.model);
        });
        this.route.params.subscribe(params => {
            if (params.user_id && params.reset_id) {
                this.model.user_id = params.user_id;
                this.model.reset_id = params.reset_id;
            }
        });
    }

    public validatePasswordConfirmation(): boolean {
        let valid = false;
        if (this.form) {
            var newPassword = this.form.get('password').value;
            var confirmPassword = this.form.get('confirm_password').value;

            if (newPassword && confirmPassword && newPassword == confirmPassword) {
                valid = true;
            }
        }
        return valid;
    }
}
