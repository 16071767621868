import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { AutoPayService } from '../services/auto-pay.service';
import { AutoPayModel } from 'app/models/auto-pay.model';

@Injectable()
export class AutoPayResolver implements Resolve<AutoPayModel> {

    constructor(private auto_pay_service: AutoPayService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<AutoPayModel> {
        return this.auto_pay_service.Get();
  }
}
