import { RegionModel } from 'app/core/models/region.model';
import { SystemNotificationModel } from './system-notification.model';

export class PaymentMethodModel
{
    public id: string;
    public created_date: Date;
    public account_type: string;

    public cc_number_mask: string;
    public cc_expiration_date: Date;
    public cc_name_on_card: string;
    public cc_card_code: string;
    public cc_street: string;
    public cc_zip: string;

    public ch_number_mask: string;
    public ch_transit_mask: string;
    public ch_name_on_check: string;
    public ch_bank_name: string;

    public number_mask: string;

    public default_account: boolean;
    public auto_pay: boolean;

    public one_time_payment: boolean;

    public notifications: Array<SystemNotificationModel>;

    public bin: boolean;
}