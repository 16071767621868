
import {throwError,  Observable } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';  
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { InvoiceModel } from 'app/models/invoice.model';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { InvoicePaymentModel } from 'app/models/invoice-payment.model';
import { ConfigService } from './config.service';

@Injectable()
export class InvoiceService {

    constructor(private http: HttpClient, private config: ConfigService) {  
  
    }
     
    public GetInvoices(): Observable<Array<InvoiceModel>> {
        return this.http.get<Array<InvoiceModel>>(this.config.getApiRoute('api/v3/customer-portal/invoices')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message); 
            }),);

    }

    public GetCurrentInvoice(): Observable<InvoiceModel> {
        return this.http.get<InvoiceModel>(this.config.getApiRoute('api/v3/customer-portal/invoices/current-invoice')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }),);

    }

    public GetInvoice(invoice_id: string): Observable<InvoiceModel> {
        return this.http.get<InvoiceModel>(this.config.getApiRoute('api/v3/customer-portal/invoices/'+invoice_id)).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message); 
            }),);

    }

    public PayInvoice(invoice_id: string, argument: PaymentMethodModel): Observable<InvoicePaymentModel> {
        return this.http.put<InvoicePaymentModel>(this.config.getApiRoute('api/v3/customer-portal/invoices/' + invoice_id + '/payments'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message); 
            }),);

    }


    public GetInvoicePayment(invoice_id: string, payment_id: string): Observable<InvoicePaymentModel> {
        return this.http.get<InvoicePaymentModel>(this.config.getApiRoute('api/v3/customer-portal/invoices/' + invoice_id + '/payments/' + payment_id)).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }),);

    } 
}
