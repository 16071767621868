import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AutoPayService } from '../../services/auto-pay.service';

import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { AutoPayModel } from 'app/models/auto-pay.model';
import { NoticeModel } from 'app/models/notice.model';
import { CreditCardService } from 'app/services/credit-card.service';
import { MatDialog } from '@angular/material/dialog';
import { CardDisclosuresDialogComponent } from '../card-disclosures-dialog/card-disclosures-dialog.component';

@Component({
    selector: 'app-auto-pay',
    templateUrl: './auto-pay.component.html',
    styleUrls: ['./auto-pay.component.css']
})
export class AutoPayComponent extends NexusComponent implements OnInit {

    public auto_pay_model: AutoPayModel;
    public result_model: NexusFormResultModel = new NexusFormResultModel();
    public form = new UntypedFormGroup({
        auto_pay: new UntypedFormControl()
    });
    public previous_auto_pay: boolean;

    public notices: Array<NoticeModel> = [];

    constructor(
        private route: ActivatedRoute, 
        private router: Router, 
        private auto_pay_service: AutoPayService, 
        private credit_card_service: CreditCardService,
        private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.route.data.subscribe(({ auto_pay_model }) => {
            this.auto_pay_model = auto_pay_model;
            this.previous_auto_pay = this.auto_pay_model.auto_pay;
            this.form.patchValue(this.auto_pay_model);
        });

        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.auto_pay_model);
        });

        this.getNotices();
    }

    submit() {
        this.result_model.Reset();
        if (this.auto_pay_model.auto_pay && (this.notices?.length ?? 0) !== 0) {
            const dialogRef = this.dialog.open(CardDisclosuresDialogComponent, {
              data: this.notices,
              minHeight:'50%',
              maxHeight:'100%',
              minWidth:'35%',
              maxWidth:'50%'
            });
        
            dialogRef.afterClosed().subscribe(result => {
              if (result === true) {
                this.completeSubmit();
              }
              else {
                this.result_model.Error('Please make any modifications needed before continuing.');
              }
            });
          }
          else {
            this.completeSubmit();
          }
    }

    completeSubmit() {
        this.StartSpinner();
        this.auto_pay_service.Put(this.auto_pay_model).subscribe(
            data => {
                this.auto_pay_model = data;
                this.previous_auto_pay = this.auto_pay_model.auto_pay;

                this.StopSpinner();
            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });
    }

    getNotices() {
        this.credit_card_service.GetAutoPayNotices().subscribe((data) => {
            this.notices = data;
        });
    }
}
