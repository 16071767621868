import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { MembershipService } from 'app/core/services/membership.service';
import { SystemNotificationModel } from 'app/models/system-notification.model';
import { PaymentMethodService } from 'app/services/payment-method.service';

@Component({
  selector: 'payment-method-instructions',
  templateUrl: './payment-method-instructions.component.html',
  styleUrls: ['./payment-method-instructions.component.scss']
})
export class PaymentMethodInstructionsComponent extends NexusComponent implements OnInit {
  public notifications_model: Array<SystemNotificationModel>;

  public get payprin_offload(): SystemNotificationModel {
    var notification: SystemNotificationModel = null;

    if ((this.notifications_model?.length ?? 0) > 0) {
      var matches = this.notifications_model.filter(x=>x.code == "PayPrinOffload");
      if ((matches?.length ?? 0) > 0) {
        notification = matches[0];
      }
    }

    return notification;
  }

  constructor(private route: ActivatedRoute, private router: Router, private membership: MembershipService) {
    super();
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ notifications_model }) => {
      this.notifications_model = notifications_model;
    });
  }

}
