import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { UtilitiesService } from '../services/utilities.service';
import { UtilitiesModel } from 'app/models/utilities.model';

@Injectable()
export class UtilitiesResolver implements Resolve<UtilitiesModel> {

    constructor(private utilities_service: UtilitiesService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<UtilitiesModel> {
        return this.utilities_service.GetUtilities();
  }
}