import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { EstimatedReconciliationService } from 'app/services/estimated-reconciliation.service';
import { EstimatedReconciliationModel } from 'app/models/estimated-reconciliation.model';

@Injectable()
export class EstimatedReconciliationResolver implements Resolve<EstimatedReconciliationModel> {

    constructor(private service: EstimatedReconciliationService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<EstimatedReconciliationModel> {
        return this.service.GetReconcilations();                
    }
}
