import { RegionModel } from 'app/core/models/region.model';
import { InvoiceModel } from './invoice.model';

export class EstimatedReconciliationModel {
    public id: string;
    public created_date: Date;
    public address1: string;
    public address2: string;
    public city: string;
    public region_id: string;
    public region: RegionModel;
    public zip5: string;
    
    public invoices: Array<InvoiceModel>;
}