<div class="bg-light">
  <div class="container">
    <div class="row">
      <div id="login-art" class="col-md-6 d-none d-md-flex">

      </div>
      <div class="col-md-6 text-center">
        <div id="login-wrapper">
          <h2>Reset Password</h2>

          <form [formGroup]="form" method="post"  (ngSubmit)="submit()">
            <div>
              <mat-form-field>
                <input matInput type="password" placeholder="Password" formControlName="password" required
                  validateEqual="confirm_password" autocomplete="off"/>
              </mat-form-field>
            </div>
            <div>
              <mat-form-field>
                <input matInput type="password" placeholder="Confirm Password" formControlName="confirm_password"
                  required validateEqual="password" reverse="true" autocomplete="off"/>
              </mat-form-field>
            </div>

            <p><button mat-raised-button type="submit" color="primary"
                [disabled]="!form.valid">submit</button></p>

            <nexus-form-result [model]="result_model"></nexus-form-result>

            <div *ngIf="show_success" class="alert alert-success" role="alert">
              Password Reset was successful! You will be automatically redirected shortly, or you can <a
                  [routerLink]="['/login']">click here</a> to proceed now.
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>