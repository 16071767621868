import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, from, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { TokenModel } from 'app/core/models/token.model';
import { MembershipService } from 'app/core/services/membership.service';

@Injectable() export class EnrollmentCompleteGuard implements CanActivate {  
  
    constructor(private router: Router, private membership_service: MembershipService) 
    { 
    
    }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
      return from(this.membership_service.GetToken())
      .pipe(
        map((token : TokenModel)=>{
          if(!token)
          {
            this.router.navigate(["login"]);
            return false;
          }
          if(token && token.enrollment_complete)
          {
              return true;
          }
          this.router.navigate(['/signup',token.signup_id]);  
          return false;
        }), 
        catchError((err) => {
          this.router.navigate(["login"]);
          return of(false);
        })
      );
  
  }
  
}  