<div id="support-wrapper">
    <div id="support-banner" class="page-banner">
        <img class="banner-image d-sm-none" src="assets/support-banner-xs.jpg"
            alt="Large image at top of screen of three college women who are on a bed looking at a screen on a laptop. Only their hands and arms and back of laptop are in the picture.">
        <img class="banner-image d-none d-sm-inline" src="assets/support-banner-sm.jpg"
            alt="Large image at top of screen of three college women who are on a bed looking at a screen on a laptop. Only their hands and arms and back of laptop are in the picture.">
    </div>
    <div id="support-top">
        <div class="container">
            <h1>We're here to help!</h1>
            <hr>
        </div>
    </div>
    <div id="support-buckets">
        <div class="container">
            <div class="row">
                <div class="col-sm-4 support-bucket">
                    <p><a href="https://simplebills.zendesk.com/hc/en-us"><img alt="" class="img-responsive-center"
                                src="assets/multiple-people.png"></a></p>

                    <h3>Help Center / FAQ</h3>

                    <p>Find quick answers to the most common questions here.</p>

                    <p><a href="https://simplebills.zendesk.com/hc/en-us" target="_blank">Visit The Help Center</a></p>
                    <hr class="bottom-rule">
                </div>
                <div class="col-sm-4 support-bucket">
                    <p><a [routerLink]="['/login']"><img alt="" class="img-responsive-center"
                                src="assets/chat.png"></a></p>

                    <h3>Chat</h3>

                    <p style="text-align:center">Click the chat button in the bottom right corner of your screen to
                        start a chat.</p>

                    <hr class="bottom-rule">
                </div>
                <div class="col-sm-4 support-bucket">
                    <p><a href="mailto:info@simplebills.com?subject=I%20need%20help!"><img alt=""
                                class="img-responsive-center" src="assets/mail.png"></a></p>

                    <h3><span>Email</span></h3>

                    <p>Send us an email.&nbsp;(Please allow&nbsp;up to 24 hours for&nbsp;response)</p>

                    <p><a href="http://www.simplebills.com/support/contact">Email Us Now</a></p>
                    <hr class="bottom-rule">
                </div>
            </div>
        </div>
    </div>
    <div id="support-bottom">
        <div class="container">
            <p><strong>PHONE:&nbsp;</strong>Contact customer service at 254.230.0199 seven days a week 7am-7pm Central
                Time.</p>

            <p><strong>POSTAL MAIL:&nbsp;</strong>SimpleBills | 100 Ritchie Road,&nbsp;Garden Level&nbsp;| Waco, TX
                76712</p>
        </div>
    </div>
</div>