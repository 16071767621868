import { Component, NgZone, ViewEncapsulation, OnInit, ChangeDetectorRef, AfterViewChecked, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment/moment';

import { MembershipService } from 'app/core/services/membership.service';
import { SessionModel } from 'app/core/models/session.model';
import { ConfigService } from 'app/services/config.service';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import { DashboardItemModel, DashboardModel } from 'app/models/dashboard.model';
import { RegionType } from 'app/enums/region-type.enum';
import { DialogBodyComponent } from '../../core/components/dialog-body/dialog-body.component';
import { MatDialog } from '@angular/material/dialog';
import { SettingsService } from '../../services/settings.service';

export interface DialogData {
  data_model: any;
}

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.css'],
    encapsulation: ViewEncapsulation.None
})
export class DashboardComponent implements OnInit, AfterViewInit {
    public dashboard_model: DashboardModel;
    public session: SessionModel = null;
    public is_late: boolean;
    public days_until_bill_due: number;

    public service_category_summary_data = [];
    private service_category_summary_chart: any;
    private historical_amount_data = {};
    private historical_amount_charts: any[] = [];
    private property_average_charts: any[] = [];
    public RegionType = RegionType;

  constructor(private route: ActivatedRoute, public membership: MembershipService, private config: ConfigService, private cd_ref: ChangeDetectorRef, private zone: NgZone, private dialog: MatDialog, private settings_service: SettingsService) {

   }

    ngOnInit() {
        this.route.data.subscribe(({ dashboard_model }) => {
            this.dashboard_model = dashboard_model;

            let days = this.config.getCurrentMoment().startOf('day').diff(moment(this.dashboard_model.date_due).startOf('day'), 'days', false);
            this.is_late = days > 0;
            this.days_until_bill_due = Math.ceil(Math.abs(days));

            if (this.dashboard_model.items) {
                this.service_category_summary_data = [];
                for (let item of this.dashboard_model.items) {
                    this.service_category_summary_data.push({
                        "service_category_name": item.provider_category.name,
                        "amount": item.amount,
                        "color": "#" + item.provider_category.color
                    });

                }

                for (let item of this.dashboard_model.items) {
                    this.historical_amount_data[item.provider_category.id] = [];

                    if (item.historical_items) {
                        for (let historical of item.historical_items.sort((o1, o2) => moment(o1.billing_period_date).unix() - moment(o2.billing_period_date).unix())) {
                            this.historical_amount_data[item.provider_category.id].push({
                                "month": moment(historical.billing_period_date).format('MMM'),
                                "amount": historical.amount,
                                "color": "#" + item.provider_category.color,
                                "label": moment(historical.billing_period_date).format('MMM YYYY')
                            });
                        }
                    }
                }
            }
        });
        this.membership.GetSession().subscribe(
            data => {
                this.session = data;
            },
            error => {
                this.session = null;
          });
    }


    ngOnDestroy() {
        this.cd_ref.detach(); //check this view and its children for changes.
        this.zone.runOutsideAngular(() => {
            if (this.service_category_summary_chart) {
                this.service_category_summary_chart.dispose();
            }
            if (this.dashboard_model.items) {
                for (let item of this.dashboard_model.items) {
                    if (this.historical_amount_charts[item.provider_category.id]) {
                        this.historical_amount_charts[item.provider_category.id].dispose();
                    }
                    if (this.property_average_charts[item.provider_category.id]) {
                        this.property_average_charts[item.provider_category.id].dispose();
                    }
                }
            }
        });
    }

    ngAfterViewInit() {

        /*Service Category Summary*/
        if (this.service_category_summary_data && this.service_category_summary_data.length > 0) {
            this.zone.runOutsideAngular(() => {
                let chart = am4core.create("service-category-summary-chart", am4charts.PieChart);
                chart.data = this.service_category_summary_data;
                chart.innerRadius = am4core.percent(27);
                chart.radius = am4core.percent(90);
                let pieSeries = chart.series.push(new am4charts.PieSeries());
                pieSeries.dataFields.value = "amount";
                pieSeries.dataFields.category = "service_category_name";
                pieSeries.slices.template.propertyFields.fill = "color";
                pieSeries.slices.template.stroke = am4core.color("#ffffff");
                pieSeries.slices.template.strokeWidth = 2;
                pieSeries.slices.template.strokeOpacity = 1;
                pieSeries.labels.template.disabled = true;
                pieSeries.slices.template.states.getKey("active").properties.shiftRadius = 0;
                pieSeries.slices.template.tooltipHTML = "<center>{category}<br><b>${value.value}<b></center>";
                pieSeries.tooltip.fontSize = 11;
                pieSeries.tooltip.autoTextColor = false;
                pieSeries.tooltip.label.fill = am4core.color("#ffffff");
                var shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter);
                shadow.opacity = 0;
                var hoverState = pieSeries.slices.template.states.getKey("hover");
                var hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter);
                hoverShadow.opacity = 0.7;
                hoverShadow.blur = 5;
                this.service_category_summary_chart = chart;
            });
        }

        /*Utility Graphs */
        if (this.dashboard_model.items) {
            for (let item of this.dashboard_model.items) {
                this.generateHistoricalGraphs(item, null);

                if (item.average_amount && item.amount) {
                    let dataprovider = [
                        {
                            "label": "Average Bill",
                            "amount": item.average_amount
                        },
                        {
                            "label": "Your Bill",
                            "amount": item.amount
                        }];
                    this.zone.runOutsideAngular(() => {
                        let chart = am4core.create(item.provider_category.id + "-property-average-chart", am4charts.XYChart);
                        chart.data = dataprovider;
                        chart.fontSize = 11;
                        chart.numberFormatter.numberFormat = "$#";

                        let categoryAxis = chart.yAxes.push(new am4charts.CategoryAxis());
                        categoryAxis.dataFields.category = "label";
                        categoryAxis.renderer.baseGrid.disabled = false;
                        categoryAxis.renderer.ticks.template.disabled = false;
                        categoryAxis.renderer.ticks.template.strokeOpacity = 1;
                        categoryAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
                        categoryAxis.renderer.ticks.template.strokeWidth = 1;
                        categoryAxis.renderer.ticks.template.length = 5;
                        categoryAxis.renderer.line.strokeOpacity = 1;
                        categoryAxis.renderer.line.strokeWidth = 1;
                        categoryAxis.renderer.line.stroke = am4core.color("#808080");
                        categoryAxis.tooltip.label.fontSize = 11;
                        categoryAxis.renderer.grid.template.location = 0;
                        categoryAxis.renderer.minGridDistance = 30;


                        let valueAxis = chart.xAxes.push(new am4charts.ValueAxis());
                        valueAxis.min = 0;
                        valueAxis.renderer.baseGrid.disabled = false;
                        valueAxis.renderer.ticks.template.disabled = false;;
                        valueAxis.renderer.ticks.template.strokeOpacity = 1;
                        valueAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
                        valueAxis.renderer.ticks.template.strokeWidth = 1;
                        valueAxis.renderer.ticks.template.length = 5;
                        valueAxis.renderer.line.strokeOpacity = 1;
                        valueAxis.renderer.line.strokeWidth = 1;
                        valueAxis.renderer.line.stroke = am4core.color("#808080");
                        valueAxis.tooltip.label.fontSize = 11;
                        valueAxis.extraMax = 0.01;
                        valueAxis.renderer.minGridDistance = 50;

                        let series = chart.series.push(new am4charts.ColumnSeries());
                        series.dataFields.valueX = "amount";
                        series.dataFields.categoryY = "label";
                        series.columns.template.fill = am4core.color("#" + item.provider_category.color);
                        series.columns.template.stroke = am4core.color("#" + item.provider_category.color);
                        series.columns.template.tooltipHTML = "<center>{categoryY}: {valueX}</center>";

                        series.tooltip.getFillFromObject = false;
                        series.tooltip.background.fill = am4core.color("#" + item.provider_category.color);
                        series.tooltip.fontSize = 11;
                        series.tooltip.autoTextColor = false;
                        series.tooltip.label.fill = am4core.color("#ffffff");
                        this.historical_amount_charts[item.provider_category.id] = chart;
                    });
                }
            }
      }
      if (this.dashboard_model.customer.tutorial_watched == null && this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url != null && this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url != "") {
        this.openDialog();
      }
    }

    generateHistoricalGraphs(item: DashboardItemModel, event) {
        this.zone.runOutsideAngular(() => {
            let chart = am4core.create(item.provider_category.id + "-historical-amount-chart", am4charts.XYChart);
            chart.data = this.historical_amount_data[item.provider_category.id];
            chart.fontSize = 11;
            chart.numberFormatter.numberFormat = "$#";
            chart.cursor = new am4charts.XYCursor();
            chart.zoomOutButton.background.fill = am4core.color("#DCDAD9");
            chart.zoomOutButton.background.states.getKey("hover").properties.fill = am4core.color("#CCCAC9");
            chart.maskBullets = false;


            chart.cursor.lineX.stroke = am4core.color("#000000");
            chart.cursor.lineX.strokeWidth = 1;
            chart.cursor.lineX.strokeOpacity = 0.4;
            chart.cursor.lineX.strokeDasharray = "";

            chart.cursor.lineY.disabled = true;

            let categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
            categoryAxis.dataFields.category = "month";
            categoryAxis.renderer.baseGrid.disabled = false;
            categoryAxis.renderer.ticks.template.disabled = false;
            categoryAxis.renderer.ticks.template.strokeOpacity = 1;
            categoryAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
            categoryAxis.renderer.ticks.template.strokeWidth = 1;
            categoryAxis.renderer.ticks.template.length = 5;
            categoryAxis.renderer.line.strokeOpacity = 1;
            categoryAxis.renderer.line.strokeWidth = 1;
            categoryAxis.renderer.line.stroke = am4core.color("#808080");
            categoryAxis.tooltip.label.fontSize = 11;
            categoryAxis.renderer.grid.template.location = 0;
            categoryAxis.renderer.minGridDistance = 30;


            let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
            valueAxis.min = 0;
            valueAxis.renderer.baseGrid.disabled = false;
            valueAxis.renderer.ticks.template.disabled = false;;
            valueAxis.renderer.ticks.template.strokeOpacity = 1;
            valueAxis.renderer.ticks.template.stroke = am4core.color("#b2b2b2");
            valueAxis.renderer.ticks.template.strokeWidth = 1;
            valueAxis.renderer.ticks.template.length = 5;
            valueAxis.renderer.line.strokeOpacity = 1;
            valueAxis.renderer.line.strokeWidth = 1;
            valueAxis.renderer.line.stroke = am4core.color("#808080");
            valueAxis.tooltip.label.fontSize = 11;
            valueAxis.extraMax = 0.01;
            valueAxis.renderer.minGridDistance = 50;

            let series = chart.series.push(new am4charts.LineSeries());
            series.dataFields.valueY = "amount";
            series.dataFields.categoryX = "month";
            series.propertyFields.stroke = "color";
            series.strokeWidth = 2;

            let bullet = series.bullets.push(new am4charts.CircleBullet());
            bullet.circle.radius = 4;
            bullet.circle.propertyFields.fill = "color";
            bullet.circle.stroke = am4core.color("#ffffff");
            bullet.circle.strokeWidth = 2;
            bullet.setStateOnChildren = true;
            let hoverState = bullet.circle.states.create("hover");
            hoverState.properties.radius = 4;
            hoverState.properties.strokeWidth = 1;
            bullet.tooltipHTML = "<center>{categoryX}<br><b>{valueY}<b></center>";

            series.tooltip.getFillFromObject = false;
            series.tooltip.background.fill = am4core.color("#" + item.provider_category.color);
            series.tooltip.fontSize = 11;
            series.tooltip.autoTextColor = false;
            series.tooltip.label.fill = am4core.color("#ffffff");
            this.historical_amount_charts[item.provider_category.id] = chart;
        });

  }


  openDialog(): void {
    if (this.dashboard_model.customer.tutorial_watched == null) {
      const dialogRef = this.dialog.open(DialogBodyComponent, {
        data: { message: "You can access this video again from your Account Settings", url: this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url},
        height: '585px',
        width: '900px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.settings_service.PutTutorial(this.dashboard_model.customer).subscribe(
          data => {
          },
          err => {
          });
      });
    }
  }
}
