import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NoticeModel } from 'app/models/notice.model';

@Component({
  selector: 'app-card-disclosures-dialog',
  templateUrl: './card-disclosures-dialog.component.html',
  styleUrls: ['./card-disclosures-dialog.component.scss']
})
export class CardDisclosuresDialogComponent implements OnInit {
  public empty_msg: string = "By clicking 'I Agree' below, you are authorizing payment against the selected payment method.";

  public notices: NoticeModel[] = [];

  constructor(
    public dialogRef: MatDialogRef<CardDisclosuresDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: NoticeModel[]) {
    this.notices = data ?? [];
  }

  ngOnInit(): void {
  }


  onAgree(): void {
    this.dialogRef.close(true);
  }

  onCancel(): void {
    this.dialogRef.close(false);
  }
}
