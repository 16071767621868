<div id="bank-payment-container" class="container">
  <h2 *ngIf="payment_method_model.id">Update a Payment Account</h2>
  <h2 *ngIf="!payment_method_model.id">Add a Payment Account</h2>

  <div class="row">
    <div class="col-md-3 col-sm-4">
      <app-payments-menu></app-payments-menu>
    </div>
    <div class="col-md-9 col-sm-8">
      <div class="row">
        <div class="col-md-7">
          <mat-card>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <mat-card-title *ngIf="!payment_method_model.id">Add Checking Account</mat-card-title>
              <mat-card-title *ngIf="payment_method_model.id">Edit Checking Account</mat-card-title>
              <p>
                Customers MUST use a checking account, not a savings account.
              </p>
              <mat-card-content>
                <div class="input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Name on Check" formControlName="ch_name_on_check" />
                  </mat-form-field>
                </div>
                <div class="input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Account Number" formControlName="ch_number_mask" />
                  </mat-form-field>
                </div>
                <div class="input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Routing Number" formControlName="ch_transit_mask"
                           [mask]="edit ? '000000000' : ''" />
                  </mat-form-field>
                </div>
                <div class="input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Bank Name" formControlName="ch_bank_name" />
                  </mat-form-field>
                </div>
              </mat-card-content>
              <p>By saving this payment method on file, you agree that this payment method can be used to pay any outstanding balance on your account per our <a href="http://www.simplebills.com/terms-of-service">Terms of Service</a>.</p>
              <mat-card-actions>
                <p><button mat-raised-button type="submit" [disabled]="!form.valid" color="primary">Save</button></p>
                <nexus-form-result [model]="result_model"></nexus-form-result>
              </mat-card-actions>
            </form>
          </mat-card>
        </div>
        <div class="col-md-5">
          <mat-card *ngIf="payment_method_model.id && payment_methods_model.length > 1">
            <mat-card-title>Delete Bank Account</mat-card-title>

            <mat-card-actions>
              <button mat-raised-button (click)="unLink()" *ngIf="!un_link_pressed">Delete</button>
              <button mat-raised-button (click)="confirmUnLink()" *ngIf="un_link_pressed" color="warn">
                Confirm
                Delete
              </button>
            </mat-card-actions>
          </mat-card>
          <payment-method-instructions></payment-method-instructions>
        </div>
      </div>
    </div>

  </div>
