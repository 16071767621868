import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PaymentMethodModel } from '../models/payment-method.model';
import { PaymentMethodService } from '../services/payment-method.service';

@Injectable()
export class PaymentMethodsResolver implements Resolve<Array<PaymentMethodModel>> {

    constructor(private service: PaymentMethodService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<PaymentMethodModel>> {
        return this.service.GetPaymentMethods();                
    }
}
