import { Component, Input, OnInit } from '@angular/core';
import { InvoiceModel } from 'app/models/invoice.model';
import { NexusComponent } from '../nexus/nexus.component';

@Component({
  selector: 'module-nexus-invoice-information',
  templateUrl: './nexus-invoice-information.component.html',
  styleUrls: ['./nexus-invoice-information.component.scss']
})
export class NexusInvoiceInformationComponent extends NexusComponent implements OnInit {
  @Input() public invoice_model: InvoiceModel;
  
  constructor() {
    super();
   }

  ngOnInit(): void {
  }

}
