import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InvoicePaymentModel } from 'app/models/invoice-payment.model';

@Component({
  selector: 'app-invoice-payment',
  templateUrl: './invoice-payment.component.html',
  styleUrls: ['./invoice-payment.component.css']
})
export class InvoicePaymentComponent implements OnInit {
    public invoice_payment_model: InvoicePaymentModel;

    constructor(private route: ActivatedRoute) { }

  ngOnInit() {
      this.route.data.subscribe(({ invoice_payment_model }) => {
     this.invoice_payment_model = invoice_payment_model;
});
  }

}
