import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NoticeModel } from 'app/models/notice.model';
import { Observable, of, throwError } from 'rxjs';
import { ConfigService } from './config.service';
import { catchError, map } from 'rxjs/operators';
import { CardIdentificationModel } from 'app/models/card-identification.model';
import { SurchargeModel } from 'app/models/surcharge.model';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { InvoiceModel } from 'app/models/invoice.model';

@Injectable({
  providedIn: 'root'
})
export class CreditCardService {
 
  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetNotices(tags: string[], payment_method_id: string = null, invoice_id: string = null): Observable<Array<NoticeModel>> {
    var tag = tags.join(',');
    
    var url = this.config.getApiRoute(`api/v3/customer-portal/credit-card/notices/${tag}`);
    
    if (invoice_id) {
      url += `/i/${invoice_id}`;
    }

    if (payment_method_id) {
      url += `/pm/${payment_method_id}`;
    }

    return this.http.get<Array<NoticeModel>>(url).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetSurcharge(invoice_model: InvoiceModel, payment_method_model: PaymentMethodModel): Observable<SurchargeModel> {
    var type_tag = payment_method_model.cc_number_mask ? payment_method_model?.bin === true ? 'debit' : payment_method_model?.bin === false ? 'credit' : 'default' : 'default';

    if (type_tag !== 'credit') {
      return of(new SurchargeModel());
    }

    let url = encodeURI(this.config.getApiRoute(`api/v3/customer-portal/credit-card/surcharge-amount/${invoice_model.total_balance}`));

    return this.http.get<SurchargeModel>(url).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      })); ;
  }

  public BinCheck(card_number:string): Observable<CardIdentificationModel> {
    return this.http.get<CardIdentificationModel>(this.config.getApiRoute(`api/v3/customer-portal/credit-card/bin-check/${card_number}`)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetAutoPayNotices(): Observable<Array<NoticeModel>> {
    return this.http.get<Array<NoticeModel>>(this.config.getApiRoute(`api/v3/customer-portal/credit-card/auto-pay/notices`)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
}
