<div id="payment-method-required-container" class="container">
  <mat-card>
    <mat-card-title>Payment Method is required</mat-card-title>
    <mat-card-content>
      <p>A payment method must be on file before you can use our service.</p>
    </mat-card-content>
    <mat-card-actions>
      <button class="submit-button" mat-raised-button [routerLink]="['/payment-methods']" color="primary">Continue</button>
    </mat-card-actions>
  </mat-card>
</div>