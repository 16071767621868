<div class="bg-light">
    <div class="container">
        <div class="row">
            <div id="login-art" class="col-md-6 d-none d-md-flex">

            </div>
            <div class="col-md-6 text-center">
                <div id="login-wrapper">
                <h2>Are You a Resident? Sign In.</h2>

                <form [formGroup]="form" method="post"  (ngSubmit)="submit()">
                    <div>
                        <mat-form-field>
                            <input matInput type="text" placeholder="Email" formControlName="email" />
                        </mat-form-field>
                    </div>
                    <div>
                        <mat-form-field>
                            <input matInput type="password" placeholder="Password" formControlName="password"  autocomplete="off"/>
                        </mat-form-field>
                    </div>
                    <p><small>By logging in to this website, you are agreeing to our <a href="http://www.simplebills.com/terms-of-service">Terms of Service</a>.</small></p>
                    <p>
                        <button mat-raised-button type="submit" color="primary">Sign In</button>
                    </p>
                    <nexus-form-result [model]="result_model"></nexus-form-result>

                    <p class="font-weight-lighter">Forgot your password? <a [routerLink]="['/forgot-password']">Reset</a></p>

                    <hr class="green-rule" />
                    <p class="font-weight-bolder">Don’t have an account? <a [routerLink]="['/signup']">Sign Up!</a></p>
                    <hr class="green-rule" />
                    <p class="font-weight-bolder">Are you looking for the client portal? <a href="https://clients.simplebills.com">Sign in Here.</a></p>

                </form>
            </div>
            </div>
        </div>
    </div>
</div>
