import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
import { PaymentMethodService } from 'app/services/payment-method.service';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { RegionModel } from 'app/core/models/region.model';

@Component({
    selector: 'app-payment-method-bank',
    templateUrl: './payment-method-bank.component.html',
    styleUrls: ['./payment-method-bank.component.css']
})
export class PaymentMethodBankComponent extends NexusComponent implements OnInit {
    public regions_model: Array<RegionModel>;
    public payment_methods_model: Array<PaymentMethodModel>;
    public payment_method_model: PaymentMethodModel = new PaymentMethodModel();
    public result_model: NexusFormResultModel = new NexusFormResultModel();
    public un_link_pressed: boolean;

    public form = new UntypedFormGroup({
        ch_name_on_check: new UntypedFormControl("", Validators.required),
        ch_number_mask: new UntypedFormControl("", Validators.required),
        ch_transit_mask: new UntypedFormControl("", this.ValidateBankRouting),
        ch_bank_name: new UntypedFormControl("", Validators.required)
    });

    public edit: boolean = true;

    constructor(private route: ActivatedRoute, private router: Router, private membership: MembershipService, private payment_method_service: PaymentMethodService) {
        super();
        this.un_link_pressed = false;

    }

    ngOnInit() {
        this.route.data.subscribe(({ regions_model, payment_methods_model, payment_method_model }) => {
            this.regions_model = regions_model;
            this.payment_methods_model = payment_methods_model;
            if (payment_method_model) {
                this.payment_method_model = payment_method_model;
                this.form.patchValue(this.payment_method_model);
            }
        });
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.payment_method_model);

            if(!this.edit)
            {
                this.edit = true;   
                this.form.get('ch_number_mask').setValue('');
                this.form.get('ch_transit_mask').setValue('');
                this.payment_method_model.ch_number_mask = null;
                this.payment_method_model.ch_transit_mask = null;
            }
        });
    }

    submit() {
        this.result_model.Reset();
        this.StartSpinner();
        this.payment_method_model.account_type = 'Checking';
        this.payment_method_service.SavePaymentMethod(this.payment_method_model).subscribe(
            data => {
                this.membership.OverrideGoodStanding();
                this.router.navigate(['/payment-methods']);
                this.StopSpinner();
            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });
    }

    unLink() {
        this.un_link_pressed = true;
    }

    confirmUnLink() {
        this.result_model.Reset();
        this.StartSpinner();

        this.payment_method_service.DeletePaymentMethod(this.payment_method_model.id).subscribe(
            data => {
                this.router.navigate(['/payment-methods']);
                this.StopSpinner();
            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });
    }
}
