import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { EstimatedReconciliationService } from 'app/services/estimated-reconciliation.service';
import { TrueUpFeatureModel } from 'app/models/true-up.model';

@Injectable()
export class TrueUpConfigResolver implements Resolve<TrueUpFeatureModel> {

    constructor(private service: EstimatedReconciliationService)
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<TrueUpFeatureModel> {
        return this.service.GetTrueUpConfig();
    }
}
