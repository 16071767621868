import { Component, OnInit } from '@angular/core';
import { OptInModel } from 'app/models/opt-in.model';
import { ActivatedRoute, Router } from '@angular/router';
import { OptInService } from 'app/services/opt-in.service';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { UntypedFormGroup, UntypedFormControl, Validators, FormBuilder } from '@angular/forms';
import { MembershipService } from 'app/core/services/membership.service';

@Component({
  selector: 'app-opt-in',
  templateUrl: './opt-in.component.html',
  styleUrls: ['./opt-in.component.scss']
})
export class OptInComponent extends NexusComponent {

  public user_in_group_id: string;
  public opt_in_token: string;

  public opt_in_model: OptInModel;

  public form = new UntypedFormGroup({
    password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)]),
    confirm_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)])
});
  public result_model: NexusFormResultModel = new NexusFormResultModel();

  constructor(private route: ActivatedRoute, private router: Router, private opt_in_service: OptInService, public membership: MembershipService) {
    super();
  }

  ngOnInit(): void {
    this.route.data.subscribe(({ opt_in_model }) => {
      this.opt_in_model = opt_in_model;
    });
    this.route.params.subscribe(({ user_in_group_id, opt_in_token }) => {
      this.user_in_group_id = user_in_group_id;
      this.opt_in_token = opt_in_token;
    });
    this.form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.opt_in_model);
    });
  }

  public Accept(): void {
    this.opt_in_model.accept = true;

  }

  public SubmitAccept(): void {

    this.result_model.Reset();
    
    if (this.form.invalid) {
      this.result_model.Incomplete();
      return;
    }    

    this.StartSpinner();

    this.opt_in_service.SaveOptIn(this.user_in_group_id, this.opt_in_token, this.opt_in_model).subscribe(
      data => {
        this.result_model.Success('Opt-In Successful!');
        this.StopSpinner();
        if (data && data.token) {
          this.result_model.Success();
          this.membership.SetToken(data);

          if (!data.enrollment_complete) {
              this.router.navigate(['/signup', data.signup_id]);
              return;
          }

          this.router.navigate(['/']);
      }
      },
      err => {
        this.result_model.Error(err);
        this.StopSpinner();
      });
  }


  public Reject(): void {
    this.opt_in_model.accept = false;

    this.result_model.Reset();
    this.StartSpinner();

    this.opt_in_service.SaveOptIn(this.user_in_group_id, this.opt_in_token, this.opt_in_model).subscribe(
      data => {

        this.result_model.Success("We are sorry to hear that! If you change your mind, give us a call!");
        this.StopSpinner();
      },
      err => {
        this.result_model.Error(err);
        this.StopSpinner();
      });
  }

}
