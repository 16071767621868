import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { InvoiceModel } from 'app/models/invoice.model';
import { InvoiceService } from 'app/services/invoice.service';

@Injectable()
export class InvoiceResolver implements Resolve<InvoiceModel> {

    constructor(private service: InvoiceService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<InvoiceModel> {
        return this.service.GetInvoice(route.params.invoice_id);
  }
}
