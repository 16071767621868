<mat-expansion-panel class="mb-3" [expanded]="true">
    <mat-expansion-panel-header class="parent-header">
        <mat-panel-title>
            Utility Charges
        </mat-panel-title>
        <mat-panel-description>
            <span>{{ utility_total | currency }}</span>
        </mat-panel-description>
    </mat-expansion-panel-header>

    <app-invoice-items-accordion [items]="utility_items"></app-invoice-items-accordion>

</mat-expansion-panel>


<mat-expansion-panel class="mb-3" [expanded]="default_customer_array.length>0">
    <mat-expansion-panel-header class="parent-header">
        <mat-panel-title>
            Services & Taxes
        </mat-panel-title>
        
        <mat-panel-description>
            {{ fee_total | currency }}
        </mat-panel-description>
    </mat-expansion-panel-header>

    <br>
    <div *ngIf="invoice_model.invoice_notice_message" class="alert alert-warning mb-3">
        <div style="float: left;">
            <fa-icon [icon]="faExclamationCircle"></fa-icon>
        </div>
        <div style="text-align:center;">
            {{ invoice_model.invoice_notice_message }}
        </div>  
    </div>

    <app-invoice-items-accordion [items]="fee_items"></app-invoice-items-accordion>

</mat-expansion-panel>