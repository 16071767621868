var Years = [];

var current_year = new Date().getFullYear();

for( var i = current_year; i < current_year + 10; i++ )
{
    Years.push({
        "name": (i),
        "numeric": i             
    });
} 

export default Years;