import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { InvoiceModel } from 'app/models/invoice.model';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { InvoiceService } from 'app/services/invoice.service';
import { faCcMastercard, faCcVisa, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faLock, faMoneyCheckAlt} from '@fortawesome/free-solid-svg-icons';
import Months from '../../statics/Months';
import Years from '../../statics/Years';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PaymentMethodService } from 'app/services/payment-method.service';
import { MembershipService } from 'app/core/services/membership.service';
import { CreditCardService } from 'app/services/credit-card.service';
import { CardIdentificationModel } from 'app/models/card-identification.model';
import { NoticeModel } from 'app/models/notice.model';
import { MatDialog } from '@angular/material/dialog';
import { CardDisclosuresDialogComponent } from '../card-disclosures-dialog/card-disclosures-dialog.component';
import { SurchargeModel } from 'app/models/surcharge.model';

@Component({
  selector: 'app-invoice-one-time-payment-method',
  templateUrl: './invoice-one-time-payment-method.component.html',
  styleUrls: ['./invoice-one-time-payment-method.component.scss']
})
export class InvoiceOneTimePaymentMethodComponent extends NexusComponent implements OnInit {
  public invoice_model: InvoiceModel;
  public payment_method_model: PaymentMethodModel= new PaymentMethodModel();;
  public result_model: NexusFormResultModel = new NexusFormResultModel();
  public surcharge_model: SurchargeModel = new SurchargeModel();
  public months = Months;
  public years = Years;
  public faCcMastercard = faCcMastercard;
  public faCcVisa = faCcVisa;
  public faCcDiscover = faCcDiscover;
  public faLock = faLock;
  public faMoneyCheckAlt = faMoneyCheckAlt;

  public credit_card_save_on_file:boolean = false;
  public bank_save_on_file:boolean = false;
  public expand_credit:boolean = false;
  public expand_bank:boolean = false;

  public creditform = new UntypedFormGroup({
    cc_name_on_card: new UntypedFormControl("", Validators.required),
    cc_number_mask: new UntypedFormControl("", this.ValidateCreditCardNumber),
    cc_exp_month: new UntypedFormControl("", Validators.required),
    cc_exp_year: new UntypedFormControl("", Validators.required),
    cc_card_code: new UntypedFormControl("", this.ValidateCVV),
    cc_street: new UntypedFormControl("", Validators.required),
    cc_zip: new UntypedFormControl("", Validators.required),
    save_on_file: new UntypedFormControl("")
  });

  public notices: Array<NoticeModel> = [];
  public default_card_storage_notice_html: string = 'By saving this payment method on file, you agree that this payment method can be used to pay any outstanding balance on your account per our <a href="http://www.simplebills.com/terms-of-service">Terms of Service</a>.';
  public card_storage_notice_html: string = 'By saving this payment method on file, you agree that this payment method can be used to pay any outstanding balance on your account per our <a href="http://www.simplebills.com/terms-of-service">Terms of Service</a>.';
  
  public bankform = new UntypedFormGroup({
    ch_name_on_check: new UntypedFormControl("", Validators.required),
    ch_number_mask: new UntypedFormControl("", Validators.required),
    ch_transit_mask: new UntypedFormControl("", this.ValidateBankRouting),
    ch_bank_name: new UntypedFormControl("", Validators.required),
    save_on_file: new UntypedFormControl()
});

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private invoice_service: InvoiceService, 
    private payment_method_service:PaymentMethodService,
    private membership: MembershipService,
    private credit_card_service: CreditCardService,
    private dialog: MatDialog) {
    super();
}

  ngOnInit() {
    this.route.data.subscribe(({ invoice_model }) => {
      this.invoice_model = invoice_model;
  });

  this.creditform.valueChanges.subscribe((form: any) => {
    this.PopulateModelFromForm(form, this.payment_method_model);
    if (this.creditform.get('cc_exp_month').value && this.creditform.get('cc_exp_year').value) {
      this.payment_method_model.cc_expiration_date = new Date(this.creditform.get('cc_exp_year').value, this.creditform.get('cc_exp_month').value - 1, 1);
    }
    this.payment_method_model.account_type = 'CreditCard';
    this.payment_method_model.one_time_payment = (form.save_on_file??false)?false:true;

    this.binCheck(this.payment_method_model.cc_number_mask);
  });

  this.bankform.valueChanges.subscribe((form: any) => {
    this.PopulateModelFromForm(form, this.payment_method_model);
    this.payment_method_model.account_type='Checking';
    this.payment_method_model.cc_expiration_date=null;
    this.payment_method_model.one_time_payment = (form.save_on_file??false)?false:true;

    this.binCheck("");
  });
  }


  submit() {
    this.result_model.Reset();

    if (this.expand_credit === true && (this.notices?.length ?? 0) !== 0) {
      const dialogRef = this.dialog.open(CardDisclosuresDialogComponent, {
        data: this.notices,
        minHeight:'60%',
        maxHeight:'100%',
        minWidth:'35%',
        maxWidth:'75%'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result === true) {
          this.completeSubmit();
        }
        else {
          this.result_model.Error('Please make any modifications needed before continuing.');
        }
      });
    }
    else {
      this.completeSubmit();
    }
  }

  completeSubmit() {
    this.StartSpinner();
    console.log(this.payment_method_model);
    this.invoice_service.PayInvoice(this.invoice_model.id, this.payment_method_model).subscribe(
      data => {
          this.StopSpinner();
          this.router.navigate(['/invoices', this.invoice_model.id, 'payments', data.id]);
      },
      err => {
          this.result_model.Error(err);
          this.StopSpinner();
      });
  }

  expand_payment_method(row: string){
    if(row==='credit'){
      this.expand_credit = true;
      this.expand_bank = false;
      this.bankform.reset();
    }
    else{
      this.expand_credit = false;
      this.expand_bank = true;
      this.creditform.reset();
    }
  }

  private _lastBinCheck: string = "";
  binCheck(card_number: string) {
    if (this._lastBinCheck === card_number)
      return;

    this._lastBinCheck = card_number;

    if (card_number && card_number?.length >= 4 && !isNaN(Number(card_number))) {
      this.credit_card_service.BinCheck(card_number).subscribe((cc_ident: CardIdentificationModel) => {
        var new_bin = cc_ident.card_type == "Unidentified" ? null : (cc_ident.card_type == "DebitCard");
        if (new_bin != this.payment_method_model.bin) {
          this.payment_method_model.bin = new_bin;
          this.getNotices(this.payment_method_model);
        }
      });      
    }
    else if ((!card_number || isNaN(Number(card_number)) || card_number?.length < 4) && this.payment_method_model.bin !== null) {
      this.payment_method_model.bin = null;
      this.getNotices(this.payment_method_model);
    }
  }

  getNotices(payment_method_model: PaymentMethodModel) {
    var type_tag = payment_method_model?.bin === true ? 'debit' : payment_method_model?.bin === false ? 'credit' : 'default';

    var tags = [type_tag, 'one-time-payment'];

    if (!this.payment_method_model.one_time_payment) {
      tags.push('storage');
    }

    this.credit_card_service.GetNotices(tags, this.payment_method_model.id).subscribe((data) => {
      this.notices = data;

      if ((this.notices?.length ?? 0) > 0) {
        if (this.notices.filter(n=> n.tags.filter(t=> t === 'storage').length > 0).length > 0) {
          this.card_storage_notice_html = this.notices.filter(n=> n.tags.filter(t=> t === 'storage').length > 0)[0].message;
        }
        else {
          this.card_storage_notice_html = this.default_card_storage_notice_html;
        }
      }
      else {
        this.card_storage_notice_html = this.default_card_storage_notice_html;
      }
    });

    this.getSurcharge(payment_method_model);
  }

  getSurcharge(payment_method_model: PaymentMethodModel) {
    this.credit_card_service.GetSurcharge(this.invoice_model, payment_method_model).subscribe((data) => {
      this.surcharge_model = data;
    });
  }

}
