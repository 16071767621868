import { Component, OnInit } from '@angular/core';
import { InvoiceModel } from 'app/models/invoice.model';
import { ActivatedRoute } from '@angular/router';
import { RegionModel } from 'app/core/models/region.model';
import { EstimatedReconciliationModel } from 'app/models/estimated-reconciliation.model';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { EstimatedReconciliationService } from 'app/services/estimated-reconciliation.service';
import { TrueUpFeatureModel } from 'app/models/true-up.model';

@Component({
  selector: 'app-estimated-reconciliation',
  templateUrl: './estimated-reconciliation.component.html',
  styleUrls: ['./estimated-reconciliation.component.scss']
})
export class EstimatedReconciliationComponent extends NexusComponent implements OnInit {

  public regions_model: Array<RegionModel>;
  public estimated_reconciliation_model: EstimatedReconciliationModel = new EstimatedReconciliationModel();
  public result_model: NexusFormResultModel = new NexusFormResultModel();
  public true_up_config_model: TrueUpFeatureModel;
  public form = new UntypedFormGroup({
    address1: new UntypedFormControl("", Validators.required),
    address2: new UntypedFormControl(),
    city: new UntypedFormControl("", Validators.required),
    region_id: new UntypedFormControl("", Validators.required),
    zip5: new UntypedFormControl("", this.ValidateZip),
    agree: new UntypedFormControl("", Validators.required)
  });

  constructor(private route: ActivatedRoute, private estimated_reconciliation_service: EstimatedReconciliationService) {
    super();
  }

  ngOnInit() {
    this.route.data.subscribe(({ regions_model, estimated_reconciliation_model,true_up_config_model }) => {
      this.regions_model = regions_model;
      this.estimated_reconciliation_model = estimated_reconciliation_model;
      this.true_up_config_model = true_up_config_model;
      this.form.patchValue(estimated_reconciliation_model);
      if(this.estimated_reconciliation_model.created_date)
      {
        this.form.disable();
      }
    });

    this.form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.estimated_reconciliation_model);
    });
  }

  submit() {
    this.result_model.Reset();

    if(!this.form.get('agree').value)
    {
      this.result_model.Error('You must agree to the terms and conditions.');
      return;
    }

    if (this.form.invalid) {
      this.result_model.Incomplete();
      return;
    }

    this.StartSpinner();

    this.estimated_reconciliation_service.SaveReconciliation(this.estimated_reconciliation_model).subscribe(
      data => {
        this.estimated_reconciliation_model = data;
        this.form.patchValue(data);
        this.form.disable();
        this.result_model.Success('Reconciliation Requested!');
        this.StopSpinner();
      },
      err => {
        this.result_model.Error(err);
        this.StopSpinner();
      });
  }

}
