import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { EnvironmentType } from 'app/enums/environment-type.enum';
import * as moment from 'moment/moment';
import * as Sentry from "@sentry/browser";
import * as am4core from "@amcharts/amcharts4/core";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import { map, switchMap, take, tap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { from } from 'rxjs/internal/observable/from';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private http: HttpClient;
  private config: NexusConfig;
/*
        These error filters exists in multiple projects.  To maintain continuity, ensure that each of the following projects are similarly updated, if the error applies across multiple projects.

        Projects:
             - Resident-Portal
             - Client-Portal
             - Nexus-Client
*/
//silencedErrors: add error text here when it is necessary to prevent the associated error from
  //  being logged with Sentry.  Entries added to this array should be limited and approved by
  //  project management.  Provide a brief note (or DevOps link) with each entry for reference.
    private silencedErrors: (string | RegExp)[] = [
        //Example entry, will identify when "throw('This is a silent error test!')" is called.
        "silent error test",

        //https://dev.azure.com/SimpleBills-Engineering/Nexus/_workitems/edit/3982
        "$root.threadManager().activeRoomInRTC().rtcroom is not a function.",

        //https://dev.azure.com/SimpleBills-Engineering/Nexus/_workitems/edit/3933
        "The request is not allowed by the user agent or the platform in the current context, possibly because the user denied permission.",

        //https://dev.azure.com/SimpleBills-Engineering/Nexus/_workitems/edit/3961
        /^QuotaExceededError.*/,

        /^NotAllowedError.*/,

        /^AbortError.*/
    ];

    //filter exceptions from these urls
    private denyUrls: string[] = [];

  constructor(handler: HttpBackend) {
    this.http = new HttpClient(handler);
  }


  public loadConfig(): Observable<any> {
    return from(this.http.get(environment.config)).pipe(
      take(1),
      map(data => {
        this.config = data as NexusConfig;

        am4core.addLicense(this.config.amcharts_license);
        am4core.useTheme(am4themes_animated);

        Sentry.init({
          dsn: this.config.sentry,
          environment: this.config.environment_type,
          release: this.config.build,
          integrations: [
            new Sentry.Integrations.TryCatch({
              XMLHttpRequest: false,
            }),
          ],
          ignoreErrors: this.silencedErrors,
          denyUrls: this.denyUrls,
        });

        return data;
      })
    );
  }

  public getApiRoute(route: string) : string
  {
    return this.config.service_base + route;
  }

  public isApiRoute(url: string): boolean
  {
    return url.startsWith(this.config.service_base);
  }

  public getEnvironmentType(): EnvironmentType
  {
    return this.config.environment_type;
  }

  public getCurrentMoment()
  {
    if(this.config.environment_type == EnvironmentType.Demo)
    {
      return moment('2019-11-01');
    }
    return moment();
  }

  public getBuild(): string {
    return this.config.build;
  }

  public get TutorialURL(): string {
    return this.config.tutorialURL;
  }
}

export interface NexusConfig {
  service_base: string;
  environment_type: EnvironmentType;
  sentry: string;
  build: string;
  amcharts_license: string;
  tutorialURL:string;
}
