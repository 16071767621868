import { Component, OnInit, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NexusComponent } from '../nexus/nexus.component';
import * as moment from 'moment';

@Component({
  selector: 'nexus-form-date-input',
  templateUrl: './nexus-form-date-input.component.html',
  styleUrls: ['./nexus-form-date-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => NexusFormDateInputComponent),
    }
  ]
})
export class NexusFormDateInputComponent  extends NexusComponent implements ControlValueAccessor, OnInit {

  public is_disabled: boolean = false;
  public day: number;
  public month: number;
  public year: number;  
  
  public months = [
    { name: "January", value: 0 },
    { name: "February", value: 1 },
    { name: "March", value: 2 },
    { name: "April", value: 3 },
    { name: "May", value: 4 },
    { name: "June", value: 5 },
    { name: "July", value: 6 },
    { name: "August", value: 7 },
    { name: "September", value: 8 },
    { name: "October", value: 9 },
    { name: "November", value: 10 },
    { name: "December", value: 11 }
  ];

  constructor() {
    super();
  }

  ngOnInit(){    
    this.handleChange();
  }

  private propagateChange = (value: any) => { };

  handleChange() {
    if(this.year && this.month && this.day)
    {
      this.propagateChange(moment.utc([this.year, this.month-1, this.day]).toDate());
    }
  }

  writeValue(obj: any): void {
    if (obj) {
      this.day = moment(obj).date();
      this.month = moment(obj).month()+1;
      this.year = moment(obj).year();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {

  }

  setDisabledState?(isDisabled: boolean): void {
    this.is_disabled = isDisabled;
  }

}
