<div class="container">
  <mat-card>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <mat-card-title>True-Up Request</mat-card-title>
      <mat-card-content   *ngIf="true_up_config_model.enabled" [innerHtml]="true_up_config_model.message">
      </mat-card-content>
      <mat-card-content   *ngIf="!true_up_config_model?.enabled">
        <p>Utilities are always billed after you use them–so the actual final bill for your house or apartment will only
          be issued by your utility provider after you move out. In some cases, this can be 8 to 12 weeks after you’ve
          packed up and left. No one wants to deal with a utility bill 2 months after the fact.</p>
        <p>To provide a better move out experience, SimpleBills estimates your final bill so you can be done with your
          house or apartment once you receive your Final Move-Out Statement from your property. This way, when you move
          out, you can feel free to move on to bigger and better things!</p>
        <p>If you choose to, you can contact SimpleBills to make arrangements to True-Up your estimated final bill with
          the actual final bill and SimpleBills will determine the following:</p>
        <p><strong>If the estimated bill is less than the actual bill</strong>, SimpleBills will make arrangements for
          you
          to pay more to
          cover the actual utility costs.</p>
        <p><strong>If the estimated bill is higher than the actual bill</strong>, SimpleBills will send a refund check
          to
          the permanent
          address you have provided.</p>
        <p>Your <strong>invoice</strong> must be settled with SimpleBills prior to requesting a True-Up.
          Please note the following:</p>
        <p>This True-Up cannot start until we have received all of the utility bills for the period of time that has
          been
          estimated. This may take up to 8 to 12 weeks after you move out. After SimpleBills has received all of the
          actual utility bills we will begin the True-Up process.</p>
        <p>You may request a reconciliation as soon as you pay your estimated invoice but you must fill out this form within your lease period and up to 14 days after your lease end date.
          All True-Up invoices generate a service fee.</p>
      </mat-card-content>
      <mat-card-content *ngIf="estimated_reconciliation_model.invoices?.length == 0">
        <div class="alert alert-info">You do not have any invoices eligible for True-Up.</div>
      </mat-card-content>
      <mat-card-content *ngIf="estimated_reconciliation_model.invoices?.length > 0">
        <h5>Invoices Eligible for True-Up</h5>
        <table class="table">
          <thead>
            <tr>
              <th>Issued</th>
              <th>Invoice #</th>
              <th>Amount</th>
              <th>Paid</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let invoice of estimated_reconciliation_model.invoices">
              <td>{{ invoice.date_sent | amDateFormat:'MMM D, YYYY' }}</td>
              <td>{{ invoice.invoice_number }}</td>
              <td>{{ invoice.item_amount | currency }}</td>
              <td>{{ invoice.settled_date | amDateFormat:'MMM D, YYYY' }}</td>
            </tr>
          </tbody>
        </table>
        <div style="max-width: 400px; margin: auto;">
          <h5>Permanent Mailing Address</h5>
          <p>We need an address to reach you at after you have moved out.</p>

          <div class="row">
            <div class="col-8 input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Street Address" formControlName="address1" />
              </mat-form-field>
            </div>
            <div class="col-4 input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Apt #" formControlName="address2" />
              </mat-form-field>
            </div>
          </div>
          <div class="input-cell">
            <mat-form-field>
              <input matInput type="text" placeholder="City" formControlName="city" />
            </mat-form-field>
          </div>
          <div class="row">
            <div class="col-7 input-cell">
              <mat-form-field>
                <mat-select placeholder="State" formControlName="region_id">
                  <mat-option *ngFor="let region of regions_model" [value]="region.id">
                    {{ region.name }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="col-5 input-cell">
              <mat-form-field>
                <input matInput type="text" placeholder="Zip" formControlName="zip5" mask="00000" />
              </mat-form-field>
            </div>
          </div>

          <p *ngIf="!estimated_reconciliation_model.created_date">
            <mat-checkbox formControlName="agree">I agree to the Terms and Conditions above.</mat-checkbox>
          </p>

        </div>

      </mat-card-content>
      <mat-card-actions *ngIf="estimated_reconciliation_model.invoices?.length > 0 && !estimated_reconciliation_model.created_date">
        <p><button class="submit-button" mat-raised-button type="submit" color="primary">Submit</button></p>
        <nexus-form-result [model]="result_model"></nexus-form-result>
      </mat-card-actions>
      <mat-card-actions *ngIf="estimated_reconciliation_model.invoices?.length > 0 && estimated_reconciliation_model.created_date">
        <div class="alert alert-info">You completed the request for True-Up on {{ estimated_reconciliation_model.created_date | amDateFormat:'MMM D, YYYY' }}.</div>
      </mat-card-actions>

    </form>
  </mat-card>
</div>
