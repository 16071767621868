import { Component, OnInit, Input } from '@angular/core';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';

@Component({
  selector: 'nexus-form-result',
  templateUrl: './nexus-form-result.component.html',
  styleUrls: ['./nexus-form-result.component.scss']
})
export class NexusFormResultComponent implements OnInit {
  @Input() public model: NexusFormResultModel;

  constructor() { }

  ngOnInit() {
  }

}
