import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { LoginModel } from 'app/core/models/login.model';
import { MembershipService } from 'app/core/services/membership.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent extends NexusComponent implements OnInit {
    public form = new UntypedFormGroup({
        email: new UntypedFormControl("", Validators.required),
        password: new UntypedFormControl("", Validators.required)
    });
    public model: LoginModel = new LoginModel();
    public result_model: NexusFormResultModel = new NexusFormResultModel();


    constructor(public membership: MembershipService, private router: Router, private route: ActivatedRoute) {
        super();
    }

    submit() {
        this.result_model.Reset();
        this.StartSpinner();

        this.membership.Login(this.model).subscribe(
            data => {
                this.StopSpinner();

                if (data.token) {
                    this.result_model.Success();
                    this.membership.SetToken(data);

                    if (!data.enrollment_complete) {
                        this.router.navigate(['/signup', data.signup_id]);
                        return;
                    }

                    if (!data.utilities_linked) {
                        this.router.navigate(['/utilities']);
                        return;
                    }

                    if (this.membership.redirectUrl) {
                        var route =this.membership.redirectUrl;
                        this.membership.redirectUrl=null;
                        this.router.navigate([route]);
                        return;
                    }

                    this.router.navigate(['/']);
                }
            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });
    }

    ngOnInit() {
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.model);
        });

        this.route.paramMap.subscribe(params => {
            if (params.has('email') && params.has('impersonation_id')) {
                this.model.email=params.get('email');
                this.model.impersonation_id = params.get('impersonation_id');
                this.submit();
            }
            if(params.has('external_token'))
            {
                this.model.external_token = params.get('external_token');
                this.submit();
            }
        });

    }
}
