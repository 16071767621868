<div id="credit-payment-method-container" class="container">
  <h2 *ngIf="payment_method_model.id">Update a Payment Account</h2>
  <h2 *ngIf="!payment_method_model.id">Add a Payment Account</h2>

  <div class="row">
    <div class="col-md-3 col-sm-4">
      <app-payments-menu></app-payments-menu>
    </div>
    <div class="col-md-9 col-sm-8">
      <div class="row">
        <div class="col-md-7">
          <mat-card>
            <form [formGroup]="form" (ngSubmit)="submit()">
              <mat-card-title *ngIf="!payment_method_model.id">Add Card</mat-card-title>
              <mat-card-title *ngIf="payment_method_model.id">Edit Card</mat-card-title>
              <mat-card-subtitle>
                A $2.95 verification transaction will be performed to validate your payment method.
                This transaction is temporary and will not be billed to your account, and the funds will be released in
                24 hours.
              </mat-card-subtitle>
              <mat-card-content>
                <div class="cc-icon">
                  <fa-icon [icon]="faCcMastercard"></fa-icon>
                  <fa-icon [icon]="faCcDiscover"></fa-icon>
                  <fa-icon [icon]="faCcVisa"></fa-icon>
                </div>
                <div class="input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Name on Card" formControlName="cc_name_on_card" />
                  </mat-form-field>
                </div>
                <div class="row">
                  <div class="col-7 input-cell">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Card Number" formControlName="cc_number_mask" [mask]="edit ? '0000-0000-0000-0000' : ''" />
                    </mat-form-field>
                  </div>
                  <div class="col-1 input-cell">
                    <div class="cc-icon-lock">
                      <fa-icon [icon]="faLock"></fa-icon>
                    </div>
                  </div>
                  <div class="col-4 input-cell">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Security Code" formControlName="cc_card_code"
                             mask="999" />
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-6 input-cell">
                    <mat-form-field>
                      <mat-select placeholder="Expiration Month" formControlName="cc_exp_month">
                        <mat-option *ngFor="let month of months" [value]="month.numeric">
                          {{ month.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div class="col-6 input-cell">
                    <mat-form-field>
                      <mat-select placeholder="Expiration Year" formControlName="cc_exp_year">
                        <mat-option *ngFor="let year of years" [value]="year.name">
                          {{ year.name }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="row">
                  <div class="col-8 input-cell">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Street Address" formControlName="cc_street" />
                    </mat-form-field>
                  </div>
                  <div class="col-4 input-cell">
                    <mat-form-field>
                      <input matInput type="text" placeholder="Zip" formControlName="cc_zip"
                             mask="00000" />
                    </mat-form-field>
                  </div>
                </div>              
              </mat-card-content>
              <div *ngIf="payment_information_acceptance" style="color:#999999;font-weight:normal;"><i [innerHTML]="payment_information_acceptance"></i></div>
              <mat-card-actions>
                <p><button mat-raised-button type="submit" [disabled]="!form.valid" color="primary">Save</button></p>
                <nexus-form-result [model]="result_model"></nexus-form-result>
              </mat-card-actions>
            </form>
          </mat-card>
        </div>
        <div class="col-md-5">
          <mat-card *ngIf="payment_method_model.id && payment_methods_model.length > 1">
            <mat-card-title>Delete Card</mat-card-title>

            <mat-card-actions>
              <button mat-raised-button (click)="unLink()" *ngIf="!un_link_pressed">Delete</button>
              <button mat-raised-button (click)="confirmUnLink()" *ngIf="un_link_pressed" color="warn">Confirm
                Delete</button>
            </mat-card-actions>
          </mat-card>
          <payment-method-instructions></payment-method-instructions>
        </div>
      </div>
    </div>
  </div>
</div>
