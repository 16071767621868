<div class="mdc1" mat-dialog-container>
  <div mat-dialog-content>
    <div>
      <h1 class="mdt1" mat-dialog-title>Learn how to use the SimpleBills Portal</h1>
    </div>
    <div class="video">
      <iframe class="iframe-div" [src]='safeUrl' frameborder="0" allowfullscreen></iframe>
    </div>
  </div>
  <div class="mda1" mat-dialog-actions>
    <button class="btn1"
            (click)="close()">Dismiss</button> 
  </div>
  <div *ngIf="data != null">
    <h3 class="mdt2" mat-dialog-title>You can access this video again from your Account Settings</h3>
    
  </div>
</div>
