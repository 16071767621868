//import { NgModule, Injector } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NexusFormResultComponent } from './components/nexus-form-result/nexus-form-result.component';
import { NexusComponent } from './components/nexus/nexus.component';
import { EventService } from './services/event.service';
//import { ModuleWithProviders } from '@angular/compiler/src/core';
import { NexusFormDateInputComponent } from './components/nexus-form-date-input/nexus-form-date-input.component';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MembershipService } from './services/membership.service';
import { AuthenticationGuard } from './guards/authentication.guard';
import { RegionService } from './services/region.service';
import { RegionsResolver } from './resolvers/regions.resolver';
import { NexusInjector } from './nexus.injector';
import { NgxMaskModule } from 'ngx-mask';
import { NexusInvoiceInformationComponent } from './components/nexus-invoice-information/nexus-invoice-information.component';
import { NgPipesModule } from 'ngx-pipes';
import { MatCardModule } from '@angular/material/card';
import { MomentModule } from 'ngx-moment';
import { DialogBodyComponent } from './components/dialog-body/dialog-body.component';
import { Injector, ModuleWithProviders, NgModule } from '@angular/core';

@NgModule({
  declarations: [
    NexusFormResultComponent,
    NexusComponent,
    NexusFormDateInputComponent,
    NexusInvoiceInformationComponent,
    DialogBodyComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,
    NgxMaskModule.forRoot(),
    NgPipesModule,
    MatCardModule,
    MomentModule
  ],
  exports: [
    NexusFormResultComponent,
    NexusFormDateInputComponent,
    NgxMaskModule,
    NexusInvoiceInformationComponent
  ]
  
})
export class CoreModule { 

  constructor(injector: Injector) {
    NexusInjector.setInjector(injector);

  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        MembershipService,
        EventService,
        RegionService,
        AuthenticationGuard,
        RegionsResolver
      ]
    };
  }
}
