<div id="payment-methods-wrapper" class="container">
  <h2>Payment Accounts</h2>

  <div class="row">
    <div class="col-md-3 col-sm-4">
      <app-payments-menu></app-payments-menu>
    </div>
    <div class="col-md-9 col-sm-8">
      <h3>Manage your primary & additional payment accounts</h3>
      <p>Your primary payment account is used by default to pay your bill. If you need to correct your payment method’s details, add your payment method again and then delete your original payment method.</p>
      <table class="table">
        <thead>
          <tr>
            <th>Date Created</th>
            <th>Account</th>
            <th>Type</th>
            <th>CC Expiration</th>
            <th>Primary</th>
            <th>Edit</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let payment_account of payment_methods_model">
            <td>{{ payment_account.created_date | amDateFormat:'l LT' }}</td>
            <td>{{ payment_account.number_mask }}</td>
            <td>{{ payment_account.bin === true ? 'Debit' : payment_account.account_type }}</td>
            <td>
              <span
                    *ngIf="payment_account.cc_expiration_date">{{ payment_account.cc_expiration_date | amDateFormat:'MMMM YYYY' }}</span>
            </td>
            <td>
              <a href="" (click)="setDefault(payment_account)">
                <fa-icon [icon]="payment_account.default_account ? faCheckSquare : faSquare"></fa-icon>
              </a>
            </td>
            <td>
              <a *ngIf="payment_account.account_type == 'Checking'"
                 [routerLink]="['/payment-methods', 'bank', payment_account.id]">
                <fa-icon [icon]="faPencilAlt"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Normal'" [icon]="icon_normal" [fixedWidth]="true" class="notification-indicator" [title]="notification(payment_account)?.title"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Urgent'" [icon]="icon_urgent" [fixedWidth]="true" class="notification-indicator" [title]="notification(payment_account)?.title"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Critical'" [icon]="icon_critical" [fixedWidth]="true" class="notification-indicator notification-critical" [title]="notification(payment_account)?.title"></fa-icon>
              </a>
              <a *ngIf="payment_account.account_type == 'CreditCard'"
                 [routerLink]="['/payment-methods', 'credit', payment_account.id]">
                <fa-icon [icon]="faPencilAlt"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Normal'" [icon]="icon_normal" [fixedWidth]="true" class="notification-indicator" [title]="notification(payment_account)?.title"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Urgent'" [icon]="icon_urgent" [fixedWidth]="true" class="notification-indicator" [title]="notification(payment_account)?.title"></fa-icon>
                <fa-icon *ngIf="notification(payment_account)?.priority=='Critical'" [icon]="icon_critical" [fixedWidth]="true" class="notification-indicator notification-critical" [title]="notification(payment_account)?.title"></fa-icon>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Add a Payment Account</h3>
      <div class="row">
        <div class="col-sm-6" *ngIf="regulatory_model.credit_payments_enabled">
          <a [routerLink]="['/payment-methods', 'credit', 'new']" class="payment-box">
            <div class="payment-image-wrapper">
              <div class="credit-card-image"></div>
            </div>
            <div class="payment-text-wrapper">
              <div class="payment-text">Add a <b>debit or credit card</b></div>
            </div>
          </a>
        </div>
        <div class="col-sm-6" *ngIf="regulatory_model.bank_payments_enabled">
          <a [routerLink]="['/payment-methods', 'bank', 'new']" class="payment-box">
            <div class="payment-image-wrapper">
              <div class="bank-image"></div>
            </div>
            <div class="payment-text-wrapper">
              <div class="payment-text">Add a <b>bank account</b></div>
            </div>
          </a>
        </div>
      </div>

    </div>
  </div>
</div>
