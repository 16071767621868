<mat-accordion [multi]="true" [displayMode]="'flat'">
    <mat-expansion-panel *ngFor="let item of items | orderBy:['position']" [expanded]="item.invoice_item_type.resident_class == 'default'">
        <mat-expansion-panel-header collapsedHeight="86px" expandedHeight="86px">
            <mat-panel-title>
                <div *ngIf="item.provider_category" class="service-category-title"
                    [attr.service-category-type]="item.provider_category.resident_class">
                    <span>{{ item.provider_category.name }}</span>
                    <span *ngIf="item.invoice_item_type.resident_class == 'default'"> - Balance Removed</span>
                </div>
                <div *ngIf="item.invoice_item_type.resident_class == 'miscellaneous'" class="service-category-title"
                    [attr.service-category-type]="item.invoice_item_type.resident_class">Miscellaneous</div>
                <div *ngIf="!item.expected_bill && item.invoice_item_type.resident_class != 'miscellaneous'"
                    class="service-category-title" [attr.service-category-type]="item.invoice_item_type.resident_class">
                    {{ item.reference }}</div>
            </mat-panel-title>
            <mat-panel-description> 
              <div class="d-flex align-items-center">
                <span *ngIf="item.invoice_item_type.mapping == 'EstimatedBill'" class="estimated">
                  Estimated
                </span>
                <span *ngIf="item.defaulted_date" class="badge badge-pill badge-danger pl-1 pr-1 pt-1">Forwarded to Property</span>
                <span>{{ item.amount | currency }}</span>
              </div>             
            </mat-panel-description>

        </mat-expansion-panel-header>
        <div class="invoice-item-content">
            <div class="row">
              <div class="offset-sm-3 col-sm-6">
                <div *ngIf="item.expected_bill?.provider as provider">
                  <strong>{{ item.group?.region?.code === RegionType.NC && provider.legal_name ? provider.legal_name: provider.name }}</strong>
                </div>
                <div>
                  {{ item.group?.name }}
                </div>
                <div *ngIf="item.start_date && item.end_date">
                  <span class="item-header usage-dates">
                    {{ item.start_date | amDateFormat:'MMM D, YYYY' }}
                    - {{ item.end_date | amDateFormat:'MMM D, YYYY' }}
                  </span>
                </div>
                <div *ngIf="item.usage_days">
                  <span class="item-header usage-days">({{ item.usage_days }} days)</span>
                </div>
                <br />
                <ng-container *ngIf="item.provider_bill_charge as provider_bill_charge">
                  <div class="row">
                    <div class="col-6 text-left">Bill Amount:</div>
                    <div class="col-6 text-right">
                      {{ provider_bill_charge.amount | currency }}
                    </div>
                  </div>
                  <div class="row"
                       *ngIf="provider_bill_charge.resident_portion && provider_bill_charge.resident_portion != provider_bill_charge.amount">
                    <div class="col-6 text-left">Resident Portion:</div>
                    <div class="col-6 text-right">
                      {{ provider_bill_charge.resident_portion | currency }}
                    </div>
                  </div>
                  <div class="row" *ngIf="provider_bill_charge.property_portion">
                    <div class="col-6 text-left">Property Portion:</div>
                    <div class="col-6 text-right">
                      {{ provider_bill_charge.property_portion | currency }}
                    </div>
                  </div>
                  <div class="row" *ngIf="provider_bill_charge.allowance_portion">
                    <div class="col-6 text-left">Your Allowance:</div>
                    <div class="col-6 text-right">
                      {{ provider_bill_charge.allowance_portion | currency }}
                    </div>
                  </div>
                  <div class="row" *ngIf="item.invoice_item_type.resident_class == 'actual-bill'">
                    <div class="col-6 text-left">Your Percentage:</div>
                    <div class="col-6 text-right">{{ item.percent | percent:'0.0-2' }}</div>
                  </div>
                  <hr />
                  <div class="row">
                    <div class="col-6 text-left">Your Portion</div>
                    <div class="col-6 text-right">{{ item.pre_allowance_amount | currency }}</div>
                  </div>
                  <hr />
                      <div class="row" *ngFor="let allowance_item of item.allowance_items">
                        <div class="col-9 text-left" *ngIf="allowance_item.reference">{{ allowance_item.reference }}</div>
                        <div class="col-9 text-left" *ngIf="!allowance_item.reference">{{ allowance_item.invoice_item_type.name }}</div>
                        <div class="col-3 text-right">{{ allowance_item.amount | currency }}</div>
                      </div>
                  <hr />
                  <div class="row">
                    <div class="col-6 text-left">Your Total</div>
                    <div class="col-6 text-right">{{ item.amount | currency }}</div>
                  </div>
                  <div *ngIf="provider_bill_charge.details?.length > 0">
                    <div style="margin-top: 15px;"><strong>Bill Breakdown</strong></div>
                    <div class="row" *ngFor="let detail of provider_bill_charge.details | orderBy:['-amount']">
                      <div class="col-6 text-left">{{ detail.detail_type.name }}:</div>
                      <div class="col-6 text-right">
                        <span>{{ detail.amount | currency }}</span>
                        <span class="breakdown-detail" *ngIf="detail.units">
                          <br />Usage:&nbsp;&nbsp;{{
                                        detail.units
                          }}
                          {{ detail.detail_type.usage_unit.display_name }}
                        </span>
                        <span class="breakdown-detail" *ngIf="detail.rate">
                          <br />Rate:&nbsp;&nbsp;{{
                                        detail.rate | currency
                          }}
                        </span>
                      </div>
                    </div>
                  </div>

                

                </ng-container>
                <ng-container *ngIf="item.provider_bill_charge?.provider_bill?.source_type != 'MeterConversion' && item.files as files">
                  <a class="pdf-download" *ngFor="let file of files" [href]="file.url" target="_blank"></a>
                </ng-container>

                <ng-container *ngIf="item.provider_bill_charge?.provider_bill?.source_type == 'MeterConversion'">
                  <button class="pdf-download" (click)="PdfProviderBillExport(item.provider_bill_charge.provider_bill.id)"></button>
                </ng-container>
              </div>
            </div>
            <div>
              <p *ngIf="item?.provider_bill_charge?.customer_note">
                {{ item.provider_bill_charge.customer_note }}
              </p>
            </div>
            <div *ngIf="item.invoice_item_type.mapping != 'EstimatedBill'" [innerHTML]="item.invoice_item_type.resident_description"></div>
            <div class="estimated-charge-note" *ngIf="item.invoice_item_type.mapping == 'EstimatedBill'">
              <p><span>This utility charge was estimated to expedite your move out process.</span></p>
              <p><a *ngIf="item.true_up_eligible" [routerLink]="['/true-up-request']">Click here to true up this estimate with the actual utility charge. Conditions may apply.</a></p>
            </div>
        </div>
    </mat-expansion-panel>
</mat-accordion>
