import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyBillComponent } from './components/my-bill/my-bill.component';
import { AuthenticationGuard } from './core/guards/authentication.guard';
import { EnrollmentCompleteGuard } from './guards/enrollment-complete.guard';
import { GoodStandingGuard } from './guards/good-standing.guard';
import { CurrentInvoiceResolver } from './resolvers/current-invoice.resolver';
import { InvoicesComponent } from './components/invoices/invoices.component';
import { InvoicesResolver } from './resolvers/invoices.resolver';
import { InvoiceComponent } from './components/invoice/invoice.component';
import { InvoiceResolver } from './resolvers/invoice.resolver';
import { InvoicePayComponent } from './components/invoice-pay/invoice-pay.component';
import { RegulatoryResolver } from './resolvers/regulatory.resolver';
import { PaymentMethodsResolver } from './resolvers/payment-methods.resolver';
import { RegionsResolver } from './core/resolvers/regions.resolver';
import { InvoicePaymentComponent } from './components/invoice-payment/invoice-payment.component';
import { InvoicePaymentResolver } from './resolvers/invoice-payment.resolver';
import { PaymentMethodsComponent } from './components/payment-methods/payment-methods.component';
import { PaymentMethodBankComponent } from './components/payment-method-bank/payment-method-bank.component';
import { PaymentMethodResolver } from './resolvers/payment-method.resolver';
import { PaymentMethodCreditComponent } from './components/payment-method-credit/payment-method-credit.component';
import { AutoPayComponent } from './components/auto-pay/auto-pay.component';
import { AutoPayResolver } from './resolvers/auto-pay.resolver';
import { UtilitiesComponent } from './components/utilities/utilities.component';
import { UtilitiesResolver } from './resolvers/utilities.resolver';
import { SettingsComponent } from './components/settings/settings.component';
import { SettingsResolver } from './resolvers/settings.resolver';
import { PaymentMethodRequiredComponent } from './components/payment-method-required/payment-method-required.component';
import { EstimatedReconciliationComponent } from './components/estimated-reconciliation/estimated-reconciliation.component';
import { EstimatedReconciliationResolver } from './resolvers/estimated-reconciliation.resolver';
import { LoginComponent } from './components/login/login.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './components/reset-password/reset-password.component';
import { ErrorComponent } from './components/error/error.component';
import { PrivateBrowsingNoticeComponent } from './components/private-browsing-notice/private-browsing-notice.component';
import { SupportComponent } from './components/support/support.component';
import { SignupHostComponent } from './components/signup-host/signup-host.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardResolver } from './resolvers/dashboard.resolver';
import { OptInComponent } from './components/opt-in/opt-in.component';
import { OptInResolver } from './resolvers/opt-in.resolver';
import { InvoiceOneTimePaymentMethodComponent } from './components/invoice-one-time-payment-method/invoice-one-time-payment-method.component';
import { PaymentMethodNotificationsResolver } from './resolvers/payment-method-notifications.resolver';
import { TrueUpConfigResolver } from './resolvers/true-up-config.resolver';

export const routes: Routes = [
  {
    path: 'my-bill',
    component: MyBillComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      invoice_model: CurrentInvoiceResolver
    }
  },
  {
    path: 'invoices',
    component: InvoicesComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      invoices_model: InvoicesResolver
    }
  },
  {
    path: 'invoices/:invoice_id',
    component: InvoiceComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      invoice_model: InvoiceResolver
    }
  },
  {
    path: 'invoices/:invoice_id/pay',
    component: InvoicePayComponent,
    canActivate: [AuthenticationGuard, GoodStandingGuard],
    resolve: {
      invoice_model: InvoiceResolver,
      regulatory_model: RegulatoryResolver,
      payment_methods_model: PaymentMethodsResolver,
      regions_model: RegionsResolver
    }
  },
  {
    path: 'invoices/:invoice_id/one-time-payment',
    component: InvoiceOneTimePaymentMethodComponent,
    canActivate: [AuthenticationGuard, GoodStandingGuard],
    resolve: {
      invoice_model: InvoiceResolver
    }
  },
  {
    path: 'invoices/:invoice_id/payments/:invoice_payment_id',
    component: InvoicePaymentComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      invoice_payment_model: InvoicePaymentResolver
    }
  },
  {
    path: 'payment-methods',
    component: PaymentMethodsComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard],
    resolve: {
      regulatory_model: RegulatoryResolver,
      payment_methods_model: PaymentMethodsResolver
    }
  },
  {
    path: 'payment-methods/bank/new',
    component: PaymentMethodBankComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      regions_model: RegionsResolver,
    }
  },
  {
    path: 'payment-methods/bank/:payment_method_id',
    component: PaymentMethodBankComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      regions_model: RegionsResolver,
      payment_methods_model: PaymentMethodsResolver,
      payment_method_model: PaymentMethodResolver,
      notifications_model: PaymentMethodNotificationsResolver
    }
  },
  {
    path: 'payment-methods/credit/new',
    component: PaymentMethodCreditComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      regions_model: RegionsResolver,
      regulatory_model: RegulatoryResolver,
    }
  },
  {
    path: 'payment-methods/credit/:payment_method_id',
    component: PaymentMethodCreditComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      regions_model: RegionsResolver,
      payment_methods_model: PaymentMethodsResolver,
      payment_method_model: PaymentMethodResolver,
      regulatory_model: RegulatoryResolver,
      notifications_model: PaymentMethodNotificationsResolver
    }
  },
  {
    path: 'auto-pay',
    component: AutoPayComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      auto_pay_model: AutoPayResolver
    }
  },
  {
    path: 'utilities',
    component: UtilitiesComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      utilities_model: UtilitiesResolver
    }
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [AuthenticationGuard],
    resolve: {
      settings_model: SettingsResolver
    }
  },
  {
    path: 'payment-method-required',
    canActivate: [AuthenticationGuard],
    component: PaymentMethodRequiredComponent,
    resolve: {
      dashboard_model: DashboardResolver
    }
  },
  {
    path: 'true-up-request',
    canActivate: [AuthenticationGuard],
    component: EstimatedReconciliationComponent,
    resolve: {
      regions_model: RegionsResolver,
      estimated_reconciliation_model: EstimatedReconciliationResolver,
      true_up_config_model: TrueUpConfigResolver
    }
  },
  {
    path: 'login/:email/:impersonation_id',
    component: LoginComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'external-login/:external_token',
    component: LoginComponent
  },
  {
    path: 'forgot-password',
    component: ForgotPasswordComponent
  },
  {
    path: 'reset-password/:user_id/:reset_id',
    component: ResetPasswordComponent
  },
  {
    path: 'error',
    component: ErrorComponent
  },
  {
    path: 'private-browsing-notice',
    component: PrivateBrowsingNoticeComponent
  },
  {
    path: 'support',
    component: SupportComponent
  },
  {
    path: 'opt-in/:user_in_group_id/:opt_in_token',
    component: OptInComponent,
    resolve: {
      opt_in_model: OptInResolver
    }
  },
  {
    path: 'signup',
    component: SignupHostComponent,
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule)
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthenticationGuard, EnrollmentCompleteGuard, GoodStandingGuard],
    resolve: {
      dashboard_model: DashboardResolver
    }
  },
  {
    path: '**',
    redirectTo: '/'
  }
];

@NgModule({
  imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', relativeLinkResolution: 'legacy' }) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}
