import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { InvoiceItemModel } from 'app/models/invoice-item.model';
import { InvoiceModel } from 'app/models/invoice.model';

@Component({
  selector: 'app-invoice-detail',
  templateUrl: './invoice-detail.component.html',
  styleUrls: ['./invoice-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceDetailComponent implements OnInit {

  public utility_items: Array<InvoiceItemModel>;
  public utility_total: number;
  public fee_items: Array<InvoiceItemModel>;
  public fee_total: number;
  public default_customer_array: any;
  public invoice_model: InvoiceModel;
  public faExclamationCircle = faExclamationCircle;

  @Input('invoice') public set update_items(data: InvoiceModel)
  {
    this.utility_items = data.items.filter(f=>f.expected_bill != null);
    this.utility_total = this.utility_items.reduce((sum, current) => sum + current.amount, 0);
    this.fee_items = data.items.filter(f=>f.expected_bill == null);
    this.fee_total = this.fee_items.reduce((sum, current) => sum + current.amount, 0);
    this.default_customer_array = this.fee_items.filter(res => res.invoice_item_type.resident_class === 'default')
    this.invoice_model = data;
  }

  constructor() { }

  ngOnInit() {
  }

}
