import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DialogBodyComponent } from '../../core/components/dialog-body/dialog-body.component';
import { DashboardModel } from '../../models/dashboard.model';
import { SettingsService } from '../../services/settings.service';

@Component({
  selector: 'app-payment-method-required',
  templateUrl: './payment-method-required.component.html',
  styleUrls: ['./payment-method-required.component.css']
})
export class PaymentMethodRequiredComponent implements OnInit, AfterViewInit {

  public dashboard_model: DashboardModel;

  constructor(private route: ActivatedRoute, private dialog: MatDialog, private settings_service: SettingsService) {
  }

  ngOnInit() {
    this.route.data.subscribe(({ dashboard_model }) => {
      this.dashboard_model = dashboard_model;
    });
  }

  ngAfterViewInit() {
    if (this.dashboard_model.customer.tutorial_watched == null && this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url != null && this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url != "") {
      this.openDialog();
    }
  }

  openDialog(): void {
    if (this.dashboard_model.customer.tutorial_watched == null) {
      const dialogRef = this.dialog.open(DialogBodyComponent, {
        data: { message: "You can access this video again from your Account Settings", url: this.dashboard_model.group?.property?.market?.resident_portal_tutorial_url },
        height: '585px',
        width: '900px'
      });

      dialogRef.afterClosed().subscribe(result => {
        this.settings_service.PutTutorial(this.dashboard_model.customer).subscribe(
          data => {
          },
          err => {
          });
      });
    }
  }
}
