<h1 mat-dialog-title>Disclosures</h1>
<div mat-dialog-content class="column">
    <div *ngIf="(notices?.length ?? 0) > 0" class="d-flex align-items-center">
        <ul>
            <li *ngFor="let notice of notices" class="w-100">
                <h3>{{notice.title}}</h3>
                <p [innerHTML]="notice.message"></p>
            </li>
        </ul>        
    </div>

    <div *ngIf="(notices?.length ?? 0) === 0" class="h-100 d-flex align-items-center">
        <div class="w-100 text-center text-muted"><em>{{empty_msg}}</em></div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button cdkFocusInitial (click)="onAgree()">I Agree</button>
    <button mat-stroked-button [mat-dialog-close]="notices">I Disagree</button>
</div>
