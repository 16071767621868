<div class="bg-light">
  <div class="container">
    <div class="row">
      <div id="login-art" class="col-md-6 d-none d-md-flex">

      </div>
      <div class="col-md-6 text-center">
        <div id="login-wrapper">
          <h2>Forgot Password</h2>

          <form [formGroup]="form" (ngSubmit)="submit()">
            <div>
              <mat-form-field>
                <input matInput type="text" placeholder="Email" formControlName="email" required />
              </mat-form-field>
            </div>

            <p><button mat-raised-button type="submit" color="primary"
                [disabled]="!form.valid || success_submit">Submit</button></p>

              <nexus-form-result [model]="result_model"></nexus-form-result>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>