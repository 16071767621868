export class CustomerModel
{
    public id: string;
    public first_name: string;
    public last_name: string;
    public email: string;
    public secondary_email: string;
    public phone: string;
    public secondary_phone: string;
    public password: string;
    public credit_reporting_enabled: boolean;
    public block_signup: boolean;
    public tutorial_watched: Date;
}

