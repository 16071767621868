import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt, faSquare, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { PaymentMethodService } from 'app/services/payment-method.service';
import { CustomerRegulatoryModel } from 'app/models/customer-regulatory.model';
import { faBell, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { SystemNotificationModel } from 'app/models/system-notification.model';

@Component({
    selector: 'app-payment-methods',
    templateUrl: './payment-methods.component.html',
    styleUrls: ['./payment-methods.component.css']
})
export class PaymentMethodsComponent extends NexusComponent implements OnInit {
    public regulatory_model: CustomerRegulatoryModel;
    public payment_methods_model: Array<PaymentMethodModel>;
    public error: string;
    public faPencilAlt = faPencilAlt;
    public faSquare = faSquare;
    public faCheckSquare = faCheckSquare;

    public icon_normal = faBell;
    public icon_urgent = faExclamation;
    public icon_critical = faExclamationTriangle;

    constructor(private route: ActivatedRoute, private router: Router, private payment_method_service: PaymentMethodService) {
        super();
    }

    ngOnInit() {
        this.route.data.subscribe(({ regulatory_model, payment_methods_model }) => {
            this.regulatory_model = regulatory_model;
            this.payment_methods_model = payment_methods_model;
        });
    }

    setDefault(payment_method: PaymentMethodModel) {
        this.StartSpinner();
        this.error = null;
        this.payment_method_service.SetDefaultPaymentMethod(payment_method.id).subscribe(
            data => {
                this.payment_methods_model = data;
                this.StopSpinner();
            },
            err => {
                this.error = err;
                this.StopSpinner();
            });
        return false;
    }

    notification(payment_method: PaymentMethodModel): SystemNotificationModel {
        if ((payment_method?.notifications?.length ?? 0) == 0) {
            return null;
        }

        return payment_method.notifications[0];
    }
}
