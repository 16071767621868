
import {throwError,  Observable } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { CustomerRegulatoryModel } from 'app/models/customer-regulatory.model';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class RegulatoryService {

  constructor(private http: HttpClient, private config: ConfigService) {

  }


  public Get(): Observable<CustomerRegulatoryModel> {
      return this.http.get<CustomerRegulatoryModel>(this.config.getApiRoute('api/v3/customer-portal/regulatory')).pipe(
          map(data => {
              return data;
          }),
          catchError((err: HttpErrorResponse)  => {
            return throwError(err.error.message);  
          }),);

  }
}
