import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, AbstractControl, Validators, ValidatorFn } from '@angular/forms';
import { EventService } from 'app/core/services/event.service';
import { NexusInjector } from 'app/core/nexus.injector';
import * as moment from 'moment';

@Component({
  selector: 'app-nexus',
  templateUrl: './nexus.component.html',
  styleUrls: ['./nexus.component.scss']
})
export class NexusComponent {
  private event_service: EventService;

  constructor() {
    const injector = NexusInjector.getInjector();
    this.event_service = injector.get(EventService);
  }
  
  public StartSpinner() {
    this.event_service.loadingStart();
  }

  public StopSpinner() {
    this.event_service.loadingFinish();
  }


  public PopulateModelFromForm(form_data, model) {
    for (var propName in form_data) {
      model[propName] = form_data[propName];
    }
  }

  public CreateFormControl(current_value = null) {
    return new UntypedFormControl({ value: current_value, disabled: true });
  }
  public CreateRequiredFormControl(current_value = null) {
    return new UntypedFormControl({ value: current_value, disabled: true }, Validators.required);
  }

  public ClearFormControlValidator(control: AbstractControl) {
    control.clearValidators();
    control.updateValueAndValidity();
  }

  public SetFormControlValidator(control: AbstractControl, validators: ValidatorFn) {
    control.setValidators(validators);
    control.updateValueAndValidity();
  }


  ValidateOptionalEmail(control) {
      if (control.value == null || control.value == '') {
          return null;
      }
      // RFC 2822 compliant regex
      if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
          return null;
      } else {
          return { 'invalidEmailAddress': true };
      }
  }

  ValidateEmail(control) {
      // RFC 2822 compliant regex
      if (control.value && control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
          return null;
      } else {
          return { 'invalidEmailAddress': true };
      }
  }

  ValidatePhone(control) {
      if (control.value && control.value.match(/^\(??\d\d\d\)??-??\s??\d\d\d-??\s??\d\d\d\d$/)) {
          return null;
      } else {
          return { 'invalidPhoneNumber': true };
      }
  }

  ValidateOptionalPhone(control) {
      if (control.value == null || control.value == '' || control.value=='(___) ___-____')
      {
          return null;
      }
      if (control.value && control.value.match(/^\(??\d\d\d\)??-??\s??\d\d\d-??\s??\d\d\d\d$/)) {
          return null;
      } else {
          return { 'invalidPhoneNumber': true };
      }
  }

  ValidateCVV(control) {
      if (control.value && control.value.match(/\d{3}/)) {
          return null;
      } else {
          return { 'invalidCVV': true };
      }
  }

  ValidateZip(control) {
      if (control.value && control.value.match(/\d{5}/)) {
          return null;
      } else {
          return { 'invalidZip': true };
      }
  }

  ValidateBankRouting(control) {
      if (control.value && control.value.match(/\d{9}/)) {
          return null;
      } else {
          return { 'invalidBankRouting': true };
      }
  }

  ValidateCreditCardNumber(control) {
      if (control.value && control.value.startsWith('x'))
      {
          return null;
      }

      if (control.value && control.value.match(/\d{16}/)) {
          return null;
      } else {
          return { 'invalidCreditCardNumber': true };
      }
  }

  ValidateSSN(control) {
      if (control.value && control.value.startsWith('x'))
      {
          return null;
      }

      if (control.value && control.value.match(/\d{3}-\d{2}-\d{4}/)) {
          return null;
      } else {
          return { 'invalidSocialSecurityNumber': true };
      }
  }
}
