<div id="invoice-pay-container" class="container">
  <div class="row">
    <div id="current-invoice-summary" class="col-md-4">
      <module-nexus-invoice-information [invoice_model]="invoice_model">
      </module-nexus-invoice-information>
    </div>
    <div class="col-md-8">
      <mat-card *ngIf="payment_methods_model.length == 0">
        <mat-card-title>Confirm Payment Method</mat-card-title>
        <mat-card-subtitle>We need to confirm your method of payment. Please re-enter your details and click Submit
          Payment.</mat-card-subtitle>
        <mat-card-content *ngIf="!payment_method_form">
          <div class="row">
            <div class="col-sm-6" *ngIf="regulatory_model.credit_payments_enabled">
              <a href="" class="payment-box" (click)="startCreditPaymentMethodForm($event)">
                <div class="payment-image-wrapper">
                  <div class="credit-card-image"></div>
                </div>
                <div class="payment-text-wrapper">
                  <div class="payment-text">Link a <b>debit or credit card</b></div>
                </div>
              </a>
            </div>
            <div class="col-sm-6">
              <a href="" class="payment-box" (click)="startBankPaymentMethodForm($event)">
                <div class="payment-image-wrapper">
                  <div class="bank-image"></div>
                </div>
                <div class="payment-text-wrapper">
                  <div class="payment-text">Link a <b>bank account</b></div>
                </div>
              </a>
            </div>
          </div>
        </mat-card-content>
        <form *ngIf="payment_method_form" [formGroup]="payment_method_form" (ngSubmit)="submitPayment()">
          <mat-card-subtitle *ngIf="show_credit_form">Credit Card Information</mat-card-subtitle>
          <mat-card-content *ngIf="show_credit_form">
            <div class="cc-icon">
              <fa-icon [icon]="faCcMastercard"></fa-icon>
              <fa-icon [icon]="faCcDiscover"></fa-icon>
              <fa-icon [icon]="faCcVisa"></fa-icon>
            </div>

            <div class="payment-method-form-wrapper">
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Name on Card" formControlName="cc_name_on_card" />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-7 input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Card Number" formControlName="cc_number_mask"
                    [mask]="'0000-0000-0000-0000'" />
                  </mat-form-field>
                </div>
                <div class="col-1 input-cell">
                  <div class="cc-icon-lock">
                    <fa-icon [icon]="faLock"></fa-icon>
                  </div>
                </div>
                <div class="col-4 input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Security Code" formControlName="cc_card_code"
                      mask="999" />
                  </mat-form-field>
                </div>
              </div>
              <div class="row" style="margin-bottom: 30px;">
                <div class="col-6 input-cell">
                  <mat-form-field>
                    <mat-select placeholder="Expiration Month" formControlName="cc_exp_month">
                      <mat-option *ngFor="let month of months" [value]="month.numeric">
                        {{ month.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-6 input-cell">
                  <mat-form-field>
                    <mat-select placeholder="Expiration Year" formControlName="cc_exp_year">
                      <mat-option *ngFor="let year of years" [value]="year.name">
                        {{ year.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-subtitle *ngIf="show_bank_form">Bank Account Information</mat-card-subtitle>
          <mat-card-content *ngIf="show_bank_form">
            <div class="payment-method-form-wrapper">
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Name on Check" formControlName="ch_name_on_check" />
                </mat-form-field>
              </div>
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Account Number" formControlName="ch_number_mask" />
                </mat-form-field>
              </div>
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Routing Number" formControlName="ch_transit_mask"
                    mask="000000000" />
                </mat-form-field>
              </div>
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Bank Name" formControlName="ch_bank_name" />
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
          <mat-card-subtitle>Billing Information</mat-card-subtitle>
          <mat-card-content>
            <div class="payment-method-form-wrapper">
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="First Name" formControlName="first_name" />
                </mat-form-field>
              </div>
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="Last Name" formControlName="last_name" />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-8 input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Street Address" formControlName="address1" />
                  </mat-form-field>
                </div>
                <div class="col-4 input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Apt #" formControlName="address2" />
                  </mat-form-field>
                </div>
              </div>
              <div class="input-cell">
                <mat-form-field>
                  <input matInput type="text" placeholder="City" formControlName="city" />
                </mat-form-field>
              </div>
              <div class="row">
                <div class="col-7 input-cell">
                  <mat-form-field>
                    <mat-select placeholder="State" formControlName="region_id" (focus)="stateFocus($event)"
                      (keypress)="stateKeypress($event)">
                      <mat-option *ngFor="let region of regions_model" [value]="region.id"
                        (keypress)="stateKeypress($event)">
                        {{ region.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div class="col-5 input-cell">
                  <mat-form-field>
                    <input matInput type="text" placeholder="Zip" formControlName="zip" mask="00000" />
                  </mat-form-field>
                </div>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions>                    
            <div *ngIf="surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
              <div class="row"><div class="col-md-3 offset-3 text-left">Total Balance Due</div><div class="col-md-3 text-right">{{ surcharge_model.sale_amount| currency }}</div></div>
              <div class="row"><div class="col-md-3 offset-3 text-left">{{surcharge_model.surcharge_description}}</div><div class="col-md-3 text-right">{{ surcharge_model.surcharge_amount | currency }}</div></div>
              <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ surcharge_model.transaction_total | currency }}</div></div>
            </div> 
            <div *ngIf="!surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
              <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ invoice_model.total_balance | currency }}</div></div>
            </div>
            <p><button mat-raised-button color="primary" type="submit"
                [disabled]="!payment_method_form.valid">Save</button></p>

            <nexus-form-result [model]="payment_result_model"></nexus-form-result>
          </mat-card-actions>
        </form>
      </mat-card>

      <mat-card *ngIf="payment_methods_model.length > 0">
        <mat-card-title>Select Payment Method</mat-card-title>
        <mat-card-content>
          <mat-radio-group [(ngModel)]="payment_method_model.id">
            <a class="payment-account-wrapper"
              *ngFor="let payment_method of payment_methods_model | orderBy: ['-default_account']"
              [ngClass]="{ 'alert-success': payment_method.id === payment_method_model.id && one_time_payment === false}"
              (click)="select_payment_method(payment_method)">

              <mat-radio-button color="primary" [value]="payment_method.id"
                [checked]="payment_method.id == payment_method_model.id && one_time_payment === false"
                (change)="select_payment_method(payment_method)"></mat-radio-button>

              <dl class="dl-horizontal"
                *ngIf="payment_method.account_type==='CreditCard' || payment_method.account_type==='Credit'">
                <dt>Account Type</dt>
                <dd>{{ payment_method.bin === true ? 'Debit' : payment_method.account_type }}</dd>
                <dt>Name on Card</dt>
                <dd>{{ payment_method.cc_name_on_card }}</dd>
                <dt>Card Number</dt>
                <dd>{{ payment_method.cc_number_mask }}</dd>
                <dt>Expiration Month</dt>
                <dd>{{ get_expiration_month(payment_method) | number:'2.0' }}</dd>
                <dt>Expiration Year</dt>
                <dd>{{ get_expiration_year(payment_method) }}</dd>
              </dl>
              <dl class="dl-horizontal" *ngIf="payment_method.account_type==='Checking'">
                <dt>Account Type</dt>
                <dd>{{ payment_method.account_type }}</dd>
                <dt>Name on Check</dt>
                <dd>{{ payment_method.ch_name_on_check }}</dd>
                <dt>Account Number</dt>
                <dd>{{ payment_method.ch_number_mask }}</dd>

              </dl>
            </a>
            <a class="payment-account-wrapper pt-4 pb-4"
              [ngClass]="{ 'alert-success': one_time_payment}"
              (click)="one_time_payment_method()">
              <mat-radio-button id="one_time_payment_method" color="primary" [value]="one_time_payment"
              [checked]="one_time_payment"
              (change)="one_time_payment_method()"></mat-radio-button>

              <dl class="dl-horizontal">
                <dt>One Time Payment</dt>
              </dl>
            </a>
          </mat-radio-group>
        </mat-card-content>

        <mat-card-actions>
          <div *ngIf="surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
            <div class="row"><div class="col-md-3 offset-3 text-left">Total Balance Due</div><div class="col-md-3 text-right">{{ surcharge_model.sale_amount| currency }}</div></div>
            <div class="row"><div class="col-md-3 offset-3 text-left">{{surcharge_model.surcharge_description}}</div><div class="col-md-3 text-right">{{ surcharge_model.surcharge_amount | currency }}</div></div>
            <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ surcharge_model.transaction_total | currency }}</div></div>
          </div> 
          <div *ngIf="!surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
            <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ invoice_model.total_balance | currency }}</div></div>
          </div>
          <p><button mat-raised-button color="primary" [disabled]="!payment_method_model || submit_disabled"
              (click)="submitPayment()">Submit
              </button></p>
          <nexus-form-result [model]="payment_result_model"></nexus-form-result>
        </mat-card-actions>
      </mat-card>

    </div>
  </div>
</div>