import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { CustomerRegulatoryModel } from 'app/models/customer-regulatory.model';
import { RegulatoryService } from 'app/services/regulatory.service';

@Injectable()
export class RegulatoryResolver implements Resolve<CustomerRegulatoryModel> {

    constructor(private service: RegulatoryService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<CustomerRegulatoryModel> {
        return this.service.Get();
  }
}
