<div class="container">
  <mat-card>
    <mat-card-title *ngIf="invoice_payment_model.success">Your Payment Was Successful</mat-card-title>
    <mat-card-title *ngIf="!invoice_payment_model.success">Your Payment Was NOT Successful</mat-card-title>
    <mat-card-content>
      <dl class="dl-horizontal">
        <dt>Date</dt>
        <dd>{{ invoice_payment_model.payment_date | amDateFormat:'MMM D, YYYY' }}</dd>
        <dt>Amount</dt>
        <dd>{{ invoice_payment_model.amount | currency }}</dd>
        <dt>Payment Method</dt>
        <dd>
          {{ invoice_payment_model.number_mask }}
        </dd>
        <dt>Response</dt>
        <dd>{{ invoice_payment_model.response_message }}</dd>
        <dt>Code</dt>
        <dd>{{ invoice_payment_model.response_code }}</dd>
        <dt>Transaction ID</dt>
        <dd>{{ invoice_payment_model.response_transaction_id  }}</dd>
        <dt>Auth Code</dt>
        <dd>{{ invoice_payment_model.auth_code }}</dd>
      </dl>
    </mat-card-content>
</mat-card>
</div>