import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { UtilitiesService } from '../../services/utilities.service';
import { MembershipService } from 'app/core/services/membership.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { UtilitiesModel, UtilitiesGroupModel, UtilitiesGroupUtilityModel } from 'app/models/utilities.model';
import { ProviderTaskFieldType } from 'app/enums/provider-task-field-type.enum';

@Component({
    selector: 'app-utilities',
    templateUrl: './utilities.component.html',
    styleUrls: ['./utilities.component.css']
})
export class UtilitiesComponent extends NexusComponent implements OnInit {
    public utilities_model: UtilitiesModel;
    public current_group: UtilitiesGroupModel;
    public link_forms: Array<UntypedFormGroup>;
    public link_error: Array<string>;
    public link_success: Array<string>;

    public ProviderTaskFieldType = ProviderTaskFieldType;

    constructor(private route: ActivatedRoute, private utilities_service: UtilitiesService, private membership: MembershipService) {
        super();
    }

    ngOnInit() {
        this.route.data.subscribe(({ utilities_model }) => {
            this.utilities_model = utilities_model;

            if (this.utilities_model.groups.length > 0) {
                this.utilities_model.groups = this.utilities_model.groups.sort(function (a, b) { return (a.end_date > b.end_date) ? 1 : ((b.end_date > a.end_date) ? -1 : 0); }).reverse();
                this.current_group = this.utilities_model.groups[0];
                this.setup_group();
            }
        });
    }

    get_initial(last_name: string) {
        if (last_name) {
            return last_name.charAt(0);
        }
        return null;
    }

    setup_group() {
        if (this.current_group) {
            this.link_forms = new Array<UntypedFormGroup>();
            this.link_success = new Array<string>();
            this.link_error = new Array<string>();

            for (let utility of this.current_group.utilities) {
                let inputs = {};

                for (let field of utility.fields) {
                    let val = null;
                    switch (field.field_type) {
                        case ProviderTaskFieldType.Date:
                            val = field.value_date;
                            break;
                        case ProviderTaskFieldType.DateTime:
                            val = field.value_date_time;
                            break;
                        case ProviderTaskFieldType.TextArea:
                            val = field.value_text;
                            break;
                        case ProviderTaskFieldType.Numeric:
                            val = field.value_numeric;
                            break;
                        default:
                            val = field.value;
                            break;
                    }

                    inputs[field.id] = new UntypedFormControl({ value: val, disabled: utility.completed_date }, Validators.required);

                    if (field.field_type == 'Password') {
                        inputs[field.id + '_confirm'] = new UntypedFormControl({ value: val, disabled: utility.completed_date }, Validators.required);
                    }
                }

                this.link_forms[utility.id] = new UntypedFormGroup(inputs);
                this.link_error[utility.id] = "";
                this.link_success[utility.id] = "";

                this.link_forms[utility.id].valueChanges.subscribe((form: any) => {
                    for (let field of utility.fields) {
                        switch (field.field_type) {
                            case ProviderTaskFieldType.Date:
                                field.value_date = form[field.id];
                                break;
                            case ProviderTaskFieldType.DateTime:
                                field.value_date_time = form[field.id];
                                break;
                            case ProviderTaskFieldType.TextArea:
                                field.value_text = form[field.id];
                                break;
                            case ProviderTaskFieldType.Numeric:
                                field.value_numeric = form[field.id];
                                break;
                            default:
                                field.value = form[field.id]; 
                                if (form[field.id + '_confirm']) {
                                    field.value_confirm = form[field.id + '_confirm'];
                                }
                                break;
                        }
                    }
                });
            }
        }
    }

    linkUtility(utility: UtilitiesGroupUtilityModel) {
        this.link_error[utility.id] = "";
        this.link_success[utility.id] = "";

        this.StartSpinner();
        this.utilities_service.PutLinkUtility(utility).subscribe(
            data => {
                this.utilities_model = data;
                for (let group of this.utilities_model.groups) {
                    if (group.id == this.current_group.id) {
                        this.current_group = group;
                        this.setup_group();
                    }
                }

                if (this.utilities_model.groups.every(group => group.all_utilities_linked)) {
                    this.membership.OverrideUtilitiesLinked()
                }

                this.StopSpinner();
                this.link_success[utility.id] = "Utility Saved!";

            },
            err => {
                this.link_error[utility.id] = err;
                this.StopSpinner();
            });
    }

}
