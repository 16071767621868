<h1 mat-dialog-title>Notifications</h1>
<div mat-dialog-content class="column">
    <ul *ngIf="(notifications?.length ?? 0) > 0">
        <li *ngFor="let notification of notifications" class="w-100">
            <a class="btn btn-default text-left" [href]="notification.url">
                <h3>
                    <fa-icon *ngIf="notification.priority=='Normal'" [icon]="icon_normal" [fixedWidth]="true"></fa-icon>
                    <fa-icon *ngIf="notification.priority=='Urgent'" [icon]="icon_urgent" [fixedWidth]="true"></fa-icon>
                    <fa-icon *ngIf="notification.priority=='Critical'" [icon]="icon_critical" [fixedWidth]="true"></fa-icon>
                    {{notification.title}}
                </h3>
                <p [innerHTML]="notification.notification"></p>
            </a>
        </li>
    </ul>
    <div *ngIf="(notifications?.length ?? 0) === 0" class="h-100 d-flex align-items-center">
        <div class="w-100 text-center text-muted"><em>no notifications found</em></div>
    </div>
</div>
<div mat-dialog-actions>
    <button mat-stroked-button [mat-dialog-close]="notifications" cdkFocusInitial>OK</button>
</div>