<div id="auto-pay-container" class="container">
  <h2>AutoPay</h2>

  <div class="row">
    <div class="col-md-3 col-sm-4">
      <app-payments-menu></app-payments-menu>
    </div>
    <div class="col-md-9 col-sm-8">
      <mat-card>
        <form [formGroup]="form" (ngSubmit)="submit()">
          <mat-card-title>AutoPay Settings</mat-card-title>
          <mat-card-content>
            <div class="input-cell">
              <mat-slide-toggle formControlName="auto_pay" color="primary"></mat-slide-toggle>
            </div>
          </mat-card-content>
          <mat-card-subtitle *ngIf="!previous_auto_pay">AutoPay is currently off.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="!previous_auto_pay">Automatic payment of your monthly bill is not activated.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="previous_auto_pay">AutoPay is currently on.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="previous_auto_pay">AutoPay runs every month beginning 5 business days after your bill is issued.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="previous_auto_pay && auto_pay_model.account_number">AutoPay is using your default payment account: {{ auto_pay_model.account_number }}</mat-card-subtitle>

          <mat-card-actions>
            <p><button mat-raised-button type="submit" [disabled]="!form.valid" color="primary">Save</button></p>
            <nexus-form-result [model]="result_model"></nexus-form-result>
          </mat-card-actions>
          <mat-card-subtitle *ngIf="!previous_auto_pay && auto_pay_model.auto_pay">By turning on autopay, you agree to allow SimpleBills to make automatic withdrawals once a month from the payment method on file to cover your SimpleBills payments. This withdrawal will occur 5 days after your issue date.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="!previous_auto_pay && auto_pay_model.auto_pay">You can turn off autopay at any time.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="previous_auto_pay && !auto_pay_model.auto_pay">Turning off autopay means your payments will no longer be automatically withdrawn.</mat-card-subtitle>
          <mat-card-subtitle *ngIf="previous_auto_pay && !auto_pay_model.auto_pay">You can turn autopay back on at any time.</mat-card-subtitle>
        </form>
      </mat-card>
    </div>
  </div>
</div>
