
import {throwError,  Observable } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { SettingsModel, SettingsProfileModel, SettingsCreditReportingModel } from '../models/settings.model';
import { ChangePasswordModel } from 'app/models/change-password.model';
import { ConfigService } from './config.service';
import { CustomerModel } from '../models/customer.model';
import { FileModel } from 'app/core/models/file.model';
import { shareReplay } from 'rxjs/operators';
@Injectable()
export class SettingsService {


    constructor(private http: HttpClient, private config: ConfigService) {

    }




    private coBrand$: Observable<FileModel>;
    public GetCoBrand(): Observable<FileModel> {
      if (!this.coBrand$) {
        this.coBrand$ = this.http.get<FileModel>(this.config.getApiRoute('api/v3/customer-portal/settings/co-brand')).pipe(
          shareReplay(1),
          catchError((err: HttpErrorResponse) => {
            return throwError(err.error.message);
          })
        );
      }
      return this.coBrand$;
    }


    public GetSettings(): Observable<SettingsModel> {
        return this.http.get<SettingsModel>(this.config.getApiRoute('api/v3/customer-portal/settings')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }),);

    }

    public PutProfile(argument: SettingsProfileModel): Observable<SettingsModel> {
        return this.http.put<SettingsModel>(this.config.getApiRoute('api/v3/customer-portal/settings/profile'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }),);

    }

    public ChangePassword(argument: ChangePasswordModel): Observable<SettingsModel> {
        return this.http.put<SettingsModel>(this.config.getApiRoute('api/v3/customer-portal/settings/change-password'), argument).pipe(
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
  }

  public PutTutorial(model: CustomerModel): Observable<CustomerModel> {
    return this.http.put<CustomerModel>(this.config.getApiRoute('api/v3/customer-portal/settings/put-tutorial'), model).pipe(
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

    public PutCreditReporting(argument: SettingsCreditReportingModel): Observable<SettingsModel> {
        return this.http.put<SettingsModel>(this.config.getApiRoute('api/v3/customer-portal/settings/credit-reporting'), argument).pipe(
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);
            }));
    }
}
