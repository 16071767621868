<div id="bill-history-container" class="container">
  <div class="row">
    <div class="col-sm-6"><h2>Bill History</h2></div>
    <div class="col-sm-6">
      <ul class="user-summary" *ngIf="invoice_model">
        <li>{{ invoice_model.customer.first_name }} {{ invoice_model.customer.last_name }}</li>
        <li *ngIf="invoice_model.group?.property">{{invoice_model.group.property.name}}</li>
        <li *ngIf="invoice_model.group?.region?.code === RegionType.NC && invoice_model.group?.property.legal_name">{{ invoice_model.group?.property.legal_name }}</li>
        <li *ngIf="invoice_model.group?.address1">{{ invoice_model.group?.address1 }} {{ invoice_model.group?.address2 }}</li>
        <li *ngIf="invoice_model.group?.city && invoice_model.group?.region">{{ invoice_model.group?.city }}, {{ invoice_model.group?.region.code }} {{ invoice_model.group?.zip5 }}</li>
    </ul>
   </div>
  </div>
   <table class="table">
        <tr>
          <th></th>
          <th>Month</th>
          <th>Invoice #</th>
          <th>Amount Invoiced</th> 
          <th>Settled on</th>
          <th [title]="'This amount may differ from the Amount Invoiced due to additional fees.'">Amount Paid </th>
          <th>Forwarded to Property</th>
          <th></th>
        </tr>
        <tr *ngFor="let invoice of invoices_model">
          <td><a class="sb-emblem"></a></td>
          <td>{{ invoice.billing_period_date | amDateFormat:'MMMM'}}</td>
          <td>{{ invoice.invoice_number }}</td>
          <td>{{ invoice.item_amount | currency }}</td>
          <td><span *ngIf="invoice.settled_date">{{ invoice.settled_date | amDateFormat:'M/D/YYYY' }}</span></td>
          <td><span *ngIf="invoice.settled_date">{{ invoice.item_amount + invoice.surcharge_amount | currency }}</span></td>
          <td><span *ngIf="invoice.date_defaulted" class="badge badge-pill badge-danger pl-1 pr-1 pt-1">{{ invoice.date_defaulted | amDateFormat:'M/D/YYYY' }}</span></td>
          <td><a [routerLink]="['/invoices', invoice.id]">Details</a></td>
        </tr> 
      </table>
</div>

