import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { RegionModel } from '../models/region.model';
import { ConfigService } from 'app/services/config.service';

@Injectable()
export class RegionService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetRegions(): Observable<Array<RegionModel>> {
    return this.http.get<Array<RegionModel>>(this.config.getApiRoute('api/v3/common/regions')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

}
