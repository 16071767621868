import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ConfigService } from "./config.service";
import { SystemNotificationModel } from "app/models/system-notification.model";
import { Observable, throwError } from "rxjs";
import { map, catchError } from 'rxjs/operators';

@Injectable()
export class SystemNotificationsService {
    constructor(private http: HttpClient, private config: ConfigService) {

    }
    
    public GetNotifications(): Observable<Array<SystemNotificationModel>> {
        return this.http.get<Array<SystemNotificationModel>>(this.config.getApiRoute('api/v3/common/notifications')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse) => {
                return throwError((err.error ?? err).message);
            })
        )
    }
}
