<app-component-loading-indicator></app-component-loading-indicator>
<header>
  <div class="container">
    <nav class="navbar navbar-expand-md navbar-dark">
      <a class="navbar-brand" href="#">Navbar</a>
      <div class="image-container thumbnail-preview" *ngIf="co_brand_img_url">
        <img [src]="co_brand_img_url"/>
      </div>
      <div class="navbar-splitter" *ngIf="co_brand_img_url == null"></div>
      <button class="navbar-toggler" type="button" (click)="toggle_nav_collapse()" aria-controls="main-nav"
        aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="main-nav" [class.collapse]="is_nav_collapsed"
        [class.show]="!is_nav_collapsed" (click)="toggle_nav_collapse()">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/residents">
              <fa-icon [icon]="faUserCircle" [fixedWidth]="true"></fa-icon> For Residents
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://www.simplebills.com/properties">
              <fa-icon [icon]="faHome" [fixedWidth]="true"></fa-icon> For Properties
            </a>
          </li>
        </ul>

        <ul class="navbar-nav" *ngIf="!isAuthenticated()">
          <li class="nav-item">
            <button mat-raised-button color="primary" [routerLink]="['/login']">Sign In</button>
          </li>
        </ul>
        <ul class="navbar-nav" *ngIf="isAuthenticated()">
          <li class="nav-item">
            <a class="nav-link"><fa-icon [icon]="faUser" [fixedWidth]="true"></fa-icon> {{ session.first_name }}</a>
          </li>
          <app-system-notifications></app-system-notifications>
          <li class="nav-item">
            <a class="nav-link" href="" [routerLink]="['/settings']"><fa-icon [icon]="faWrench" [fixedWidth]="true"></fa-icon></a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="" (click)="sign_out()"><fa-icon [icon]="faSignOutAlt" [fixedWidth]="true"></fa-icon> Sign Out</a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</header>

<div id="sub-nav" class="bg-light" *ngIf="isAuthenticated() && !hide_nav_bar">
  <div class="container">
    <nav class="navbar navbar-expand-md">
      <ul class="navbar-nav">
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/'" [routerLink]="['/']">Dashboard</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/my-bill'" [routerLink]="['/my-bill']">My Bill</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/invoices'" [routerLink]="['/invoices']">Bill History</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/utilities'"
             [routerLink]="['/utilities']">
            Utilities
            <span *ngIf="!session?.utilities_linked" class="badge"><fa-icon [icon]="faExclamationCircle"></fa-icon></span>
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/payment-methods'"
             [routerLink]="['/payment-methods']">
            Payment Methods
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link" [class.current-route]="router.url === '/signup/renew'" [routerLink]="['/signup/renew']">
            Renew Service
          </a>
        </li>

      </ul>
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a href="https://simplebills.zendesk.com/hc/en-us" target="_blank">
           <button class="mat-flat-button mat-primary" style="border-radius: 40px"> Visit Our Help Center</button>
          </a>
        </li>
      </ul>

    </nav>
  </div>
</div>
<div class="container text-center nols-warning" *ngIf="no_local_storage">
  <p class="m-0">
    The site uses cookies to personalize your experience.<br> Please enable cookies and reload.
  </p>
</div>
<section id="content" *ngIf="!no_local_storage">
  <router-outlet></router-outlet>
</section>
<section id="pre-footer">
  <div class="container text-center">
    <p class="mt-5 mb-3">
      <img src="/assets/logo-green.png" />
    </p>
    <div class="d-flex justify-content-center mb-3">
      <ul class="nav">
        <li class="nav-item">
          <a class="nav-link" href="https://www.simplebills.com/properties">For Properties</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.simplebills.com/residents">For Residents</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.simplebills.com/about">About Us</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://careers-realpage.icims.com/jobs/search?ss=1&searchRelation=keyword_all&searchLocation=-12827-Woodway">Careers</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.simplebills.com/support">Support</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://www.simplebills.com/credit-reporting">Credit Reporting</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" href="https://simplebills.zendesk.com/hc/en-us">Help Center</a>
        </li>
      </ul>
    </div>
    <hr />
    <div class="d-flex justify-content-center mb-4 mt-2">
      <ul class="nav">
        <li class="nav-item">
          <a href="https://www.facebook.com/Simplebills" target="_blank" class="social-link">
            <fa-icon [icon]="faFacebookF" [fixedWidth]="true"></fa-icon>
          </a>
        </li>

        <li class="nav-item">
          <a href="https://instagram.com/simplebills/" target="_blank" class="social-link">
            <fa-icon [icon]="faInstagram" [fixedWidth]="true"></fa-icon>
          </a>
        </li>

        <li class="nav-item">
          <a href="https://twitter.com/SimpleBillsCorp" target="_blank" class="social-link">
            <fa-icon [icon]="faTwitter" [fixedWidth]="true"></fa-icon>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section>
<footer>
  <div class="container text-center">
    <p class="m-0">
      <a href="https://www.realpage.com/privacy-policy/">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a
        href="http://www.simplebills.com/terms-of-service">Terms of Service</a>&nbsp;&nbsp;|&nbsp;&nbsp;&copy; {{currentYear}} SimpleBills, a RealPage Company. All rights reserved.
    </p>
  </div>
</footer>

<app-chat-genesys></app-chat-genesys>
