import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SettingsModel } from '../../models/settings.model';
import { SettingsService } from '../../services/settings.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import * as moment from 'moment';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { ChangePasswordModel } from 'app/models/change-password.model';
import { MatDialog } from '@angular/material/dialog';
import { DialogBodyComponent } from '../../core/components/dialog-body/dialog-body.component';


@Component({
    selector: 'app-settings',
    templateUrl: './settings.component.html',
    styleUrls: ['./settings.component.css']
})
export class SettingsComponent extends NexusComponent implements OnInit {
    public settings_model: SettingsModel;
    public previous_credit_reporting: boolean;
  public change_password: ChangePasswordModel = new ChangePasswordModel();

    public profile_result_model: NexusFormResultModel = new NexusFormResultModel();

  public birthdate_valid: boolean = true;

  public opened: boolean = false;

    public profile_form = new UntypedFormGroup({
        phone: new UntypedFormControl({ value: '', disabled: true }),
        secondary_phone: new UntypedFormControl("", this.ValidateOptionalPhone),
        email: new UntypedFormControl({ value: '', disabled: true }, this.ValidateEmail),
        secondary_email: new UntypedFormControl("", this.ValidateOptionalEmail)
    });

    public credit_reporting_result_model: NexusFormResultModel = new NexusFormResultModel();
    public credit_reporting_form = new UntypedFormGroup({
        credit_reporting_enabled: new UntypedFormControl()
    });
    public credit_reporting_enabled_form = new UntypedFormGroup({
        birth_date: new UntypedFormControl("", [Validators.required, Validators.minLength(8)])
    });

    public password_result_model: NexusFormResultModel = new NexusFormResultModel();
    public password_form = new UntypedFormGroup({
        current_password: new UntypedFormControl("", Validators.required),
        new_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)]),
        confirm_password: new UntypedFormControl("", [Validators.required, Validators.minLength(8)])
    });

  constructor(private route: ActivatedRoute, private router: Router, private settings_service: SettingsService, private dialog: MatDialog) {
    super();
  }

    ngOnInit() {
        this.route.data.subscribe(({ settings_model }) => {
            this.settings_model = settings_model;
            this.previous_credit_reporting = this.settings_model.credit_reporting.credit_reporting_enabled;

            this.profile_form.patchValue(this.settings_model.profile);
            this.credit_reporting_form.patchValue(this.settings_model.credit_reporting);
            this.credit_reporting_enabled_form.patchValue(this.settings_model.credit_reporting);

            
        });

        this.profile_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.settings_model.profile);
        });

        this.password_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.change_password);
        });

        this.credit_reporting_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.settings_model.credit_reporting);
        });
        this.credit_reporting_enabled_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.settings_model.credit_reporting);
        });
        
        this.credit_reporting_enabled_form.get('birth_date').valueChanges.subscribe(date => {
            let max = moment().subtract(17, 'years');
            let min = moment([1900, 1, 1]);
            this.birthdate_valid = date > min && date < max;
        });
    }

    saveProfile(event) {
        this.profile_result_model.Reset();

        this.StartSpinner();
        this.settings_service.PutProfile(this.settings_model.profile).subscribe(
            data => {
                this.settings_model = data;
                this.StopSpinner();
                this.profile_result_model.Success("Profile saved!");
            },
            err => {
                this.profile_result_model.Error(err);
                this.StopSpinner();
            });
    }

    changePassword(event) {
        if (this.password_form.invalid) {
            this.password_result_model.Incomplete();
            return;
        }

        if (!this.validatePasswordConfirmation()) {
            this.password_result_model.Error('Passwords Must Match');
            return;
        }

        this.password_result_model.Reset();

        this.StartSpinner();
        this.settings_service.ChangePassword(this.change_password).subscribe(
            data => {
                this.settings_model = data;
                this.StopSpinner();
                this.password_result_model.Success("Password changed successfully!");
            },
            err => {
                this.password_result_model.Error(err);
                this.StopSpinner();
            });

  }

    creditReportingToggled() {
        this.settings_model.credit_reporting.credit_reporting_enabled = !this.settings_model.credit_reporting.credit_reporting_enabled;
    }

    saveCreditReporting() {

        this.credit_reporting_result_model.Reset();
        this.StartSpinner();

        this.settings_service.PutCreditReporting(this.settings_model.credit_reporting).subscribe(
            data => {
                this.settings_model = data;
                this.previous_credit_reporting = this.settings_model.credit_reporting.credit_reporting_enabled;
                this.StopSpinner();
            },
            err => {
                this.credit_reporting_result_model.Error(err);
                this.StopSpinner();
            });
    }

    public validatePasswordConfirmation(): boolean {
        let valid = false;
        if (this.password_form) {
            var newPassword = this.password_form.get('new_password').value;
            var confirmPassword = this.password_form.get('confirm_password').value

            if (newPassword && confirmPassword && newPassword == confirmPassword) {
                valid = true;
            }
        }
        return valid;
    }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogBodyComponent, {
      data: { url: this.settings_model.profile?.resident_portal_tutorial_url},
      height: '585px',
      width: '900px'
    });

    dialogRef.afterClosed().subscribe(result => {
    });
  }
}
