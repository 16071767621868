<div id="one-time-payment-container" class="container">
    <div class="row">
      <div id="current-invoice-summary" class="col-md-4">
        <module-nexus-invoice-information [invoice_model]="invoice_model">
        </module-nexus-invoice-information>
      </div>
        <div class="col-md-7">
            <mat-card>
                <mat-card-title>One Time Payment <br></mat-card-title>
                <mat-card-content>
                    <mat-expansion-panel class="mb-3" [expanded]="expand_credit" hideToggle>
                        <mat-expansion-panel-header  color="primary" [collapsedHeight]="'70px'"
                            [expandedHeight]="'70px'" (click)="expand_payment_method('credit')">
                            <mat-panel-title [class.bg-header]="!expand_credit" [class.alert-success]="expand_credit" >
                                <div class="col-md-1 header-text">
                                    <mat-radio-button  color="primary" [value]="expand_credit"
                                        [checked]="expand_credit"></mat-radio-button>
                                </div>
                                <div class="col-md-5 text-left header-text">
                                    <span style="font-weight:bold">Credit Or Debit Card</span>
                                </div>
                                <div class="col-md-6 header-icons">
                                    <span class="float-right">
                                        <fa-icon [icon]="faCcMastercard" class="icon" ></fa-icon>
                                        <fa-icon [icon]="faCcDiscover" class="icon display" ></fa-icon>
                                        <fa-icon [icon]="faCcVisa" class="icon display" ></fa-icon>
                                    </span>

                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <div class="col-md-11 ml-3">
                        <form [formGroup]="creditform" (ngSubmit)="submit()">
                            <mat-card-content>
                              <div class="input-cell">
                                <mat-form-field>
                                  <input matInput type="text" placeholder="Name on Card" formControlName="cc_name_on_card" />
                                </mat-form-field>
                              </div>
                              <div class="row">
                                <div class="col-7 input-cell">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Card Number" formControlName="cc_number_mask" [mask]="'0000-0000-0000-0000'"/>
                                  </mat-form-field>
                                </div>
                                <div class="col-1 input-cell">
                                  <div class="cc-icon-lock">
                                    <fa-icon [icon]="faLock"></fa-icon>
                                  </div>
                                </div>
                                <div class="col-4 input-cell">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Security Code" formControlName="cc_card_code"
                                           mask="999" />
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-6 input-cell">
                                  <mat-form-field>
                                    <mat-select placeholder="Expiration Month" formControlName="cc_exp_month">
                                      <mat-option *ngFor="let month of months" [value]="month.numeric">
                                        {{ month.name }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                                <div class="col-6 input-cell">
                                  <mat-form-field>
                                    <mat-select placeholder="Expiration Year" formControlName="cc_exp_year">
                                      <mat-option *ngFor="let year of years" [value]="year.name">
                                        {{ year.name }}
                                      </mat-option>
                                    </mat-select>
                                  </mat-form-field>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-8 input-cell">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Street Address" formControlName="cc_street" />
                                  </mat-form-field>
                                </div>
                                <div class="col-4 input-cell">
                                  <mat-form-field>
                                    <input matInput type="text" placeholder="Zip" formControlName="cc_zip"
                                           mask="00000" />
                                  </mat-form-field>
                                </div>
                              </div> 
                              <div class="row">
                              <div class="col-8 input-cell text-left mt-2">
                                  <mat-checkbox formControlName="save_on_file" [(ngModel)]='credit_card_save_on_file' class="terms">Save Payment Method</mat-checkbox>
                              </div> 
                            </div>          
                            </mat-card-content>
                            <p *ngIf="credit_card_save_on_file" class="terms" [innerHTML]="card_storage_notice_html"></p>
                          </form>
                        </div>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mb-3" [expanded]="expand_bank" hideToggle>
                        <mat-expansion-panel-header  color="primary" [collapsedHeight]="'70px'"
                            [expandedHeight]="'70px'" (click)="expand_payment_method('bank')">
                            <mat-panel-title [class.bg-header]="!expand_bank" [class.alert-success]="expand_bank" >
                                <div class="col-md-1 header-text">
                                    <mat-radio-button  color="primary" [value]="expand_bank"
                                        [checked]="expand_bank"></mat-radio-button>
                                </div>
                                <div class="col-md-5 text-left header-text">
                                    <span  style="font-weight:bold">Checking Account</span>
                                </div>
                                <div class="col-md-6 header-icons">
                                    <div class="float-right">
                                        <fa-icon [icon]="faMoneyCheckAlt" class="icon" style="font-size:277%"></fa-icon>
                                    </div>

                                </div>
                            </mat-panel-title>
                        </mat-expansion-panel-header>
                        <br>
                        <div class="col-md-11 ml-3">
                        <form [formGroup]="bankform" (ngSubmit)="submit()">
                            <mat-card-content>
                              <p class="terms">
                                Customers MUST use a checking account, not a savings account.
                              </p>
                              <div class="input-cell">
                                <mat-form-field>
                                  <input matInput type="text" placeholder="Name on Check" formControlName="ch_name_on_check" />
                                </mat-form-field>
                              </div>
                              <div class="input-cell">
                                <mat-form-field>
                                  <input matInput type="text" placeholder="Account Number" formControlName="ch_number_mask" />
                                </mat-form-field>
                              </div>
                              <div class="input-cell">
                                <mat-form-field>
                                  <input matInput type="text" placeholder="Routing Number" formControlName="ch_transit_mask"
                                         [mask]="'000000000'" />
                                </mat-form-field>
                              </div>
                              <div class="input-cell">
                                <mat-form-field>
                                  <input matInput type="text" placeholder="Bank Name" formControlName="ch_bank_name" />
                                </mat-form-field>
                              </div>
                              <div class="row">
                                <div class="col-8 input-cell text-left mt-2">
                                    <mat-checkbox formControlName="save_on_file" [(ngModel)]='bank_save_on_file' class="terms" >Save Payment Method</mat-checkbox>
                                </div> 
                              </div>          
                              </mat-card-content>
                              <p *ngIf="bank_save_on_file" class="terms">By saving this payment method on file, you agree that this payment method can be used to pay any outstanding balance on your account per our <a href="http://www.simplebills.com/terms-of-service">Terms of Service</a>.</p>
                          </form>
                        </div>
                    </mat-expansion-panel>
                    <div *ngIf="surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
                      <div class="row"><div class="col-md-3 offset-3 text-left">Total Balance Due</div><div class="col-md-3 text-right">{{ surcharge_model.sale_amount| currency }}</div></div>
                      <div class="row"><div class="col-md-3 offset-3 text-left">{{surcharge_model.surcharge_description}}</div><div class="col-md-3 text-right">{{ surcharge_model.surcharge_amount | currency }}</div></div>
                      <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ surcharge_model.transaction_total | currency }}</div></div>
                    </div> 
                    <div *ngIf="!surcharge_model?.surcharge_amount" class="column border-top pt-2 border-bottom pb-2 mb-2">
                      <div class="row font-weight-bold"><div class="col-md-3 offset-3 text-left">Transaction Total</div><div class="col-md-3 text-right">{{ invoice_model.total_balance | currency }}</div></div>
                    </div>
                    <p class="terms">By submitting this form, you agree that this payment information can be used to pay the outstanding balance on your account.</p>
                    <mat-card-actions>
                        <p><button mat-raised-button type="submit" [disabled]="!bankform.valid && !creditform.valid" color="primary" (click)="submit()">Submit</button></p>
                        <nexus-form-result [model]="result_model"></nexus-form-result>
                      </mat-card-actions>
                </mat-card-content>
            </mat-card>
        </div>
    </div>
</div>