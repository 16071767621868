import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';

import Months from '../../statics/Months';
import Years from '../../statics/Years';
import { faCcMastercard, faCcVisa, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { EventService } from 'app/core/services/event.service';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
import { PaymentMethodService } from 'app/services/payment-method.service';
import { RegionModel } from 'app/core/models/region.model';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { CustomerRegulatoryModel } from '../../models/customer-regulatory.model';
import { CreditCardService } from 'app/services/credit-card.service';
import { NoticeModel } from 'app/models/notice.model';
import { CardIdentificationModel } from 'app/models/card-identification.model';

@Component({
  selector: 'app-payment-method-credit',
  templateUrl: './payment-method-credit.component.html',
  styleUrls: ['./payment-method-credit.component.css']
})
export class PaymentMethodCreditComponent extends NexusComponent implements OnInit {
  public regions_model: Array<RegionModel>;
  public payment_methods_model: Array<PaymentMethodModel>;
  public payment_method_model: PaymentMethodModel = new PaymentMethodModel();
  public result_model: NexusFormResultModel = new NexusFormResultModel();
  public un_link_pressed: boolean;
  public months = Months;
  public years = Years;
  public regulatory_model: CustomerRegulatoryModel;

  public edit: boolean = true;

  public faCcMastercard = faCcMastercard;
  public faCcVisa = faCcVisa;
  public faCcDiscover = faCcDiscover;
  public faLock = faLock;

  public form = new UntypedFormGroup({
    cc_name_on_card: new UntypedFormControl("", Validators.required),
    cc_number_mask: new UntypedFormControl("", this.ValidateCreditCardNumber),
    cc_exp_month: new UntypedFormControl("", Validators.required),
    cc_exp_year: new UntypedFormControl("", Validators.required),
    cc_card_code: new UntypedFormControl("", this.ValidateCVV),
    cc_street: new UntypedFormControl("", Validators.required),
    cc_zip: new UntypedFormControl("", Validators.required),
  });

  public notices: Array<NoticeModel> = [];

  public get payment_information_acceptance():string {
    if (!this.notices?.length) {
      return "";
    }

    if (!this.notices.filter(n=> n.key == "payment-information-acceptance").length) {
      return "";
    }

    return this.notices.filter(n=> n.key == "payment-information-acceptance").map(n=> "<p>" + n.message + "</p>").join();
  }

  constructor(
    private route: ActivatedRoute, 
    private router: Router, 
    private membership: MembershipService, 
    private payment_method_service: PaymentMethodService,
    private credit_card_service: CreditCardService) {
    super();
  }

  ngOnInit() {
    this.route.data.subscribe(({ regions_model, payment_methods_model, payment_method_model, regulatory_model }) => {
      this.regions_model = regions_model;
      this.payment_methods_model = payment_methods_model;
      this.regulatory_model = regulatory_model;
      if (payment_method_model) {
        this.payment_method_model = payment_method_model;
        this.form.patchValue(this.payment_method_model);

        if (this.payment_method_model.cc_expiration_date) {
          this.form.get('cc_exp_month').setValue(this.payment_method_model.cc_expiration_date.getMonth()+1);
          this.form.get('cc_exp_year').setValue(this.payment_method_model.cc_expiration_date.getFullYear());
        }
      }

      this.getNotices(payment_method_model);
    });

    this.form.valueChanges.subscribe((form: any) => {
      this.PopulateModelFromForm(form, this.payment_method_model);
      if (this.form.get('cc_exp_month').value && this.form.get('cc_exp_year').value) {
        this.payment_method_model.cc_expiration_date = new Date(this.form.get('cc_exp_year').value, this.form.get('cc_exp_month').value - 1, 1);
      }
      this.binCheck(this.payment_method_model.cc_number_mask);
    });
  }

  private _lastBinCheck: string = "";
  binCheck(card_number: string) {
    if (this._lastBinCheck === card_number)
      return;

    this._lastBinCheck = card_number;

    if (card_number && card_number?.length >= 4 && !isNaN(Number(card_number))) {
      this.credit_card_service.BinCheck(card_number).subscribe((cc_ident: CardIdentificationModel) => {
        var new_bin = cc_ident.card_type == "Unidentified" ? null : (cc_ident.card_type == "DebitCard");
        if (new_bin != this.payment_method_model.bin) {
          this.payment_method_model.bin = new_bin;
          this.getNotices(this.payment_method_model);
        }
      });      
    }
    else if ((!card_number || isNaN(Number(card_number)) || card_number?.length < 4) && this.payment_method_model.bin !== null) {
      this.payment_method_model.bin = null;
      this.getNotices(this.payment_method_model);
    }
  }

  getNotices(payment_method_model: PaymentMethodModel) {
    var type_tag = payment_method_model?.bin === true ? 'debit' : payment_method_model?.bin === false ? 'credit' : 'default';

    this.credit_card_service.GetNotices([type_tag,'storage'], payment_method_model?.id).subscribe((data) => {
      this.notices = data;
    });
  }

  submit() {
    this.result_model.Reset();
    this.StartSpinner();
    this.payment_method_model.account_type = 'CreditCard';
    this.payment_method_service.SavePaymentMethod(this.payment_method_model).subscribe(
      data => {
        this.membership.OverrideGoodStanding();
        this.router.navigate(['/payment-methods']);
        this.StopSpinner();
      },
      err => {
        this.result_model.Error(err);
        this.StopSpinner();
      });
  }


  unLink() {
    this.un_link_pressed = true;
  }

  confirmUnLink() {
    this.result_model.Reset();
    this.StartSpinner();

    this.payment_method_service.DeletePaymentMethod(this.payment_method_model.id).subscribe(
      data => {
        this.router.navigate(['/payment-methods']);
        this.StopSpinner();
      },
      err => {
        this.result_model.Error(err);
        this.StopSpinner();
      });
  }

}
