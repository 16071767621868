import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { SystemNotificationModel } from 'app/models/system-notification.model';
import { PaymentMethodService } from 'app/services/payment-method.service';
import { Observable } from 'rxjs';

@Injectable()
export class PaymentMethodNotificationsResolver implements Resolve<SystemNotificationModel[]> {
  constructor(private payment_method_service: PaymentMethodService) {

  }

  resolve(route: ActivatedRouteSnapshot): Observable<SystemNotificationModel[]> {
    return this.payment_method_service.GetNotifications(route.params.payment_method_id);
  }
}
