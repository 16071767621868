import { Component, OnInit } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { EventService } from 'app/core/services/event.service';

@Component({
  selector: 'app-component-loading-indicator',
  templateUrl: './component-loading-indicator.component.html',
  styleUrls: ['./component-loading-indicator.component.css']
})
export class ComponentLoadingIndicatorComponent implements OnInit {
    public spinner: boolean;

    constructor(private router: Router, private event_service: EventService) {
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.spinner = true;
            }
            if (event instanceof NavigationEnd) {
                this.spinner = false;
            }
            if (event instanceof NavigationCancel) {
                this.spinner = false;
            }
            if (event instanceof NavigationError) {
                this.spinner = false;
            }
        });        
    }

    ngOnInit() {

        this.event_service.on('loading-start', () => {
            this.spinner = true;
        });

        this.event_service.on('loading-finish', () => {
            this.spinner = false;
        });
    }

}
