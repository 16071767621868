import { Injectable } from '@angular/core';
import { map, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { PaymentMethodModel } from '../models/payment-method.model';
import { ConfigService } from './config.service';
import { SystemNotificationModel } from 'app/models/system-notification.model';

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodService {

  constructor(private http: HttpClient, private config: ConfigService) { }

  public GetPaymentMethods(): Observable<Array<PaymentMethodModel>> {
    return this.http.get<Array<PaymentMethodModel>>(this.config.getApiRoute('api/v3/customer-portal/payment-methods')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public GetPaymentMethod(payment_method_id: string): Observable<PaymentMethodModel> {
    return this.http.get<PaymentMethodModel>(this.config.getApiRoute('api/v3/customer-portal/payment-methods/'+payment_method_id)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public SavePaymentMethod(model: PaymentMethodModel): Observable<PaymentMethodModel> {
    if (model.id) {
      return this.http.put<PaymentMethodModel>(this.config.getApiRoute('api/v3/customer-portal/payment-methods/' + model.id), model).pipe(
        map(data => {
          return data;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
        }));
    }
    else {
      return this.http.put<PaymentMethodModel>(this.config.getApiRoute('api/v3/customer-portal/payment-methods'), model).pipe(
        map(data => {
          return data;
        }),
        catchError((err: HttpErrorResponse) => {
          return throwError(err.error.message);
        }));
    }
  }

  public DeletePaymentMethod(payment_method_id: string): Observable<{}> {
    return this.http.delete(this.config.getApiRoute('api/v3/customer-portal/payment-methods/'+payment_method_id)).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

  public SetDefaultPaymentMethod(payment_method_id: string): Observable<Array<PaymentMethodModel>> {
    return this.http.put<Array<PaymentMethodModel>>(this.config.getApiRoute('api/v3/customer-portal/payment-methods/' + payment_method_id +'/set-default'), {}).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));

  }

  public GetNotifications(payment_method_id: string): Observable<Array<SystemNotificationModel>> {
    return this.http.get<Array<SystemNotificationModel>>(this.config.getApiRoute('api/v3/customer-portal/payment-methods/' + payment_method_id +'/notifications'), {}).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }

}
