
import {throwError,  Observable } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AutoPayModel } from 'app/models/auto-pay.model';
import { ConfigService } from './config.service';

@Injectable()
export class AutoPayService {

    constructor(private http: HttpClient, private config: ConfigService) {

    }

    public Get(): Observable<AutoPayModel> {
        return this.http.get<AutoPayModel>(this.config.getApiRoute('api/v3/customer-portal/auto-pay')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);    
            }),);

    }

    public Put(argument: AutoPayModel): Observable<AutoPayModel> {
        return this.http.put<AutoPayModel>(this.config.getApiRoute('api/v3/customer-portal/auto-pay'), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);    
            }),);

    }
    
}
