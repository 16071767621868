<div id="my-bill-container" class="container">
    <div class="no-bill-message" *ngIf="!invoice_model.id">
        <img class="no-bill-message-image" src="/assets/no-bill.png" >
    </div>
    <div *ngIf="invoice_model.id">
        <div class="row">
            <div class="col-sm-6">
                <h2>Your bill, simplified.</h2>
            </div>
            <div class="col-sm-6">
                <ul class="user-summary">
                    <li>{{ invoice_model.customer.first_name }} {{ invoice_model.customer.last_name }}</li>
                    <li *ngIf="invoice_model.group?.property">{{invoice_model.group.property.name}}</li>
                    <li *ngIf="invoice_model.group?.region?.code === RegionType.NC && invoice_model.group?.property.legal_name">{{ invoice_model.group?.property.legal_name }}</li>
                    <li *ngIf="invoice_model.group?.address1">{{ invoice_model.group?.address1 }} {{ invoice_model.group?.address2 }}</li>
                    <li *ngIf="invoice_model.group?.city && invoice_model.group?.region">{{ invoice_model.group?.city }}, {{ invoice_model.group?.region.code }} {{ invoice_model.group?.zip5 }}</li>
                </ul>
            </div>
        </div>

        <div class="row">
            <div id="current-invoice-summary" class="col-md-4">
                <mat-card>
                    <mat-card-content>
                        <mat-list>
                            <mat-list-item>
                                <span mat-line class="first-name">{{ invoice_model.customer.first_name }}'s bill</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span mat-line class="issued-on">Issued
                                    {{ invoice_model.date_sent | amDateFormat:'MMM D, YYYY' }}</span>
                            </mat-list-item>
                            <mat-list-item>
                                <span mat-line class="invoice-balance">
                                    <app-styled-currency amount="{{ invoice_model.total_balance | currency }}">
                                    </app-styled-currency>
                                </span>
                            </mat-list-item>
                            <mat-list-item
                                *ngIf="(invoice_model.total_balance - invoice_model.item_amount > .01 || invoice_model.total_balance - invoice_model.item_amount < -.01) && (invoice_model.total_balance > .01 || invoice_model.total_balance < -.01)">
                                <span mat-line class="breakdown-toggle"><a
                                        (click)="breakdownToggle = !breakdownToggle">Why is this amount
                                        different?</a></span>
                            </mat-list-item>
                            <div class="breakdown-wrapper" *ngIf="breakdownToggle">
                                <div class="row">
                                    <label class="col-sm-6 text-right">Bill Amount</label>
                                    <span class="col-sm-6 text-left">{{ invoice_model.item_amount | currency }}</span>
                                </div>
                                <div class="row">
                                    <label class="col-sm-6 text-right">Payments to Date</label>
                                    <span class="col-sm-6 text-left">{{ invoice_model.transaction_amount | currency }}</span>
                                </div>
                                <div class="row">
                                    <label class="col-sm-6 text-right">Current Balance</label>
                                    <span class="col-sm-6 text-left">{{ invoice_model.balance | currency }}</span>
                                </div>
                                <div class="row">
                                    <label class="col-sm-6 text-right">Previous Balance</label>
                                    <span
                                        class="col-sm-6 text-left">{{ invoice_model.previous_balance | currency }}</span>
                                </div>
                                <div class="row">
                                    <label class="col-sm-6 text-right">Total Balance Due</label>
                                    <span class="col-sm-6  text-left">{{ invoice_model.total_balance | currency }}</span>
                                </div>
                            </div>
                            <mat-list-item>
                                <span mat-line class="due-on">{{ invoice_model.group?.region?.code === RegionType.NC ? 'Past Due After' : 'Due' }}
                                    {{ invoice_model.date_due | amDateFormat:'MMM D, YYYY' }}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="invoice_model.date_defaulted">
                                <span mat-line class="defaulted">Forwarded to Property on
                                    {{ invoice_model.date_defaulted | amDateFormat:'MMM D, YYYY' }}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="invoice_model.settled && invoice_model.settled_date">
                                <span mat-line>Settled on
                                    {{ invoice_model.settled_date | amDateFormat:'MMM D, YYYY' }}</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="!invoice_model.settled && !is_late">
                                <span mat-line class="due-days"
                                    *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }}
                                    days left</span>
                                <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due
                                    Tomorrow!</span>
                                <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 0">Due
                                    Today!</span>
                            </mat-list-item>
                            <mat-list-item *ngIf="!invoice_model.settled && is_late">
                                <span mat-line class="due-days-warning"
                                    *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }} days
                                    late</span>
                                <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due
                                    Yesterday!</span>
                            </mat-list-item>

                        </mat-list>
                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-raised-button color="primary" *ngIf="!invoice_model.settled"
                            [routerLink]="['/invoices', invoice_model.id, 'pay']">Pay Bill</button>
                    </mat-card-actions>
                </mat-card>
                <div class="row tip m-0" *ngIf="invoice_model.tip">
                    <span class="col-sm-1 p-0 tip-icon"><fa-icon [icon]="faLightbulb" class="fa-2x" [fixedWidth]="true"></fa-icon></span>
                    <span class="col-sm-11 tip-text pr-0 d-flex align-items-center">{{invoice_model?.tip?.content}}</span>
                </div>
            </div>
            <div id="current-invoice-items" class="col-md-8">
                Invoice # {{invoice_model.invoice_number}} | <a [routerLink]="['/invoices']">Bill History</a>
                <div class="my-bill-items">
                    <app-invoice-detail [invoice]="invoice_model" ></app-invoice-detail>
                </div>
                <div class="my-bill-total row "><span class="col-md-9 pr-0">Total</span><b class="col-md-3">{{invoice_model.item_amount | currency}}</b></div>
                <div *ngIf="invoice_model.settled_date">
                    <div class="my-bill-total row"><span class="col-md-9 pr-0">Surcharge Amount</span> <b class="col-md-3">{{invoice_model.surcharge_amount | currency}}</b></div>
                    <div class="my-bill-total row"><span class="col-md-9 pr-0">Amount Paid</span><b class="col-md-3">{{invoice_model.item_amount + invoice_model.surcharge_amount| currency}}</b></div>
                </div>
                
            </div>
        </div>
        <mat-card *ngIf="invoice_model.group?.region?.code === RegionType.NC">
            <mat-card-content>
                <div class="property-details">
                    <div><em><strong> Property Details:</strong></em></div>
                    <div>Property Name: {{invoice_model.group?.property.legal_name ? invoice_model.group?.property.legal_name : invoice_model.group?.property.name}}</div>
                    <div>Property Address: {{invoice_model.group?.property.address1}}</div>
                    <div>Phone Number: {{invoice_model.group?.property.phone}}</div>                   
                </div>
                
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="invoice_model.regulatory_scheme">
            <mat-card-content>
                <div class="regulatory-scheme-notes" [innerHTML]="invoice_model.regulatory_scheme.my_bill_notes"></div>
            </mat-card-content>
        </mat-card>
    </div>
</div>