import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { InvoiceService } from '../services/invoice.service';
import { InvoicePaymentModel } from 'app/models/invoice-payment.model';

@Injectable()
export class InvoicePaymentResolver implements Resolve<InvoicePaymentModel> {

    constructor(private invoice_service: InvoiceService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<InvoicePaymentModel> {
        return this.invoice_service.GetInvoicePayment(route.params.invoice_id, route.params.invoice_payment_id);
  }
}
