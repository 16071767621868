import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvoiceModel } from 'app/models/invoice.model';
import { RegionType } from 'app/enums/region-type.enum';

@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {
  public invoices_model: Array<InvoiceModel>;
  public invoice_model: InvoiceModel;
  public RegionType = RegionType;

  constructor(private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit() {
    this.route.data.subscribe(({ invoices_model }) => {
      this.invoices_model = invoices_model;
      if (this.invoices_model && this.invoices_model.length > 0) {
        this.invoice_model = this.invoices_model[0];
      }
    });
  }
}
