import { Component, Inject, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogActions, MatDialogClose, MatDialogContent, MatDialogRef, MatDialogTitle } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { SystemNotificationModel } from 'app/models/system-notification.model';
import { faBell, faExclamationTriangle, faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-system-notifications-dialog',
  templateUrl: './system-notifications-dialog.component.html',
  styleUrls: ['./system-notifications-dialog.component.scss']
})
export class SystemNotificationsDialogComponent implements OnInit {

  public notifications: SystemNotificationModel[] = [];

  public icon_normal = faBell;
  public icon_urgent = faExclamation;
  public icon_critical = faExclamationTriangle;

  constructor(
      public dialogRef: MatDialogRef<SystemNotificationsDialogComponent>, 
      @Inject(MAT_DIALOG_DATA) public data: SystemNotificationModel[]) { 
    this.notifications = data ?? [];
  }

  ngOnInit(): void {
  }

  onCancel(): void {
    this.dialogRef.close(this.notifications);
  }
}
