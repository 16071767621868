import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { InvoiceService } from '../../services/invoice.service';

import Months from '../../statics/Months';
import Years from '../../statics/Years';
import { faCcMastercard, faCcVisa, faCcDiscover } from '@fortawesome/free-brands-svg-icons';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { InvoiceModel } from 'app/models/invoice.model';
import { PaymentMethodModel } from 'app/models/payment-method.model';
import { RegionModel } from 'app/core/models/region.model';
import { CustomerRegulatoryModel } from 'app/models/customer-regulatory.model';
import { NoticeModel } from 'app/models/notice.model';
import { CreditCardService } from 'app/services/credit-card.service';
import { MatDialog } from '@angular/material/dialog';
import { CardIdentificationModel } from 'app/models/card-identification.model';
import { CardDisclosuresDialogComponent } from '../card-disclosures-dialog/card-disclosures-dialog.component';
import { SurchargeModel } from 'app/models/surcharge.model';

@Component({
    selector: 'app-invoice-pay',
    templateUrl: './invoice-pay.component.html',
    styleUrls: ['./invoice-pay.component.css']
})

export class InvoicePayComponent extends NexusComponent implements OnInit {
    public invoice_model: InvoiceModel;
    public regulatory_model: CustomerRegulatoryModel;
    public payment_methods_model: Array<PaymentMethodModel>;
    public regions_model: Array<RegionModel>;
    public payment_method_model: PaymentMethodModel = new PaymentMethodModel();
    public surcharge_model: SurchargeModel = new SurchargeModel();
    public one_time_payment: boolean = false;

    public faCcMastercard = faCcMastercard;
    public faCcVisa = faCcVisa;
    public faCcDiscover = faCcDiscover;
    public faLock = faLock;

    private state_auto_complete: string;
    private state_letter_skip: number;

    public show_bank_form: boolean = false;
    public show_credit_form: boolean = false;

    public months = Months;
    public years = Years;

    public payment_method_form: UntypedFormGroup;    
    public payment_result_model: NexusFormResultModel = new NexusFormResultModel();
    public submit_disabled:boolean=false;
  
    public notices: Array<NoticeModel> = [];

    constructor(
        private route: ActivatedRoute, 
        private router: Router, 
        private invoice_service: InvoiceService,
        private credit_card_service: CreditCardService,
        private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.route.data.subscribe(({ invoice_model, regulatory_model, payment_methods_model, regions_model }) => {
            this.invoice_model = invoice_model;
            this.regulatory_model = regulatory_model;
            this.payment_methods_model = payment_methods_model;
            this.regions_model = regions_model;

            for(let payment_method of this.payment_methods_model)
            {
                if(payment_method.default_account)
                {
                    this.payment_method_model = payment_method;
                    this.getNotices(this.payment_method_model);
                }
            }            
        });
        
    }

    select_payment_method(payment_method: PaymentMethodModel) {
        this.payment_method_model = payment_method;
        this.one_time_payment= false;
        this.getNotices(this.payment_method_model);
    }

    submitPayment() {
        this.payment_result_model.Reset();
        if(this.one_time_payment){
            this.StartSpinner();
            this.router.navigate(['/invoices', this.invoice_model.id, 'one-time-payment']);
        }
        else if ((this.notices?.length ?? 0) !== 0) {
            const dialogRef = this.dialog.open(CardDisclosuresDialogComponent, {
                data: this.notices,
                minHeight:'60%',
                maxHeight:'100%',
                minWidth:'35%',
                maxWidth:'75%'
              });
          
              dialogRef.afterClosed().subscribe(result => {
                if (result === true) {
                  this.completePayment();
                }
                else {
                  this.payment_result_model.Error('Please make any modifications needed before continuing.');
                }
              });
        }
        else {
            this.completePayment();
        }
        
    }

    completePayment() {
        this.StartSpinner();
        this.submit_disabled=true;
        this.invoice_service.PayInvoice(this.invoice_model.id, this.payment_method_model).subscribe(
            data => {
                this.StopSpinner();
                this.router.navigate(['/invoices', this.invoice_model.id, 'payments', data.id]);
            },
            err => {
                this.payment_result_model.Error(err);
                this.StopSpinner();
            });
    }

    startCreditPaymentMethodForm(event) {
        this.payment_method_model.account_type = 'CreditCard';
        this.payment_method_form = new UntypedFormGroup({
            cc_name_on_card: new UntypedFormControl("", Validators.required),
            cc_number_mask: new UntypedFormControl("", this.ValidateCreditCardNumber),
            cc_exp_month: new UntypedFormControl("", Validators.required),
            cc_exp_year: new UntypedFormControl("", Validators.required),
            cc_card_code: new UntypedFormControl("", this.ValidateCVV),
            first_name: new UntypedFormControl("", Validators.required),
            last_name: new UntypedFormControl("", Validators.required),
            address1: new UntypedFormControl("", Validators.required),
            address2: new UntypedFormControl(),
            city: new UntypedFormControl("", Validators.required),
            region_id: new UntypedFormControl("", Validators.required),
            zip: new UntypedFormControl("", this.ValidateZip)
        });

        this.payment_method_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.payment_method_model);
            if (this.payment_method_form.get('cc_exp_month').value && this.payment_method_form.get('cc_exp_year').value) {
                this.payment_method_model.cc_expiration_date = new Date(this.payment_method_form.get('cc_exp_year').value, this.payment_method_form.get('cc_exp_month').value - 1, 1);
            }
            this.binCheck(this.payment_method_model.cc_number_mask);
        });

        this.show_credit_form = true;
        this.show_bank_form = false;

        return false;
    }

    startBankPaymentMethodForm(event) {
        this.payment_method_model.account_type = 'Checking';
        this.payment_method_form = new UntypedFormGroup({
            ch_name_on_check: new UntypedFormControl("", Validators.required),
            ch_number_mask: new UntypedFormControl("", Validators.required),
            ch_transit_mask: new UntypedFormControl("", this.ValidateBankRouting),
            ch_bank_name: new UntypedFormControl("", Validators.required),
            first_name: new UntypedFormControl("", Validators.required),
            last_name: new UntypedFormControl("", Validators.required),
            address1: new UntypedFormControl("", Validators.required),
            address2: new UntypedFormControl(),
            city: new UntypedFormControl("", Validators.required),
            region_id: new UntypedFormControl("", Validators.required),
            zip: new UntypedFormControl("", this.ValidateZip)
        });

        this.payment_method_form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.payment_method_model);

            this.binCheck("");
        });

        this.show_bank_form = true;
        this.show_credit_form = false;
        return false;
    }


    stateFocus(event) {
        this.state_auto_complete = "";
        this.state_letter_skip = 0;
    }

    stateKeypress(event) {
        if (event.key && this.state_auto_complete && this.state_auto_complete.endsWith(event.key.toLowerCase())) {
            this.state_letter_skip++;
        }
        else {
            this.state_letter_skip = 0;
            if (event.key) {
                this.state_auto_complete += event.key.toLowerCase();
            }
        }

        var skipped = 0;
        for (let region of this.regions_model) {
            if (region.name.toLowerCase().startsWith(this.state_auto_complete)) {
                if (this.state_letter_skip == skipped) {
                    this.payment_method_form.patchValue({ region_id: region.id });
                    break;
                }
                skipped++;
            }
            else {
                skipped = 0;
            }
        }
    }

    one_time_payment_method(){
        this.one_time_payment= true;
        this.notices = [];
        this.surcharge_model=null;
    }

    get_expiration_month(payment_method:PaymentMethodModel){
        if(payment_method && payment_method.cc_expiration_date)    
            return (new Date(payment_method.cc_expiration_date).getMonth() + 1);
        return null; 
    }
   
    get_expiration_year(payment_method:PaymentMethodModel){
        if(payment_method && payment_method.cc_expiration_date)    
            return new Date(payment_method.cc_expiration_date).getFullYear();
        return null;
   }

   private _lastBinCheck: string = "";
   binCheck(card_number: string) {
     if (this._lastBinCheck === card_number)
       return;
 
     this._lastBinCheck = card_number;
 
     if (card_number && card_number?.length >= 4 && !isNaN(Number(card_number))) {
       this.credit_card_service.BinCheck(card_number).subscribe((cc_ident: CardIdentificationModel) => {
         var new_bin = cc_ident.card_type == "Unidentified" ? null : (cc_ident.card_type == "DebitCard");
         if (new_bin != this.payment_method_model.bin) {
           this.payment_method_model.bin = new_bin;
           this.getNotices(this.payment_method_model);
         }
       });      
     }
     else if ((!card_number || isNaN(Number(card_number)) || card_number?.length < 4) && this.payment_method_model.bin !== null) {
       this.payment_method_model.bin = null;
       this.getNotices(this.payment_method_model);
     }
   }
 
    getNotices(payment_method_model: PaymentMethodModel) {
        var type_tag = !payment_method_model.cc_number_mask ? 'default' : payment_method_model?.bin === true ? 'debit' : payment_method_model?.bin === false ? 'credit' : 'default';

        var tags = [type_tag, (this.one_time_payment ? "one-time-payment" : "payment")];

        if (!this.one_time_payment && !payment_method_model.id) {
            tags.push('storage');
        }

        this.credit_card_service.GetNotices(tags, payment_method_model?.id).subscribe((data) => {
            this.notices = data;
        });

        this.getSurcharge(payment_method_model);
    }

    getSurcharge(payment_method_model: PaymentMethodModel) {
        this.credit_card_service.GetSurcharge(this.invoice_model, payment_method_model).subscribe((data) => {
            this.surcharge_model = data;
        });
    }
}
