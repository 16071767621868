import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { InvoiceService } from 'app/services/invoice.service';
import { InvoiceModel } from 'app/models/invoice.model';

@Injectable()
export class InvoicesResolver implements Resolve<Array<InvoiceModel>> {

    constructor(private service: InvoiceService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<Array<InvoiceModel>> {
        return this.service.GetInvoices();
  }
}
