import { Injectable } from '@angular/core';
import { throwError, Observable } from 'rxjs';
import { catchError, map, share } from 'rxjs/operators';
import { HttpErrorResponse, HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { FileModel } from '../core/models/file.model';

@Injectable({
  providedIn: 'root'
})
export class ProviderBillService {

  constructor(private http: HttpClient, private config: ConfigService) {

  }
  public GetProviderBillPDF(provider_bill_id: string): Observable<FileModel> {
    return this.http.get<FileModel>(this.config.getApiRoute('api/v3/customer-portal/provider-bills/' + provider_bill_id + '/export-pdf')).pipe(
      map(data => {
        return data;
      }),
      catchError((err: HttpErrorResponse) => {
        return throwError(err.error.message);
      }));
  }
}

