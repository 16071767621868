
import {throwError,  Observable } from 'rxjs';
import {map, catchError} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UtilitiesModel, UtilitiesGroupUtilityModel } from 'app/models/utilities.model';
import { ConfigService } from './config.service';

@Injectable()
export class UtilitiesService {

    constructor(private http: HttpClient, private config: ConfigService) {

    }

    public GetUtilities(): Observable<UtilitiesModel> {
        return this.http.get<UtilitiesModel>(this.config.getApiRoute('api/v3/customer-portal/utilities')).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);  
            }),);

    }

    public PutLinkUtility(argument: UtilitiesGroupUtilityModel): Observable<UtilitiesModel> {
        return this.http.put<UtilitiesModel>(this.config.getApiRoute('api/v3/customer-portal/utilities/' + argument.id), argument).pipe(
            map(data => {
                return data;
            }),
            catchError((err: HttpErrorResponse)  => {
                return throwError(err.error.message);  
            }),);

    }

}
