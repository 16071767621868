import { getValue } from '@amcharts/amcharts4/.internal/core/utils/Type';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { CustomerModel } from '../../../models/customer.model';
import { SettingsService } from '../../../services/settings.service';

export interface DialogData {
  message: string,
  url: string
}

@Component({
  selector: 'dialog-body',
  templateUrl: './dialog-body.component.html',
  styleUrls: ['./dialog-body.component.css']
})
export class DialogBodyComponent {
  public safeUrl: any;
  public customer_model: CustomerModel = new CustomerModel();
  constructor(
    public dialogRef: MatDialogRef<DialogBodyComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private _sanitizer: DomSanitizer, private settings_service: SettingsService) {
    }

  ngOnInit() {
    if(this.data.url)
    {
      this.safeUrl = this._sanitizer.bypassSecurityTrustResourceUrl(`https://www.youtube.com/embed/${this.data.url}`);
    }
  }

  close(): void {
    this.dialogRef.close();
  }

}
