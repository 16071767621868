<div id="bill-history-invoice-container" class="container">
    <div class="row">
        <div class="col-sm-4">
            <h2>Bill History</h2>
        </div>
        <div class="col-sm-4">
        </div>
        <div class="col-sm-4">
            <ul class="user-summary">
                <li>{{ invoice_model.customer.first_name }} {{ invoice_model.customer.last_name }}</li>
                <li *ngIf="invoice_model.group?.property">{{invoice_model.group.property.name}}</li>
                <li *ngIf="invoice_model.group?.region?.code === RegionType.NC && invoice_model.group?.property.legal_name">{{ invoice_model.group?.property.legal_name }}</li>
                <li *ngIf="invoice_model.group?.address1">{{ invoice_model.group?.address1 }} {{ invoice_model.group?.address2 }}</li>
                <li *ngIf="invoice_model.group?.city && invoice_model.group?.region">{{ invoice_model.group?.city }}, {{ invoice_model.group?.region.code }} {{ invoice_model.group?.zip5 }}</li>
            </ul>
        </div>
    </div>

    <div class="row">
        <div id="current-invoice-summary" class="col-md-4">
            <mat-card>
                <mat-card-content>
                    <mat-list>
                        <mat-list-item>
                            <span mat-line class="first-name">{{ invoice_model.customer.first_name }}'s bill</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span mat-line class="issued-on">Issued
                                {{ invoice_model.date_sent | amDateFormat:'MMM D, YYYY' }}</span>
                        </mat-list-item>
                        <mat-list-item>
                            <span mat-line class="invoice-balance">
                                <app-styled-currency amount="{{ invoice_model.balance | currency }}">
                                </app-styled-currency>
                            </span>
                        </mat-list-item>
                        <mat-list-item>
                            <span mat-line class="due-on">{{ invoice_model.group?.region?.code === RegionType.NC ? 'Past Due After' : 'Due' }}
                                {{ invoice_model.date_due | amDateFormat:'MMM D, YYYY' }}</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="invoice_model.date_defaulted">
                            <span mat-line class="defaulted">Forwarded to Property on
                                {{ invoice_model.date_defaulted | amDateFormat:'MMM D, YYYY' }}</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="invoice_model.settled && invoice_model.settled_date">
                            <span mat-line>Settled on
                                {{ invoice_model.settled_date | amDateFormat:'MMM D, YYYY' }}</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="!invoice_model.settled && !is_late">
                            <span mat-line class="due-days" *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }}
                                days left</span>
                            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due
                                Tomorrow!</span>
                            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 0">Due Today!</span>
                        </mat-list-item>
                        <mat-list-item *ngIf="!invoice_model.settled && is_late">
                            <span mat-line class="due-days-warning"
                                *ngIf="days_until_bill_due > 1">{{ days_until_bill_due }} days
                                late</span>
                            <span mat-line class="due-days-warning" *ngIf="days_until_bill_due == 1">Due
                                Yesterday!</span>
                        </mat-list-item>

                    </mat-list>
                </mat-card-content>
                <mat-card-actions>
                    <button mat-raised-button color="primary" *ngIf="!invoice_model.settled"
                        [routerLink]="['/invoices', invoice_model.id, 'pay']">Pay Bill</button>
                </mat-card-actions>
            </mat-card>
            <mat-card *ngIf="invoice_model.transactions.length > 0">
                <mat-card-title>Transaction History</mat-card-title>
                <table class="transaction-table" cellspacing="30">
                    <tr>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Reference</th>
                    </tr>
                    <tr *ngFor="let transaction of invoice_model.transactions">
                        <td>{{ transaction.created_date | amDateFormat:'M/D/YYYY' }}</td>
                        <td>{{ transaction.amount | currency}}</td>
                        <td>{{ transaction.reference}}</td>
                    </tr>
                </table>
            </mat-card>
        </div>
        <div id="bill-history-invoice-items" class="col-md-8">
            Invoice # {{invoice_model.invoice_number}}
            <div>
                <div class="bill-history-items">
                    <app-invoice-detail [invoice]="invoice_model" ></app-invoice-detail>
                </div>

                <div class="bill-history-total">Total <b>{{invoice_model.item_amount | currency}}</b></div>
            </div>
        </div>
    </div>
</div>
