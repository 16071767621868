import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Observable } from 'rxjs';

import { SettingsService } from '../services/settings.service';
import { SettingsModel } from '../models/settings.model';

@Injectable()
export class SettingsResolver implements Resolve<SettingsModel> {

    constructor(private settings_service: SettingsService) 
    {

    }

    resolve(route: ActivatedRouteSnapshot): Observable<SettingsModel> {
        return this.settings_service.GetSettings();
  }
}