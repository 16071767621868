import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EventService } from 'app/core/services/event.service';
import { NexusFormResultModel } from 'app/core/models/nexus-form-result.model';
import { MembershipService } from 'app/core/services/membership.service';
import { ForgotPasswordModel } from 'app/core/models/forgot-password.model';
import { NexusComponent } from 'app/core/components/nexus/nexus.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent extends NexusComponent implements OnInit {

    public form = new UntypedFormGroup({
        email: new UntypedFormControl("", Validators.required)
    });
    public model: ForgotPasswordModel = new ForgotPasswordModel();
    public result_model: NexusFormResultModel = new NexusFormResultModel();
    public success_submit: boolean = false;

    constructor(public membership: MembershipService, private router: Router) {
        super();
    }

    submit() {
        this.result_model.Reset();
        this.StartSpinner();
        this.membership.ForgotPassword(this.model).subscribe(
            data => {
                this.StopSpinner();
                this.result_model.Success("Password reset e-mail has been sent, please check your e-mail and follow the directions provided.");
                this.success_submit = true;
            },
            err => {
                this.result_model.Error(err);
                this.StopSpinner();
            });
    }
    
    ngOnInit() {
        this.form.valueChanges.subscribe((form: any) => {
            this.PopulateModelFromForm(form, this.model);
        });   
    }
}
