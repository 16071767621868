import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment/moment';
import { InvoiceModel } from 'app/models/invoice.model';
import { ConfigService } from 'app/services/config.service';
import { RegionType } from 'app/enums/region-type.enum';
import { faLightbulb } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-my-bill',
    templateUrl: './my-bill.component.html',
    styleUrls: ['./my-bill.component.css']
})
export class MyBillComponent implements OnInit {
    public invoice_model: InvoiceModel;
    public is_late: boolean;
    public days_until_bill_due: number;
    public breakdownToggle: boolean = false;
    public RegionType = RegionType;
    public propertyEmail;
    public faLightbulb=faLightbulb;

    constructor(private route: ActivatedRoute, private config: ConfigService) {

    }

    ngOnInit() {
        this.route.data.subscribe(({ invoice_model }) => {
            this.invoice_model = invoice_model;
            let days = this.config.getCurrentMoment().startOf('day').diff(moment(this.invoice_model.date_due).startOf('day'), 'days', false);
            this.is_late = days > 0;
            this.days_until_bill_due = Math.ceil(Math.abs(days));
            this.propertyEmail = invoice_model.property_primary_contact_email;
        });
    }

}
